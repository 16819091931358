import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImagePicker } from '../common';
import * as icons from '../../../assets/icons';

export default () => {
  const [transitionTime, setTransitionTime] = useState(3);

  const setTime = (condition) => {
    condition === 'increase'
      ? setTransitionTime(transitionTime + 1)
      : transitionTime > 1 && setTransitionTime(transitionTime - 1);
  };

  return (
    <div>
      <p className='text-sm'>Transition time</p>
      <div className='flex items-center my-2'>
        <div
          onClick={() => setTime('increase')}
          className='w-6 h-6 rounded-full bg-blue-500 flex justify-center items-center cursor-pointer'
        >
          <FontAwesomeIcon icon={icons.plus} color='white' size='sm' />
        </div>
        <p className='mx-6 text-sm'>{transitionTime}s</p>
        <div
          onClick={() => setTime('decrease')}
          className='w-6 h-6 rounded-full bg-blue-500 flex justify-center items-center cursor-pointer'
        >
          <FontAwesomeIcon icon={icons.minus} color='white' size='sm' />
        </div>
      </div>

      <p className='text-sm mt-4 mb-2'>Images (Max. 4)</p>
      <div className='flex items-center'>
        {[1, 2, 3, 4].map((index) => (
          <ImagePicker key={index} />
        ))}
      </div>
    </div>
  );
};
