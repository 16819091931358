import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.templates, action) => {
  switch (action.type) {
    case types.SET_MOBILE_TEMPLATES: {
      let templates = _.cloneDeep(state);

      templates = action.payload;

      return templates;
    }
    default:
      return state;
  }
};
