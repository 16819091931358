import React from 'react';
import { Header, OrderCard, BottomNavigationBar } from '../../components';

export default () => {
  return (
    <div>
      <Header title='My Orders' color='white' />

      <p className='mt-4 text-center'>Last Order</p>
      <OrderCard />

      <p className='mt-4 text-center'>Previous Orders</p>
      <OrderCard />
      <OrderCard />

      <BottomNavigationBar />
    </div>
  );
};
