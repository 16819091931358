import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { getApp, setAppTemplateId } from '../../actions/selectedApp.actions';
import { getMobileTemplates } from '../../actions/templates.actions';
import {
  getMobileComponent,
  handleStylingActions,
} from '../../actions/mobileComponents.actions';
import { updateApp } from '../../api/apps.api';
import { RouterHelper } from '../../helpers/router.helper';
import { TopBar, Template } from '../../components';
import {
  HomeScreenPreview,
  CategoriesPreview,
  CategoryPreview,
  LoginScreenPreview,
  SignupScreenPreview,
  AddressScreenPreview,
  NewAddressScreenPreview,
  PaymentMethodsPreview,
  NewPaymentScreenPreview,
  SingleItemScreenPreview,
  FavouritesScreenPreview,
  OrdersScreenPreview,
  ProfileScreenPreview,
  ShoppingCartScreenPreview,
  ShippingScreenPreview,
  PaymentScreenPreview,
  ThankYouScreenPreview,
} from '../../AppPreview/screens';
import { PreviewPanel } from '../../AppPreview/components';
import * as icons from '../../assets/icons';

export default () => {
  const history = useHistory();
  const { appId } = useParams();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates);
  const colors = useSelector((state) => state.selectedApp.colors);
  const selectedTemplateId = useSelector((state) => state.selectedApp.templateId);
  const [selectedScreen, setSelectedScreen] = useState('Home Screen');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  useEffect(() => {
    dispatch(getApp(appId));
    dispatch(getMobileTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedTemplate(
      templates.find((template) => template.id === selectedTemplateId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId]);

  useEffect(() => {
    screenData[selectedScreen].styling?.modifiable !== false &&
      (selectedTemplateId === null
        ? dispatch(getMobileComponent(appId, screenData[selectedScreen].key))
        : !_.isEmpty(selectedTemplate) && setTemplateStyling());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, selectedScreen]);

  const setTemplateStyling = () => {
    handleStylingActions[screenData[selectedScreen].key](
      dispatch,
      selectedTemplate?.settings[screenData[selectedScreen].key]
    );
  };

  const options = [
    { value: 'shops', label: 'SHOPS' },
    { value: 'restaurants', label: 'RESTAURANTS' },
    { value: 'hotels', label: 'HOTELS' },
  ];

  const screenData = {
    'Home Screen': {
      key: 'home_screen',
      styling: useSelector((state) => state.homeScreen),
      preview: <HomeScreenPreview />,
    },
    Categories: {
      key: 'categories',
      styling: useSelector((state) => state.categories),
      preview: <CategoriesPreview />,
    },
    Category: {
      key: 'category',
      styling: useSelector((state) => state.category),
      preview: <CategoryPreview />,
    },
    'Log In': {
      key: 'log_in',
      styling: useSelector((state) => state.loginScreen),
      preview: <LoginScreenPreview />,
    },
    'Sign Up': {
      key: 'sign_up',
      styling: useSelector((state) => state.signupScreen),
      preview: <SignupScreenPreview />,
    },
    Address: {
      key: 'saved_addresses',
      styling: useSelector((state) => state.addressScreen),
      preview: <AddressScreenPreview />,
    },
    'New Address': {
      key: 'new_address',
      styling: useSelector((state) => state.newAddressScreen),
      preview: <NewAddressScreenPreview />,
    },
    'Payment Methods': {
      key: 'saved_payment_methods',
      styling: useSelector((state) => state.paymentMethods),
      preview: <PaymentMethodsPreview />,
    },
    'N. Payment Method': {
      key: 'new_payment_method',
      styling: useSelector((state) => state.newPaymentScreen),
      preview: <NewPaymentScreenPreview />,
    },
    'Single Product': {
      key: 'item_page',
      styling: useSelector((state) => state.singleItemScreen),
      preview: <SingleItemScreenPreview />,
    },
    Favourites: {
      // key: 'favorites',
      styling: { modifiable: false },
      preview: <FavouritesScreenPreview />,
    },
    'My Orders': {
      // key: '',
      styling: { modifiable: false },
      preview: <OrdersScreenPreview />,
    },
    'My Profile': {
      // key: 'profile',
      styling: { modifiable: false },
      preview: <ProfileScreenPreview />,
    },
    'Shopping Cart': {
      // key: '',
      styling: { modifiable: false },
      preview: <ShoppingCartScreenPreview />,
    },
    Shipping: {
      // key: 'shipping_address',
      styling: { modifiable: false },
      preview: <ShippingScreenPreview />,
    },
    Payment: {
      // key: 'shipping_payment',
      styling: { modifiable: false },
      preview: <PaymentScreenPreview />,
    },
    'Thank You': {
      // key: 'thank_you',
      styling: { modifiable: false },
      preview: <ThankYouScreenPreview />,
    },
  };

  const screens = Object.keys(screenData);

  const handleTemplateChange = (template) => {
    dispatch(setAppTemplateId(template ? template.id : null));
  };

  const navigateScreens = (value) => {
    const index = screens.findIndex((screen) => screen === selectedScreen);
    const newIndex =
      value === 'increment'
        ? index < screens.length - 1 ? index + 1 : index
        : index > 0 ? index - 1 : index;
    setSelectedScreen(screens[newIndex]);
  };

  const saveTemplate = () => {
    const data = {
      app: {
        mobile_template_id: selectedTemplateId,
      },
    };

    window.confirm('Changing template will reset all styling. Continue?') &&
      updateApp(appId, data)
        .then(() => {
          toast.success('Template Saved Successfully!');
          history.push(RouterHelper.appDesignPath(appId));
        })
        .catch(() => toast.error('unable to save template'));
  };

  return (
    <>
      <TopBar appId={appId} />

      <div className='my-5 grid grid-cols-2'>
        <div className='flex flex-col items-end'>
          <div className='w-3/5'>
            <div className='w-full mb-5'>
              <p className='text-4xl'>Templates</p>
            </div>

            <div className='flex items-center'>
              <Select
                options={options}
                value={options[0]}
                maxMenuHeight={125}
                className='w-3/5'
                styles={{
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#3A82F6',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? 'white' : '#3A82F6',
                  }),
                }}
              />

              <button
                className='ml-8 px-4 py-1 bg-blue-500 text-white rounded-full cursor-pointer'
                onClick={saveTemplate}
              >
                Next
              </button>
            </div>

            <div className='my-5 flex flex-wrap'>
              <Template.New
                selected={selectedTemplateId === null}
                onTemplateChange={() => handleTemplateChange()}
              />
              {templates.map((item, index) => (
                <Template
                  key={index}
                  name={item.name}
                  selected={selectedTemplateId === item.id}
                  onTemplateChange={() => handleTemplateChange(item)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className='flex flex-col items-center'>
          <PreviewPanel>
            {!_.isEmpty(colors) &&
              !_.isEmpty(screenData[selectedScreen].styling) &&
              screenData[selectedScreen].preview}
          </PreviewPanel>
          <div className='w-2/5 my-5 flex justify-between items-center'>
            <div
              className='screen-navigation-button pr-1'
              onClick={() => navigateScreens('decrement')}
            >
              <FontAwesomeIcon icon={icons.chevronLeft} size='lg' />
            </div>
            <p>{selectedScreen}</p>
            <div
              className='screen-navigation-button pl-1'
              onClick={() => navigateScreens('increment')}
            >
              <FontAwesomeIcon icon={icons.chevronRight} size='lg' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
