import React from 'react';
import { selectedColor } from '../../../helpers/colors.helper';
import { ProductDefaultImage } from '../../../assets/images';

export default ({ styleData }) => {
  const height = {
    1: 160,
    2: 150,
    3: 100,
  };

  const itemCardStyling = {
    // width: styleData.design.size.width,
    // height: styleData.design.size.height,
    height: height[styleData.category.itemsPerRow],
    borderColor: styleData.design.entireCard.borderColor,
    borderRadius: styleData.design.entireCard.borderRadius,
  };

  const backgroundImage = {
    borderTopLeftRadius: styleData.design.entireCard.borderRadius,
    borderTopRightRadius: styleData.design.entireCard.borderRadius,
    backgroundImage: `url(${ProductDefaultImage})`,
    backgroundPosition: 'center',
  };

  const imageOverlay = {
    borderTopLeftRadius: styleData.design.entireCard.borderRadius,
    borderTopRightRadius: styleData.design.entireCard.borderRadius,
    backgroundColor: styleData.design.entireCard.fillVisibility
      ? selectedColor(styleData.design.entireCard.fillColor)
      : 'transparent',
  };

  const descriptionStyling = {
    backgroundColor: selectedColor(styleData.design.description.color),
    borderBottomLeftRadius: styleData.design.entireCard.borderRadius,
    borderBottomRightRadius: styleData.design.entireCard.borderRadius,
  };

  const itemNameStyling = {
    color: styleData.itemName.color,
    fontSize: styleData.itemName.fontSize,
    textAlign: styleData.itemName.textAlign,
    lineHeight: styleData.itemName.lineHeight,
    fontFamily: styleData.itemName.fontFamily.value,
    fontWeight: styleData.itemName.bold && 'bold',
    fontStyle: styleData.itemName.italic && 'italic',
    textDecoration: styleData.itemName.underline && 'underline',
  };

  const priceStyling = {
    color: styleData.price.color,
    fontSize: styleData.price.fontSize,
    textAlign: styleData.price.textAlign,
    lineHeight: styleData.price.lineHeight,
    fontFamily: styleData.price.fontFamily.value,
    fontWeight: styleData.price.bold && 'bold',
    fontStyle: styleData.price.italic && 'italic',
    textDecoration: styleData.price.underline && 'underline',
  };

  return (
    <div
      style={itemCardStyling}
      className={`mb-4 mx-2 ${
        styleData.design.entireCard.shadow && 'filter drop-shadow-lg'
      } ${styleData.design.entireCard.borderVisibility && 'border-2'} `}
    >
      <div style={backgroundImage} className='h-2/3'>
        <div style={imageOverlay} className='h-full' />
      </div>
      <div
        style={descriptionStyling}
        className='h-1/3 flex flex-col justify-center px-3'
      >
        <p style={itemNameStyling}>Item</p>
        <p style={priceStyling}>0.00 $</p>
      </div>
    </div>
  );
};
