import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import { Header, Title, InputPreview, Button } from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const newAddressScreen = useSelector((state) => state.newAddressScreen);

  const headerColor = selectedColor(colors, 'primary');
  const title = newAddressScreen.title.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${newAddressScreen.order[0]} ${title && 'mb-5'}`}>
        {title && (
          <Title text='New Address' styleData={newAddressScreen.title} />
        )}
      </div>
      <div className={`order-${newAddressScreen.order[1]}`}>
        <InputPreview
          text='First Name'
          styleData={newAddressScreen.firstName}
        />
      </div>
      <div className={`order-${newAddressScreen.order[2]}`}>
        <InputPreview text='Last Name' styleData={newAddressScreen.lastName} />
      </div>
      <div className={`order-${newAddressScreen.order[3]}`}>
        <InputPreview text='E-Mail' styleData={newAddressScreen.email} />
      </div>
      <div className={`order-${newAddressScreen.order[4]}`}>
        <InputPreview
          text='Country'
          dropDown={true}
          styleData={newAddressScreen.country}
        />
      </div>
      <div className={`order-${newAddressScreen.order[5]}`}>
        <InputPreview
          text='State / Region'
          styleData={newAddressScreen.state}
        />
      </div>
      <div className={`order-${newAddressScreen.order[6]}`}>
        <InputPreview text='Address' styleData={newAddressScreen.address} />
      </div>
      <div className={`order-${newAddressScreen.order[7]}`}>
        <InputPreview text='Phone' styleData={newAddressScreen.phone} />
      </div>
      <div className={`order-${newAddressScreen.order[8]}`}>
        <InputPreview text='City' styleData={newAddressScreen.city} />
      </div>
      <div className={`order-${newAddressScreen.order[9]}`}>
        <InputPreview
          text='ZIP / Postal Code'
          styleData={newAddressScreen.zipCode}
        />
      </div>
      <div className={`order-${newAddressScreen.order[10]} mt-8`}>
        <Button text='Save' styleData={newAddressScreen.button} />
      </div>
    </div>
  );
};
