import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { RouterHelper } from '../../helpers/router.helper';
import * as icons from '../../assets/icons';

export default ({ app, onCancelClick }) => {
  return (
    <div className='bg-gray-300 app-block'>
      <p className='absolute top-2 right-4 cursor-pointer'>
        <FontAwesomeIcon icon={icons.close} onClick={onCancelClick} />
      </p>
      <p className='text-2xl mb-10'>{app?.title || 'New App'}</p>
      <Link to={RouterHelper.appDetailsPath(app?.id)}>
        <button className='transparent-button'>MODIFY</button>
      </Link>
    </div>
  );
};
