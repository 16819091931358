import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.newPaymentScreen, action) => {
  switch (action.type) {
    case types.SET_NEW_PAYMENT_SCREEN_STYLING: {
      let newPaymentScreen = _.cloneDeep(state);

      newPaymentScreen = action.payload;

      return newPaymentScreen;
    }
    case types.SET_NEW_PAYMENT_SCREEN_ORDER: {
      let newPaymentScreen = _.cloneDeep(state);

      newPaymentScreen.order = action.payload;

      return newPaymentScreen;
    }
    default:
      return state;
  }
};
