import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.categories, action) => {
  switch (action.type) {
    case types.SET_CATEGORIES_STYLING: {
      let categories = _.cloneDeep(state);

      categories = action.payload;

      return categories;
    }
    case types.SET_CATEGORIES_ORDER: {
      let categories = _.cloneDeep(state);

      categories.order = action.payload;

      return categories;
    }
    default:
      return state;
  }
};
