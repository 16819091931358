import React from 'react';
import {
  Header,
  InputPreview,
  BottomNavigationBar,
  RadioButtonPreview,
} from '../../components';

export default () => {
  const inputStyling = (size) => {
    return {
      text: {
        fontFamily: { value: 'sans-serif', label: 'Sans Serif' },
        fontStyle: '',
        color: '#9DA3B0',
        fontSize: 15,
        bold: false,
        italic: false,
        underline: false,
        textAlign: 'left',
        lineHeight: 1.5,
      },
      design: {
        entireCard: {
          borderVisibility: true,
          borderColor: '#9DA3B0',
          fillVisibility: true,
          fillColor: '#FFF',
          shadow: true,
          borderRadius: 20,
        },
        size: {
          alignment: 'center',
          width: size === 'short' ? 70 : 330,
          height: 35,
        },
      },
    };
  };

  return (
    <div>
      <Header title='Payment' color='white' />

      <div className='mt-3'>
        <RadioButtonPreview text='Primary Payment Methods' selected={true} />
        <InputPreview text='Card 1' styleData={inputStyling()} />
      </div>

      <div className='mt-3'>
        <RadioButtonPreview text='Saved Payment Methods' />
        <InputPreview
          text='Card 1'
          dropDown={true}
          styleData={inputStyling()}
        />
      </div>

      <div className='mt-3'>
        <RadioButtonPreview text='New Payment Method' />
        <InputPreview text='Card number' styleData={inputStyling()} />
        <InputPreview text='Name on card' styleData={inputStyling()} />
        <InputPreview text='CVC / CVV' styleData={inputStyling()} />

        <div className='mx-2 flex justify-evenly items-center'>
          <p>Expiration</p>
          <InputPreview text='MM' styleData={inputStyling('short')} />
          <InputPreview text='YY' styleData={inputStyling('short')} />
        </div>
      </div>

      <hr className='mx-5 my-5 border' />

      <div className='mx-2'>
        <div className='mx-5 my-1 flex justify-between items-center'>
          <p>Product Price:</p>
          <p>175 USD</p>
        </div>
        <div className='mx-5 my-1 flex justify-between items-center'>
          <p>Shipping Price:</p>
          <p>20 USD</p>
        </div>
        <div className='mx-5 my-1 flex justify-between items-center'>
          <p>Total Price:</p>
          <p className='text-blue-500'>195 USD</p>
        </div>
      </div>

      <div className='flex justify-center items-center'>
        <div className='w-10/12 flex justify-between items-center absolute bottom-20'>
          <p className='ml-5'>Back</p>
          <p className='px-5 py-1 text-blue-500 bg-white filter drop-shadow-lg rounded-md'>
            Place Order
          </p>
        </div>
      </div>

      <BottomNavigationBar />
    </div>
  );
};
