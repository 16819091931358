import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { fetchApp, updateApp } from '../../api/apps.api';
import { RouterHelper } from '../../helpers/router.helper';
import { TopBar, AppDetails, ScreenShots } from '../../components';
import { Button } from '../../AppEditor/components/common';
import * as icons from '../../assets/icons';

export default () => {
  const history = useHistory();
  const { appId } = useParams();
  const [activeScreen, setActiveScreen] = useState('form');
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [iconURL, setIconURL] = useState('');
  const [iconSrc, setIconSrc] = useState('');
  const [editable, setEditable] = useState(null);
  const [screenShotsURL, setScreenShotsURL] = useState([]);
  const [screenShotsSrc, setScreenShotsSrc] = useState([]);

  const btnIcon = <FontAwesomeIcon icon={icons.arrowRight} className='ml-2' />;

  useEffect(() => {
    fetchApp(appId)
      .then((res) => setData(res.data))
      .catch(() => toast.error('unable to fetch app details'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setData = (app) => {
    setTitle(app.title);
    setShortDescription(app.short_description);
    setDescription(app.description);
    app.icon && setIconSrc(app.icon);
    setEditable(app.status === 'draft');
    setScreenShotsURL(app.screenshots);
    setScreenShotsSrc(app.screenshots);
  };

  const setScreenshotsURL = (url, imageId) => {
    let imagesURL = _.cloneDeep(screenShotsURL);
    imagesURL[imageId[imageId.length - 1]] = url;
    setScreenShotsURL(imagesURL);
  };

  const setScreenshotsSrc = (src, imageId) => {
    let imagesSrc = _.cloneDeep(screenShotsSrc);
    imagesSrc[imageId[imageId.length - 1]] = src;
    setScreenShotsSrc(imagesSrc);
  };

  const submitForm = () => {
    if (!editable) {
      setActiveScreen('screenShot');
    } else if (
      title === '' ||
      description === '' ||
      shortDescription === '' ||
      (iconSrc === null && iconURL === '')
    ) {
      toast.warning('Please fill all the required fields');
    } else {
      let formData = new FormData();

      formData.append('app[title]', title);
      formData.append('app[description]', description);
      formData.append('app[short_description]', shortDescription);

      if (iconURL) {
        formData.append('app[icon]', iconURL);
      }

      updateApp(appId, formData)
        .then(() => {
          setActiveScreen('screenShot');
          toast.success('App Details Saved!');
        })
        .catch(() => toast.error('unable to save App Details'));
    }
  };

  const backToForm = () => {
    setActiveScreen('form');
  };

  const submitScreenShots = () => {
    if (!editable) {
      history.push(RouterHelper.appTemplatesPath(appId));
      return;
    }

    let formData = new FormData();

    if (screenShotsURL.length >= 2) {
      screenShotsURL.map((image) =>
        formData.append('app[screenshots][]', image)
      );
      updateApp(appId, formData)
        .then(() => {
          toast.success('App Screen Shots Saved!');
          history.push(RouterHelper.appTemplatesPath(appId));
        })
        .catch(() => toast.error('unable to save screenshots'));
    } else {
      toast.warning('Minimum 2 images are required');
    }
  };

  return (
    <>
      <TopBar appId={appId} />

      <div className='flex justify-center items-center'>
        <div className='w-3/5 my-5'>
          <p className='text-4xl'>Details</p>

          <div className='w-full bg-white filter drop-shadow-lg my-5 px-5 py-3'>
            <div className='mb-2 text-xl'>App Details</div>
            {activeScreen === 'form' && (
              <AppDetails
                editable={editable}
                title={title}
                setTitle={(e) => setTitle(e.target.value)}
                shortDescription={shortDescription}
                setShortDescription={(e) => setShortDescription(e.target.value)}
                description={description}
                setDescription={(e) => setDescription(e.target.value)}
                iconSrc={iconSrc}
                setIconURL={setIconURL}
                setIconSrc={setIconSrc}
              />
            )}
            {activeScreen === 'screenShot' && (
              <ScreenShots
                editable={editable}
                imagesSrc={screenShotsSrc}
                setScreenshotsURL={setScreenshotsURL}
                setScreenshotsSrc={setScreenshotsSrc}
              />
            )}
          </div>

          <div className='flex justify-center items-center'>
            {activeScreen === 'form' && (
              <Button.Transparent
                text='NEXT'
                onClick={submitForm}
                icon={btnIcon}
              />
            )}

            {activeScreen === 'screenShot' && (
              <div className='flex justify-center items-center'>
                <div className='mr-5'>
                  <Button.Transparent text='Back' onClick={backToForm} />
                </div>
                <Button.Transparent
                  text='PROCEED TO TEMPLATES'
                  onClick={submitScreenShots}
                  icon={btnIcon}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
