import { combineReducers } from 'redux';
import SessionReducer from './session.reducer';
import AppReducer from './apps.reducer';
import TemplatesReducer from './templates.reducer';
import SelectedAppReducer from './selectedApp.reducer';
import HomeReducer from './home.reducer';
import CategoriesReducer from './categories.reducer';
import CategoryReducer from './category.reducer';
import LoginReducer from './login.reducer';
import SignupReducer from './signup.reducer';
import AddressReducer from './address.reducer';
import NewAddressReducer from './newAddress.reducer';
import PaymentReducer from './paymentMethods.reducer';
import NewPaymentReducer from './newPayment.reducer';
import SingleItemReducer from './singleItem.reducer';

export default combineReducers({
  session: SessionReducer,
  apps: AppReducer,
  templates: TemplatesReducer,
  selectedApp: SelectedAppReducer,
  homeScreen: HomeReducer,
  categories: CategoriesReducer,
  category: CategoryReducer,
  loginScreen: LoginReducer,
  signupScreen: SignupReducer,
  addressScreen: AddressReducer,
  newAddressScreen: NewAddressReducer,
  paymentMethods: PaymentReducer,
  newPaymentScreen: NewPaymentReducer,
  singleItemScreen: SingleItemReducer,
});
