import * as types from '../../constants/actions.js';

export const setSingleItemScreenStyling = (payload) => ({
  type: types.SET_SINGLE_ITEM_SCREEN_STYLING,
  payload: payload,
});

export const setSingleItemScreenOrder = (payload) => ({
  type: types.SET_SINGLE_ITEM_SCREEN_ORDER,
  payload: payload,
});
