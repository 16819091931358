import React from 'react';
import { Toggle } from '../../common';

export default ({ text, checked, setVisibility }) => {
  return (
    <div className='w-40 flex justify-between items-center'>
      <p className='text-sm'>{text}</p>
      <Toggle checked={checked} onClick={setVisibility} />
    </div>
  );
};
