import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RouterHelper } from '../../helpers/router.helper';

export default ({ appId }) => {
  const location = useLocation();

  const navBar = [
    {
      link: '/',
      title: 'My Apps',
    },
    {
      link: RouterHelper.appDetailsPath(appId),
      title: 'Details',
    },
    {
      link: RouterHelper.appTemplatesPath(appId),
      title: 'Templates',
    },
    {
      link: RouterHelper.appDesignPath(appId),
      title: 'Design',
    },
    {
      link: RouterHelper.appColorsPath(appId),
      title: 'Colours',
    },
    {
      link: RouterHelper.appReviewPath(appId),
      title: 'Review',
    },
  ];

  return (
    <div className='bg-white filter drop-shadow-md flex justify-center py-4'>
      {navBar.map((item, index) => (
        <Link key={index} to={item.link}>
          <p
            className={`my-1 mx-2 px-5 pb-1 ${
              location.pathname === item.link && 'border-b-2'
            } border-blue-400 cursor-pointer text-xl`}
          >
            {item.title}
          </p>
        </Link>
      ))}
    </div>
  );
};
