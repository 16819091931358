import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/common';
import * as icons from '../../../assets/icons';

export default ({
  alignment,
  onRightClick,
  onCenterClick,
  onLeftClick,
  width,
  setElementWidth,
  height,
  setElementHeight,
  onResetPress,
}) => {
  return (
    <div>
      <div className='flex items-center mt-8'>
        <p className='mr-10 text-sm'>Element Alignment</p>
        <div className='w-20 flex justify-between items-center'>
          <div
            className={`w-5 h-5 flex justify-center items-center ${
              alignment === 'end' && 'filter drop-shadow-lg bg-white'
            }`}
          >
            <FontAwesomeIcon
              icon={icons.alignRight}
              className='cursor-pointer'
              onClick={onRightClick}
            />
          </div>
          <div
            className={`w-5 h-5 flex justify-center items-center ${
              alignment === 'center' && 'filter drop-shadow-lg bg-white'
            }`}
          >
            <FontAwesomeIcon
              icon={icons.alignCenter}
              className='mx-4 cursor-pointer'
              onClick={onCenterClick}
            />
          </div>
          <div
            className={`w-5 h-5 flex justify-center items-center ${
              alignment === 'start' && 'filter drop-shadow-lg bg-white'
            }`}
          >
            <FontAwesomeIcon
              icon={icons.alignLeft}
              className='cursor-pointer'
              onClick={onLeftClick}
            />
          </div>
        </div>
      </div>

      <div className='flex justify-between items-center mt-10 mr-32'>
        <div className='flex items-center'>
          <p className='text-sm'>Element Width</p>
          <FontAwesomeIcon icon={icons.arrowHorizontal} className='mx-2' />
          <input
            type='text'
            value={width}
            placeholder='18'
            onChange={setElementWidth}
            className='w-8 pl-1 border-b border-black focus:outline-none text-sm'
          />
          <p className='text-sm ml-2'>px</p>
        </div>

        <div className='flex items-center'>
          <p className='text-sm'>Element Height</p>
          <FontAwesomeIcon icon={icons.arrowVertical} className='mx-2' />
          <input
            type='text'
            value={height}
            placeholder='18'
            onChange={setElementHeight}
            className='w-8 pl-1 border-b border-black focus:outline-none text-sm'
          />
          <p className='text-sm ml-2'>px</p>
        </div>
      </div>

      <div className='mt-12'>
        <Button.White text='Reset Default Values' onClick={onResetPress} />
      </div>
    </div>
  );
};
