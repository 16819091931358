import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationBar } from '../../components';
import * as icons from '../../../assets/icons';

export default () => {
  return (
    <div className='h-full flex flex-col justify-center items-center'>
      <div className='w-10 h-10 mb-2 flex justify-center items-center bg-green-500 rounded-full'>
        <FontAwesomeIcon icon={icons.check} color='white' />
      </div>
      <p className='my-5 text-lg'>Thank you !</p>
      <p>Order placed successfully</p>

      <BottomNavigationBar />
    </div>
  );
};
