import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ styleData }) => {
  return (
    <div className={`flex justify-${styleData.design.size.alignment}`}>
      <div
        className={`mt-3 flex items-center ${
          styleData.design.entireBar.borderVisibility && 'border-2'
        } ${styleData.design.entireBar.shadow && 'filter drop-shadow-lg'}`}
        style={{
          width: styleData.design.size.width,
          height: styleData.design.size.height,
          borderColor: styleData.design.entireBar.borderColor,
          borderRadius: styleData.design.entireBar.borderRadius,
          backgroundColor: styleData.design.entireBar.fillVisibility
            ? styleData.design.entireBar.fillColor
            : 'transparent',
        }}
      >
        {styleData.design.entireBar.icon && (
          <FontAwesomeIcon icon={icons.search} className='ml-4' color='gray' />
        )}
        <div
          className={`${styleData.design.entireBar.icon ? 'w-4/5' : 'w-full'}`}
        >
          <p
            className={`${styleData.design.entireBar.icon ? 'mx-2' : 'mx-5'}`}
            style={{
              color: styleData.text.color,
              fontSize: styleData.text.fontSize,
              textAlign: styleData.text.textAlign,
              lineHeight: styleData.text.lineHeight,
              fontFamily: styleData.text.fontFamily.value,
              fontWeight: styleData.text.bold && 'bold',
              fontStyle: styleData.text.italic && 'italic',
              textDecoration: styleData.text.underline && 'underline',
            }}
          >
            Search
          </p>
        </div>
      </div>
    </div>
  );
};
