import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisibilitySelector, ColorPicker } from '../../components/common';
import * as icons from '../../../assets/icons';

export default ({
  categoryVisibility,
  setCategoryVisibility,
  borderRadius,
  setBorderRadius,
  selectedCategory,
  onCategoryChange,
  categories,
  color,
  setColor,
}) => {
  return (
    <div className='grid grid-cols-2'>
      <div>
        <div className='my-2'>
          <VisibilitySelector
            text='Category visibility'
            checked={categoryVisibility}
            setVisibility={setCategoryVisibility}
          />
        </div>

        <div className='flex items-center my-4'>
          <p className='text-sm mr-4'>Corner radius</p>
          <FontAwesomeIcon icon={icons.borderStyle} className='mx-2' />
          <input
            type='text'
            placeholder='18'
            value={borderRadius}
            onChange={setBorderRadius}
            className='w-6 pl-1 border-b border-black focus:outline-none text-sm'
          />
        </div>

        <p className='text-sm mb-2'>Icon</p>
        <div className='w-24 h-24 mr-3 border border-black rounded flex justify-center items-center cursor-pointer'>
          <FontAwesomeIcon icon={icons.upload} />
        </div>
      </div>

      <div>
        <p className='text-sm'>Category</p>
        <Select
          options={categories}
          value={selectedCategory}
          onChange={onCategoryChange}
          maxMenuHeight={125}
          className='w-4/5 my-1'
        />

        <p className='text-sm mb-2 mt-3'>Fill</p>
        <ColorPicker color={color} setColor={setColor} />
      </div>
    </div>
  );
};
