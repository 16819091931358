import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPicker, VisibilitySelector } from '../../components/common';
import * as icons from '../../../assets/icons';

export default ({
  visibilitySelector,
  textVisibility,
  setTextVisibility,
  title,
  titleText,
  onTitleChange,
  fontFamilies,
  selectedFont,
  onFontChange,
  fontSize,
  onFontSizeChange,
  bold,
  italic,
  underline,
  onBoldClick,
  onItalicClick,
  onUnderlineClick,
  textAlign,
  onLeftClick,
  onCenterClick,
  onRightClick,
  onJustifyClick,
  lineHeight,
  onLineHeightChange,
  color,
  setColor,
}) => {
  const options = [
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'banana', label: 'Banana' },
    { value: 'apple', label: 'Apple' },
  ];

  return (
    <div>
      <div className='grid grid-cols-2'>
        <div className='mr-10'>
          <Select
            options={fontFamilies}
            maxMenuHeight={125}
            value={selectedFont}
            onChange={onFontChange}
          />

          <div className='flex items-end my-3'>
            <FontAwesomeIcon icon={icons.textHeight} />
            <input
              type='text'
              value={fontSize}
              placeholder='12'
              onChange={onFontSizeChange}
              className='mx-2 pl-1 w-8 border-b border-black focus:outline-none'
            />
            <Select options={options} maxMenuHeight={125} className='w-full' />
          </div>

          <div className='w-20 flex justify-between items-center'>
            <div
              className={`w-5 h-5 flex justify-center items-center ${
                bold && 'filter drop-shadow-lg bg-white'
              }`}
            >
              <FontAwesomeIcon
                icon={icons.bold}
                className='cursor-pointer'
                onClick={onBoldClick}
              />
            </div>
            <div
              className={`w-5 h-5 flex justify-center items-center ${
                italic && 'filter drop-shadow-lg bg-white'
              }`}
            >
              <FontAwesomeIcon
                icon={icons.italic}
                className='cursor-pointer mx-4'
                onClick={onItalicClick}
              />
            </div>
            <div
              className={`w-5 h-5 flex justify-center items-center ${
                underline && 'filter drop-shadow-lg bg-white'
              }`}
            >
              <FontAwesomeIcon
                icon={icons.underline}
                className='cursor-pointer'
                onClick={onUnderlineClick}
              />
            </div>
          </div>

          <div className='flex items-center mt-2'>
            <div className='w-24 flex justify-between items-center'>
              <div
                className={`w-5 h-5 flex justify-center items-center ${
                  textAlign === 'left' && 'filter drop-shadow-lg bg-white'
                }`}
              >
                <FontAwesomeIcon
                  icon={icons.alignLeft}
                  className='cursor-pointer'
                  onClick={onLeftClick}
                />
              </div>
              <div
                className={`w-5 h-5 flex justify-center items-center ${
                  textAlign === 'center' && 'filter drop-shadow-lg bg-white'
                }`}
              >
                <FontAwesomeIcon
                  icon={icons.alignCenter}
                  className='cursor-pointer'
                  onClick={onCenterClick}
                />
              </div>
              <div
                className={`w-5 h-5 flex justify-center items-center ${
                  textAlign === 'right' && 'filter drop-shadow-lg bg-white'
                }`}
              >
                <FontAwesomeIcon
                  icon={icons.alignRight}
                  className='cursor-pointer'
                  onClick={onRightClick}
                />
              </div>
              <div
                className={`w-5 h-5 flex justify-center items-center ${
                  textAlign === 'justify' && 'filter drop-shadow-lg bg-white'
                }`}
              >
                <FontAwesomeIcon
                  icon={icons.justify}
                  className='cursor-pointer'
                  onClick={onJustifyClick}
                />
              </div>
            </div>
            <FontAwesomeIcon icon={icons.textHeight} className='ml-4 mr-1' />
            <input
              type='text'
              placeholder='1'
              value={lineHeight}
              onChange={onLineHeightChange}
              className='w-8 pl-1 border-b border-black focus:outline-none'
            />
          </div>
        </div>

        <div>
          <div className='mb-1'>
            {visibilitySelector && (
              <VisibilitySelector
                text='Text Visibility'
                checked={textVisibility}
                setVisibility={setTextVisibility}
              />
            )}
          </div>
          <p className='text-sm mb-1'>Text Color</p>
          <ColorPicker color={color} setColor={setColor} />
        </div>
      </div>

      <div>
        {title && (
          <div className='flex items-center mt-3'>
            <p className='mr-10 text-sm'>Title</p>
            <input
              type='text'
              value={titleText}
              placeholder='Title'
              onChange={onTitleChange}
              className='w-4/5 pb-1 border-b-2 text-sm border-gray-400 focus:outline-none'
            />
          </div>
        )}
      </div>
    </div>
  );
};
