import React, { useState } from 'react';

export default () => {
  const [selectedOption, setSelectedOption] = useState('custom');

  const onValueChange = (value) => {
    setSelectedOption(value);
  };

  const data = [{ type: 'half' }, { type: 'full' }, { type: 'custom' }];

  return (
    <div className='flex items-center'>
      {data &&
        data.map((item, index) => {
          return (
            <label key={index} className=' w-40 flex flex-col items-center'>
              {item.type === 'custom' ? (
                <div className='my-3 text-sm'>CUSTOM</div>
              ) : (
                <div className='w-32 h-32 mb-3 bg-white filter drop-shadow-lg rounded-lg'>
                  <div className='h-20 bg-gray-400 rounded-t-lg'></div>
                  <div
                    className={`flex flex-col justify-center pl-2 ${
                      item.type === 'half' ? 'bg-white' : 'bg-gray-400'
                    } h-12 rounded-b-lg`}
                  >
                    <p className='text-xs'>Item</p>
                    <p className='text-xs'>0.00 $</p>
                  </div>
                </div>
              )}

              <input
                type='radio'
                style={{ height: '18px', width: '18px' }}
                checked={selectedOption === item.type}
                onChange={() => onValueChange(item.type)}
              />
            </label>
          );
        })}
    </div>
  );
};
