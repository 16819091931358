import * as types from '../../constants/actions.js';

export const setCategoriesStyling = (payload) => ({
  type: types.SET_CATEGORIES_STYLING,
  payload: payload,
});

export const setCategoriesOrder = (payload) => ({
  type: types.SET_CATEGORIES_ORDER,
  payload: payload,
});
