import React from 'react';
import Switch from 'react-switch';

export default ({ checked, onClick }) => {
  return (
    <Switch
      checked={checked}
      onChange={onClick}
      checkedIcon={false}
      uncheckedIcon={false}
      height={15}
      width={35}
      handleDiameter={20}
      onColor='#8BBAE8'
      offColor='#B7B7B7'
      onHandleColor='#1876D2'
      offHandleColor='#707070'
    />
  );
};
