import * as types from '../../constants/actions.js';

export const setHomeScreenStyling = (payload) => ({
  type: types.SET_HOMESCREEN_STYLING,
  payload: payload,
});

export const setHomeScreenOrder = (payload) => ({
  type: types.SET_HOMESCREEN_ORDER,
  payload: payload,
});
