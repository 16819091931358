import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import { Header, Title, Categories } from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const categories = useSelector((state) => state.categories);

  const headerColor = selectedColor(colors, 'primary');
  const title = categories.title.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${categories.order[0]}`}>
        {title && <Title text='Categories' styleData={categories.title} />}
      </div>
      <div className={`order-${categories.order[1]}`}>
        <Categories styleData={categories.category} />
      </div>
    </div>
  );
};
