import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions/newAddress.actions';
import { ItemEditor } from '../../components';
import { editorMapping } from './editorMapping';

export default () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const newAddressScreen = useSelector((state) => state.newAddressScreen);

  useEffect(() => setData(editorMapping(newAddressScreen)), [newAddressScreen]);

  const moveUp = (i) => {
    if (i > 0) {
      let order = _.cloneDeep(newAddressScreen.order);
      const firstIndex = order.findIndex((e) => e === i);
      const secondIndex = order.findIndex((e) => e === i + 1);
      [order[firstIndex], order[secondIndex]] = [order[secondIndex], order[firstIndex]];
      dispatch(actions.setNewAddressScreenOrder(order));
    }
  };

  const moveDown = (i) => {
    if (i < data.length - 1) {
      let order = _.cloneDeep(newAddressScreen.order);
      const firstIndex = order.findIndex((e) => e === i + 1);
      const secondIndex = order.findIndex((e) => e === i + 2);
      [order[firstIndex], order[secondIndex]] = [order[secondIndex], order[firstIndex]];
      dispatch(actions.setNewAddressScreenOrder(order));
    }
  };

  return (
    <div className='flex flex-col'>
      {data.map((items, index) => {
        return (
          <div key={index} className={`order-${newAddressScreen.order[index]}`}>
            <ItemEditor
              items={items}
              itemVisibility={items.visibility}
              setItemVisibility={items.setVisibility}
              moveUp={() => moveUp(newAddressScreen.order[index] - 1)}
              moveDown={() => moveDown(newAddressScreen.order[index] - 1)}
            />
          </div>
        );
      })}
    </div>
  );
};
