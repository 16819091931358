import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getApps } from '../../actions/apps.actions.js';
import { createAppRequest, deleteAppRequest } from '../../api/apps.api.js';
import { SubmittedApp, DraftApp, NewApp, NavBar } from '../../components';

const NEW_APP_ATTR = {
  title: 'New App',
  app_type: 'android',
  configuration: {
    colors: {
      primary: { rgb: { r: 229, g: 115, b: 115, a: 1 } },
      secondary: { rgb: { r: 0, g: 200, b: 83, a: 1 } },
      text_on_p: { rgb: { r: 255, g: 255, b: 255, a: 1 } },
      text_on_s: { rgb: { r: 255, g: 255, b: 255, a: 1 } },
    },
  },
};

export default () => {
  const dispatch = useDispatch();
  const draftApps = useSelector((state) => state.apps.androidDraft);
  const submittedApps = useSelector((state) => state.apps.androidSubmitted);

  useEffect(() => {
    dispatch(getApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewApp = () => {
    createAppRequest(NEW_APP_ATTR)
      .then(() => {
        dispatch(getApps());
        toast.success('New App Created!');
      })
      .catch(() => toast.error('unable to create new app'));
  };

  const deleteApp = (appId) => {
    deleteAppRequest(appId)
      .then(() => {
        dispatch(getApps());
        toast.success('App Deleted Successfully!');
      })
      .catch(() => toast.error('unable to delete app'));
  };

  return (
    <div className=''>
      <NavBar />
      <div>
        <h1 className='text-3xl ml-28 mt-10'>My Apps</h1>
        <div className='ml-32 mt-4 flex flex-wrap'>
          {submittedApps.map((app, index) => (
            <SubmittedApp key={index} app={app} />
          ))}
        </div>
      </div>
      <div>
        <h2 className='text-2xl ml-28 mt-10'>Draft Apps</h2>
        <div className='ml-32 mt-4 flex flex-wrap'>
          {draftApps.map((app, index) => (
            <DraftApp
              key={index}
              app={app}
              onCancelClick={() => deleteApp(app.id)}
            />
          ))}
          <NewApp onClick={createNewApp} />
        </div>
      </div>
    </div>
  );
};
