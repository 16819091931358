import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ onAddressClick, onPaymentClick, onSignoutClick }) => {
  const address = ['Address', 'City', 'Region/State', 'Country', 'Postal Code'];

  return (
    <div className='flex justify-center'>
      <div className='w-full mx-4 px-5 mt-10 bg-white filter drop-shadow-lg rounded-lg'>
        <p className='mt-5 text-lg font-medium text-center'>Adam Green</p>
        <p className='mt-2 text-sm text-center'>adam.green@email.com</p>

        <>
          <div className='mt-10 flex justify-between items-center'>
            <p>Primary Address</p>
            <FontAwesomeIcon
              icon={icons.edit}
              onClick={onAddressClick}
              className='cursor-pointer'
            />
          </div>
          <div className='mt-1 px-3 py-1 border-2 border-gray-500 rounded-md'>
            {address.map((item) => (
              <div
                key={item}
                className='w-4/5 my-0.5 text-sm flex justify-between items-center'
              >
                <p>{item}:</p>
                <p>xxxxxxxxxxxxx</p>
              </div>
            ))}
          </div>
        </>

        <>
          <div className='mt-5 flex justify-between items-center'>
            <p>Primary Payment Method</p>
            <FontAwesomeIcon
              icon={icons.edit}
              onClick={onPaymentClick}
              className='cursor-pointer'
            />
          </div>
          <div className='mt-1 px-3 py-1 border-2 border-gray-500 rounded-md'>
            <div className='w-4/5 my-1 flex justify-between items-center'>
              <p>Card Type</p>
              <p className='text-sm'>****0000</p>
            </div>
            <div className='w-4/5 my-0.5 mt-2 text-sm flex justify-between items-center'>
              <p>First Name</p>
              <p>Last Name</p>
            </div>
            <p className='mb-2 text-sm'>Expiration date</p>
          </div>
        </>

        <div className='mx-5 mt-16 mb-5 flex justify-evenly items-center text-sm'>
          <div className='flex flex-col items-center'>
            <FontAwesomeIcon icon={icons.delete} />
            <p>Delete profile</p>
          </div>
          <div onClick={onSignoutClick} className='flex flex-col items-center'>
            <FontAwesomeIcon icon={icons.signout} className='cursor-pointer' />
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};
