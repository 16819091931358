import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default () => {
  const [selectedOption, setSelectedOption] = useState('custom');

  const onValueChange = (value) => {
    setSelectedOption(value);
  };

  const data = [
    { light: true, type: 'light' },
    { light: false, type: 'dark' },
    { type: 'custom' },
  ];

  return (
    <div className='grid grid-cols-2'>
      {data.map((item, index) => (
        <label key={index} className='w-64 mb-4 flex flex-col items-center'>
          {item.type === 'custom' ? (
            <div className='my-3 text-sm'>CUSTOM</div>
          ) : (
            <div
              className={`w-64 h-28 mb-3 filter drop-shadow-lg ${
                item.light ? 'bg-white' : 'bg-gray-500'
              } rounded-lg flex`}
            >
              <div className='w-2/5'>
                <div
                  className={`ml-4 mt-1 ${
                    item.light ? 'text-gray-500' : 'text-white'
                  }`}
                >
                  <p className='text-sm mb-0.5'>First Name</p>
                  <p className='text-xs'>Email</p>
                  <p className='text-xs'>Address</p>
                  <p className='text-xs'>City</p>
                  <p className='text-xs'>State/Region</p>
                  <p className='text-xs'>Country</p>
                </div>
              </div>
              <div className='w-2/5'>
                <div
                  className={`ml-4 mt-1 ${
                    item.light ? 'text-gray-500' : 'text-white'
                  }`}
                >
                  <p className='text-sm mb-0.5'>Last Name</p>
                  <p className='text-xs'>Postal Code</p>
                </div>
              </div>
              <div className='w-1/5'>
                <div className='ml-6 mt-2'>
                  <FontAwesomeIcon
                    icon={icons.close}
                    color={item.light ? 'gray' : 'white'}
                  />
                </div>
              </div>
            </div>
          )}

          <input
            type='radio'
            style={{ height: '18px', width: '18px' }}
            checked={selectedOption === item.type}
            onChange={() => onValueChange(item.type)}
          />
        </label>
      ))}
    </div>
  );
};
