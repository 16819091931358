import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPicker, VisibilitySelector } from '../../components/common';
import * as icons from '../../../assets/icons';

export default ({
  iconSelector,
  borderVisibility,
  setBorderVisibility,
  borderColor,
  setBorderColor,
  fillVisibility,
  setFillVisibility,
  fillColor,
  setFillColor,
  customShadow,
  shadowVisibilty,
  setShadowVisibility,
  shadowColor,
  setShadowColor,
  borderRadius,
  setBorderRadius,
  iconVisibility,
  setIconVisibility,
}) => {
  return (
    <div className='grid grid-cols-3'>
      <div>
        <div className='my-2'>
          <VisibilitySelector
            text='Border Visibility'
            checked={borderVisibility}
            setVisibility={setBorderVisibility}
          />
        </div>
        <p className='text-sm mb-2'>Border</p>
        <ColorPicker color={borderColor} setColor={setBorderColor} />
      </div>

      {customShadow ? (
        <div>
          <div className='my-2'>
            <VisibilitySelector
              text='Shadows'
              checked={shadowVisibilty}
              setVisibility={setShadowVisibility}
            />
          </div>
          <p className='text-sm mb-2'>Shadow</p>
          <ColorPicker color={shadowColor} setColor={setShadowColor} />
        </div>
      ) : (
        <div>
          <div className='my-2'>
            <VisibilitySelector
              text='Fill Visibility'
              checked={fillVisibility}
              setVisibility={setFillVisibility}
            />
          </div>
          <p className='text-sm mb-2'>Fill</p>
          <ColorPicker color={fillColor} setColor={setFillColor} />
        </div>
      )}

      <div>
        {!customShadow && (
          <div className='my-2'>
            <VisibilitySelector
              text='Shadows'
              checked={shadowVisibilty}
              setVisibility={setShadowVisibility}
            />
          </div>
        )}

        <div className='flex items-center'>
          <p className='text-sm'>Corner radius</p>
          <FontAwesomeIcon icon={icons.borderStyle} className='mx-2' />
          <input
            type='text'
            placeholder='18'
            value={borderRadius}
            onChange={setBorderRadius}
            className='w-6 pl-1 border-b border-black focus:outline-none text-sm'
          />
        </div>

        {iconSelector && (
          <div className='my-2'>
            <VisibilitySelector
              text='Icon'
              checked={iconVisibility}
              setVisibility={setIconVisibility}
            />
          </div>
        )}
      </div>
    </div>
  );
};
