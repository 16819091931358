import React, { useState } from 'react';
import { CustomColorPicker, PaletteColorPicker } from '../../components';
import { rgbToHex, selectedColor } from '../../helpers/colors.helper';

export default ({
  colors,
  selectedConfig,
  setPrimary,
  setSecondary,
  handleColorChange,
  handleManualChange,
}) => {
  const [selected, setSelected] = useState('PALETTE');

  return (
    <>
      <div className='flex items-center mb-5'>
        {['PALETTE', 'CUSTOM'].map((item, index) => (
          <p
            key={index}
            onClick={() => setSelected(item)}
            className={`mr-4 px-3 pb-0.5 cursor-pointer ${
              item === selected && 'border-b-2 border-blue-500'
            }`}
          >
            {item}
          </p>
        ))}
      </div>

      {selected === 'PALETTE' && (
        <PaletteColorPicker
          color={colors[selectedConfig]}
          setColor={(color) => handleColorChange(color)}
        />
      )}
      {selected === 'CUSTOM' && (
        <CustomColorPicker
          color={colors[selectedConfig]}
          colorHex={rgbToHex(colors, selectedConfig)}
          setColor={(color) => handleColorChange(color)}
          handleManualChange={handleManualChange}
        />
      )}

      <div className='w-full mt-5 flex justify-evenly items-center'>
        <div className='flex flex-col items-center'>
          <p className='mb-2 text-sm'>Primary Colour</p>
          <label className='flex flex-col items-center cursor-pointer'>
            <div
              className='w-56 h-32 flex justify-center items-center rounded-lg'
              style={{ backgroundColor: selectedColor(colors, 'primary') }}
            >
              <p>{rgbToHex(colors, 'primary')}</p>
            </div>

            <input
              type='radio'
              checked={selectedConfig === 'primary'}
              onChange={setPrimary}
              className='mt-2'
              style={{ height: '18px', width: '18px' }}
            />
          </label>
        </div>

        <div className='flex flex-col items-center'>
          <p className='mb-2 text-sm'>Secondary Colour</p>
          <label className='cursor-pointer flex flex-col items-center'>
            <div
              className='w-56 h-32 flex justify-center items-center rounded-lg'
              style={{ backgroundColor: selectedColor(colors, 'secondary') }}
            >
              <p>{rgbToHex(colors, 'secondary')}</p>
            </div>

            <input
              type='radio'
              checked={selectedConfig === 'secondary'}
              onChange={setSecondary}
              className='mt-2'
              style={{ height: '18px', width: '18px' }}
            />
          </label>
        </div>
      </div>
    </>
  );
};
