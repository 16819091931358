import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import {
  Header,
  Title,
  InputPreview,
  CheckList,
  Button,
} from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const signupScreen = useSelector((state) => state.signupScreen);

  const headerColor = selectedColor(colors, 'primary');
  const title = signupScreen.title.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${signupScreen.order[0]} ${title && 'mb-8'}`}>
        {title && <Title text='Log In' styleData={signupScreen.title} />}
      </div>
      <div className={`order-${signupScreen.order[1]}`}>
        <InputPreview text='Name' styleData={signupScreen.nameInput} />
      </div>
      <div className={`order-${signupScreen.order[2]}`}>
        <InputPreview text='E-Mail' styleData={signupScreen.emailInput} />
      </div>
      <div className={`order-${signupScreen.order[3]}`}>
        <InputPreview text='Password' styleData={signupScreen.password} />
      </div>
      <div className={`order-${signupScreen.order[4]}`}>
        <InputPreview
          text='Confirm Password'
          styleData={signupScreen.confirmPassword}
        />
      </div>
      <div className={`order-${signupScreen.order[5]}`}>
        <InputPreview
          text='Phone Number (Optional)'
          styleData={signupScreen.phoneNumber}
        />
      </div>
      <div className={`order-${signupScreen.order[6]} my-8`}>
        <CheckList styleData={signupScreen.checkList} />
      </div>
      <div className={`order-${signupScreen.order[7]}`}>
        <Button text='LOG IN' styleData={signupScreen.loginButton} />
      </div>
      <div className={`order-${signupScreen.order[8]}`}>
        <Button text='SIGN UP' styleData={signupScreen.signupButton} />
      </div>
    </div>
  );
};
