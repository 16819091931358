import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.singleItemScreen, action) => {
  switch (action.type) {
    case types.SET_SINGLE_ITEM_SCREEN_STYLING: {
      let singleItemScreen = _.cloneDeep(state);

      singleItemScreen = action.payload;

      return singleItemScreen;
    }
    case types.SET_SINGLE_ITEM_SCREEN_ORDER: {
      let singleItemScreen = _.cloneDeep(state);

      singleItemScreen.order = action.payload;

      return singleItemScreen;
    }
    default:
      return state;
  }
};
