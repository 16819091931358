export const SET_ANDROID_DRAFT_APPS = 'setAndroidDraftApps';
export const SET_ANDROID_SUBMITTED_APPS = 'setAndroidSubmittedApps';
export const SET_MOBILE_TEMPLATES = 'setMobileTemplates';
export const SET_APP_COLORS = 'setAppColors';
export const SET_APP_TEMPLATE = 'setAppTemplate';
export const SET_HOMESCREEN_STYLING = 'setHomescreenStyling';
export const SET_HOMESCREEN_ORDER = 'setHomescreenOrder';
export const SET_CATEGORIES_STYLING = 'setCategoriesStyling';
export const SET_CATEGORIES_ORDER = 'setCategoriesOrder';
export const SET_CATEGORY_STYLING = 'setCategoryStyling';
export const SET_CATEGORY_ORDER = 'setCategoryOrder';
export const SET_LOGIN_SCREEN_STYLING = 'setLoginScreenStyling';
export const SET_LOGIN_SCREEN_ORDER = 'setLoginScreenOrder';
export const SET_SIGNUP_SCREEN_STYLING = 'setSignupScreenStyling';
export const SET_SIGNUP_SCREEN_ORDER = 'setSignupScreenOrder';
export const SET_ADDRESS_SCREEN_STYLING = 'setAddressScreenStyling';
export const SET_ADDRESS_SCREEN_ORDER = 'setAddressScreenOrder';
export const SET_NEW_ADDRESS_SCREEN_STYLING = 'setNewAddressScreenStyling';
export const SET_NEW_ADDRESS_SCREEN_ORDER = 'setNewAddressScreenOrder';
export const SET_PAYMENT_METHODS_STYLING = 'setPaymentMethodsStyling';
export const SET_PAYMENT_METHODS_ORDER = 'setPaymentMethodsOrder';
export const SET_NEW_PAYMENT_SCREEN_STYLING = 'setNewPaymentScreenStyling';
export const SET_NEW_PAYMENT_SCREEN_ORDER = 'setNewPaymentScreenOrder';
export const SET_SINGLE_ITEM_SCREEN_STYLING = 'setSingleItemcreenStyling';
export const SET_SINGLE_ITEM_SCREEN_ORDER = 'setSingleItemScreenOrder';
