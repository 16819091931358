import * as types from '../../constants/actions.js';

export const setPaymentMethodsStyling = (payload) => ({
  type: types.SET_PAYMENT_METHODS_STYLING,
  payload: payload,
});

export const setPaymentMethodsOrder = (payload) => ({
  type: types.SET_PAYMENT_METHODS_ORDER,
  payload: payload,
});
