import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validateSessionToken } from '../api/session.api';
import { setHeaders, setUserId } from './userAuth.helper';

export default () => {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    validateSessionToken(token)
      .then((res) => {
        setUserId(res.data.data.id);
        setHeaders(res.headers);
        history.push('/');
      })
      .catch(() => alert('unable to validate session token'));

    checkHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkHeight = () => {
    if (window.innerWidth < 768) {
      alert('your screen size is too small, UI may get distorted');
    }
  };

  return <div>loading...</div>;
};
