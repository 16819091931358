import React from 'react';
import { toast } from 'react-toastify';
import defaultImage from '../../../assets/images/default-image-2.png'

export default ({
  id,
  alt,
  src,
  width,
  height,
  setURL,
  setSrc,
  editable,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
}) => {
  let check = false;

  const triggerFileInput = () => {
    document.getElementById(id).click();
  };

  const handleChange = (e) => {
    let image = new Image();
    const url = e.target.files[0];
    image.src = url && URL.createObjectURL(url);

    image.onload = () => {
      if (url.type === 'image/png') {
        if (minWidth && minHeight && maxWidth && maxHeight) {
          if (
            image.height >= minHeight &&
            image.width >= minWidth &&
            image.height <= maxHeight &&
            image.width <= maxWidth
          ) {
            check = true;
          } else {
            toast.warning('upload a file with specified dimension');
            check = false;
          }
        }

        if (width && height) {
          if (image.width === width && image.height === height) {
            check = true;
          } else {
            toast.warning('upload a file with specified dimension');
            check = false;
          }
        }
      } else {
        toast.warning('please upload a PNG file');
        check = false;
      }

      if (check) {
        setURL(url);
        setSrc(image.src);
      }
    };
  };

  return (
    <>
      <img
        src={src || defaultImage}
        alt={alt}
        className='w-44 h-44 m-2 rounded-lg'
        onClick={editable && triggerFileInput}
      />
      <input
        id={id}
        type='file'
        accept='image/png'
        className='hidden'
        onChange={handleChange}
      />
    </>
  );
};
