import * as types from '../../constants/actions.js';

export const setLoginScreenStyling = (payload) => ({
  type: types.SET_LOGIN_SCREEN_STYLING,
  payload: payload,
});

export const setLoginScreenOrder = (payload) => ({
  type: types.SET_LOGIN_SCREEN_ORDER,
  payload: payload,
});
