import {
  AndroidApps,
  IOSApps,
  Details,
  Templates,
  Colors,
  Design,
  Review,
  Preview,
} from '../pages';
import SessionHandler from '../helpers/session.helper';

export const routes = [
  {
    path: '/',
    exact: true,
    component: AndroidApps,
  },
  {
    path: '/session/:token+',
    component: SessionHandler,
  },
  {
    path: '/android',
    component: AndroidApps,
  },
  {
    path: '/ios',
    component: IOSApps,
  },
  {
    path: '/apps/:appId/details',
    component: Details,
  },
  {
    path: '/apps/:appId/templates',
    component: Templates,
  },
  {
    path: '/apps/:appId/colors',
    component: Colors,
  },
  {
    path: '/apps/:appId/design',
    component: Design,
  },
  {
    path: '/apps/:appId/review',
    component: Review,
  },
  {
    path: '/apps/:appId/preview/:key',
    component: Preview,
  },
];
