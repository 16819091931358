import React, { useState } from 'react';
import {
  Header,
  ProfileDetailsCard,
  ModifyPrimaryAddress,
  ModifyPrimaryPayment,
  BottomNavigationBar,
  SignoutCard,
} from '../../components';

export default () => {
  const [selected, setSelected] = useState('default');

  return (
    <div>
      <Header title='My Profile' color='white' />

      {selected === 'default' && (
        <ProfileDetailsCard
          onAddressClick={() => setSelected('address')}
          onPaymentClick={() => setSelected('payment')}
          onSignoutClick={() => setSelected('signout')}
        />
      )}

      {selected === 'address' && (
        <ModifyPrimaryAddress onSaveClick={() => setSelected('default')} />
      )}
      {selected === 'payment' && (
        <ModifyPrimaryPayment onSaveClick={() => setSelected('default')} />
      )}
      {selected === 'signout' && (
        <SignoutCard onLoginClick={() => setSelected('default')} />
      )}

      <BottomNavigationBar />
    </div>
  );
};
