import * as types from '../../constants/actions.js';

export const setNewAddressScreenStyling = (payload) => ({
  type: types.SET_NEW_ADDRESS_SCREEN_STYLING,
  payload: payload,
});

export const setNewAddressScreenOrder = (payload) => ({
  type: types.SET_NEW_ADDRESS_SCREEN_ORDER,
  payload: payload,
});
