import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ onLoginClick }) => {
  return (
    <div className='mt-16 flex justify-center items-center'>
      <div className='w-4/5 pt-10 bg-white filter drop-shadow-lg rounded-lg'>
        <p className='text-center'>You are currently not logged in.</p>
        <p className='text-center'>Please login or Sign up</p>

        <div className='my-10 flex justify-evenly items-center'>
          <div className='flex flex-col items-center'>
            <FontAwesomeIcon icon={icons.edit} />
            <p className='mt-2'>Sign up</p>
          </div>
          <div className='flex flex-col items-center'>
            <FontAwesomeIcon
              icon={icons.signin}
              onClick={onLoginClick}
              className='cursor-pointer'
            />
            <p className='mt-2'>Login</p>
          </div>
        </div>
      </div>
    </div>
  );
};
