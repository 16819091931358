import React from 'react';
import { InputPreview } from '../../components';

export default ({ onSaveClick }) => {
  const inputStyling = {
    text: {
      fontFamily: { value: 'sans-serif', label: 'Sans Serif' },
      fontStyle: '',
      color: '#9DA3B0',
      fontSize: 15,
      bold: false,
      italic: false,
      underline: false,
      textAlign: 'left',
      lineHeight: 1.5,
    },
    design: {
      entireCard: {
        borderVisibility: true,
        borderColor: '#9DA3B0',
        fillVisibility: true,
        fillColor: '#FFF',
        shadow: true,
        borderRadius: 20,
      },
      size: {
        alignment: 'center',
        width: 330,
        height: 32,
      },
    },
  };

  return (
    <>
      <p className='mt-10 text-lg text-center'>Modify Profile</p>
      <p className='mt-3 ml-8 mb-1'>Primary Address</p>

      <InputPreview text='First Name' styleData={inputStyling} />
      <InputPreview text='Last Name' styleData={inputStyling} />
      <InputPreview text='E-Mail' styleData={inputStyling} />
      <InputPreview text='Country' dropDown={true} styleData={inputStyling} />
      <InputPreview text='State / Region' styleData={inputStyling} />
      <InputPreview text='Address' styleData={inputStyling} />
      <InputPreview text='Phone' styleData={inputStyling} />
      <InputPreview text='City' styleData={inputStyling} />
      <InputPreview text='ZIP / Postal Code' styleData={inputStyling} />

      <div className='mt-10 flex justify-center items-center'>
        <p
          className='px-5 py-1 text-blue-500 bg-white filter drop-shadow-lg rounded-md cursor-pointer'
          onClick={onSaveClick}
        >
          Save
        </p>
      </div>
    </>
  );
};
