import React from 'react';

export default ({ text, styleData }) => {
  const backgroundStyling = {
    width: styleData.design.size.width,
    height: styleData.design.size.height,
    borderColor: styleData.design.entireCard.borderColor,
    borderRadius: styleData.design.entireCard.borderRadius,
    backgroundColor: styleData.design.entireCard.fillVisibility
      ? styleData.design.entireCard.fillColor
      : 'transparent',
  };

  const textStyling = {
    color: styleData.text.color,
    fontSize: styleData.text.fontSize,
    textAlign: styleData.text.textAlign,
    lineHeight: styleData.text.lineHeight,
    fontFamily: styleData.text.fontFamily.value,
    fontWeight: styleData.text.bold && 'bold',
    fontStyle: styleData.text.italic && 'italic',
    textDecoration: styleData.text.underline && 'underline',
  };

  return (
    <div className={`flex justify-${styleData.design.size.alignment}`}>
      <div
        className={`my-2 flex items-center ${
          styleData.design.entireCard.borderVisibility && 'border-2'
        } ${styleData.design.entireCard.shadow && 'filter drop-shadow-lg'}`}
        style={backgroundStyling}
      >
        <div className='w-full'>
          <p className='mx-5' style={textStyling}>
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};
