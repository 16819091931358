import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectedColor } from '../../../helpers/colors.helper';
import { Header, Title, InputPreview, Button } from '../../components';
import * as icons from '../../../assets/icons';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const loginScreen = useSelector((state) => state.loginScreen);

  const headerColor = selectedColor(colors, 'primary');
  const title = loginScreen.title.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${loginScreen.order[0]} ${title && 'mb-10'}`}>
        {title && <Title text='Log In' styleData={loginScreen.title} />}
      </div>
      <div className={`order-${loginScreen.order[1]}`}>
        <InputPreview text='E-Mail' styleData={loginScreen.emailInput} />
      </div>
      <div className={`order-${loginScreen.order[2]}`}>
        <InputPreview text='Password' styleData={loginScreen.password} />
        <div className='mx-8 mt-2 mb-10 flex items-center'>
          <FontAwesomeIcon icon={icons.checkBox} color='blue' />
          <p className='ml-5'>Keep me logged in</p>
        </div>
      </div>
      <div className={`order-${loginScreen.order[3]}`}>
        <p className='text-center mb-5'>Forgot Password?</p>
        <Button text='LOG IN' styleData={loginScreen.loginButton} />
      </div>
      <div className={`order-${loginScreen.order[4]}`}>
        <Button text='SIGN UP' styleData={loginScreen.signupButton} />
      </div>
    </div>
  );
};
