import React from 'react';
import { CategoryDefaultImage } from '../../../assets/images';
import { selectedColor } from '../../../helpers/colors.helper';

export default ({ styleData }) => {
  const backgroundImage = {
    borderColor: styleData.design.borderColor,
    borderRadius: styleData.design.borderRadius,
    backgroundImage: `url(${CategoryDefaultImage})`,
    backgroundPosition: 'center',
  };

  const backgroundStyling = {
    backgroundColor: selectedColor(styleData.overlay.color),
    borderColor: styleData.design.borderColor,
    borderRadius: styleData.design.borderRadius,
  };

  const textStyling = {
    color: styleData.text.color,
    fontSize: styleData.text.fontSize,
    textAlign: styleData.text.textAlign,
    lineHeight: styleData.text.lineHeight,
    fontFamily: styleData.text.fontFamily.value,
    fontWeight: styleData.text.bold && 'bold',
    fontStyle: styleData.text.italic && 'italic',
    textDecoration: styleData.text.underline && 'underline',
  };

  return (
    <div className='mt-4 flex justify-center'>
      <div className='flex justify-center flex-wrap' style={{ width: '90%' }}>
        {[1, 2, 3, 4].map((index) => (
          <div
            key={index}
            style={backgroundImage}
            className={`w-40 h-24 mx-1 my-1 flex items-center ${
              styleData.design.borderVisibility && 'border-2'
            } ${styleData.design.shadow && 'filter drop-shadow-lg'}`}
          >
            <div
              style={backgroundStyling}
              className='w-40 h-24 flex items-center'
            >
              <div className='w-full'>
                {styleData.text.visibility && (
                  <p style={textStyling}>Category {index}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
