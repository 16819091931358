import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import { Header, ProductCard } from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const singleItemScreen = useSelector((state) => state.singleItemScreen);

  const headerColor = selectedColor(colors, 'primary');

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${singleItemScreen.order[0]}`}>
        <ProductCard styleData={singleItemScreen.item} />
      </div>
    </div>
  );
};
