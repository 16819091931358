import React from 'react';
import Category from '../../../assets/images/category.png';
import { selectedColor } from '../../../helpers/colors.helper';

export default ({ styleData }) => {
  return (
    <div className={`flex justify-${styleData.design.size.alignment}`}>
      <div
        className={`mt-5 flex justify-center items-center ${
          styleData.design.entireCard.borderVisibility && 'border-2'
        } ${styleData.design.entireCard.shadow && 'filter drop-shadow-lg'}`}
        style={{
          width: styleData.design.size.width,
          height: styleData.design.size.height,
          borderColor: styleData.design.entireCard.borderColor,
          borderRadius: styleData.design.entireCard.borderRadius,
          backgroundImage: `url(${Category})`,
          backgroundPosition: 'center',
        }}
      >
        <div
          style={{
            width: styleData.design.size.width,
            height: styleData.design.size.height,
            borderColor: styleData.design.entireCard.borderColor,
            borderRadius: styleData.design.entireCard.borderRadius,
            backgroundColor: styleData.design.entireCard.fillVisibility
              ? selectedColor(styleData.design.entireCard.fillColor)
              : 'transparent',
          }}
        />
      </div>
    </div>
  );
};
