import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import {
  Header,
  Title,
  CategoryImage,
  Search,
  ItemScrollList,
  ItemList,
} from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const category = useSelector((state) => state.category);

  const headerColor = selectedColor(colors, 'primary');
  const title = category.title.visibility;
  const image = category.categoryImage.visibility;
  const scroll = category.itemScrollList.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${category.order[0]}`}>
        {title && <Title text='Category Name' styleData={category.title} />}
      </div>
      <div className={`order-${category.order[1]}`}>
        {image && <CategoryImage styleData={category.categoryImage} />}
      </div>
      <div className={`order-${category.order[2]}`}>
        {scroll && <ItemScrollList styleData={category.itemScrollList} />}
      </div>
      <div className={`order-${category.order[3]}`}>
        <ItemList styleData={category.itemList} />
      </div>
      <div className={`order-${category.order[4]}`}>
        <Search styleData={category.searchBar} />
      </div>
    </div>
  );
};
