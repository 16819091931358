import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ styleData }) => {
  const ICONS = {
    list: icons.list,
    bolt: icons.bolt,
    heart: icons.heart,
  };

  return (
    <div className={`flex justify-${styleData.design.size.alignment}`}>
      <div className='w-11/12 mt-5 flex items-center justify-evenly'>
        {styleData.components.map((item, index) => (
          <React.Fragment key={index}>
            {item.visibility && (
              <div className='flex flex-col justify-center items-center'>
                <div
                  className='flex justify-center items-center'
                  style={{
                    width: styleData.design.size.width,
                    height: styleData.design.size.height,
                    borderRadius: item.borderRadius,
                    backgroundColor: item.backgroundColor,
                  }}
                >
                  <FontAwesomeIcon
                    icon={ICONS[item.icon]}
                    color='white'
                    size='lg'
                  />
                </div>
                <div className='text-sm mt-1'>{item.category.label}</div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
