const componentToHex = (num) => {
  let hex = num.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (colors, type) => {
  const R = +colors[type].rgb.r;
  const G = +colors[type].rgb.g;
  const B = +colors[type].rgb.b;

  return '#' + componentToHex(R) + componentToHex(G) + componentToHex(B);
};

export const selectedColor = (colors, type) => {
  return type
    ? `rgba(${colors[type].rgb.r},${colors[type].rgb.g},${colors[type].rgb.b},${colors[type].rgb.a})`
    : `rgba(${colors.rgb.r},${colors.rgb.g},${colors.rgb.b},${colors.rgb.a})`;
};
