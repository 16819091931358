import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ styleData }) => {
  const backgroundStyling = {
    width: styleData.design.size.width,
    height: styleData.design.size.height,
    borderColor: styleData.design.entireCard.borderColor,
    borderRadius: styleData.design.entireCard.borderRadius,
    backgroundColor: styleData.design.entireCard.fillVisibility
      ? styleData.design.entireCard.fillColor
      : 'transparent',
  };

  const textStyling = (text) => {
    const fontSize = styleData.text.fontSize;

    return {
      color: styleData.text.color,
      fontSize: text === 'name' ? fontSize + 4 : fontSize,
      textAlign: styleData.text.textAlign,
      lineHeight: styleData.text.lineHeight,
      fontFamily: styleData.text.fontFamily.value,
      fontWeight: styleData.text.bold && 'bold',
      fontStyle: styleData.text.italic && 'italic',
      textDecoration: styleData.text.underline && 'underline',
    };
  };

  return (
    <div className={`mt-5 flex justify-${styleData.design.size.alignment}`}>
      <div
        style={backgroundStyling}
        className={`flex ${
          styleData.design.entireCard.shadow && 'filter drop-shadow-lg'
        } ${styleData.design.entireCard.borderVisibility && 'border-2'}`}
      >
        <div className='w-2/5'>
          <div className='ml-5 mt-2'>
            <p style={textStyling('name')} className='mb-1'>
              First Name
            </p>
            <p style={textStyling()}>Email</p>
            <p style={textStyling()}>Address</p>
            <p style={textStyling()}>City</p>
            <p style={textStyling()}>State/Region</p>
            <p style={textStyling()}>Country</p>
          </div>
        </div>
        <div className='w-2/5'>
          <div className='ml-5 mt-2'>
            <p style={textStyling('name')} className='mb-1'>
              Last Name
            </p>
            <p style={textStyling()}>Postal Code</p>
          </div>
        </div>
        <div className='w-1/5'>
          <div className='ml-10 mt-1'>
            <FontAwesomeIcon icon={icons.close} />
          </div>
        </div>
      </div>
    </div>
  );
};
