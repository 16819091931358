import React from 'react';
import { ItemCard } from '../../components';

export default ({ styleData }) => {
  return (
    <div
      className={`mt-3 flex flex-col items-${styleData.design.size.alignment}`}
    >
      {styleData.title.visibility && (
        <div style={{ width: '85%' }}>
          <p
            style={{
              color: styleData.title.color,
              fontSize: styleData.title.fontSize,
              textAlign: styleData.title.textAlign,
              lineHeight: styleData.title.lineHeight,
              fontFamily: styleData.title.fontFamily.value,
              fontWeight: styleData.title.bold && 'bold',
              fontStyle: styleData.title.italic && 'italic',
              textDecoration: styleData.title.underline && 'underline',
            }}
          >
            {styleData.title.text || 'Title'}
          </p>
        </div>
      )}
      <div
        className={`grid grid-cols-${styleData.category.itemsPerRow}`}
        style={{ width: '90%' }}
      >
        {[1, 2, 3, 4].map((index) => {
          return <ItemCard key={index} styleData={styleData} />;
        })}
      </div>
    </div>
  );
};
