import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchApp } from '../../api/apps.api';
import { TopBar, ReviewCard } from '../../components';

export default () => {
  const { appId } = useParams();
  const [app, setApp] = useState({});

  useEffect(() => {
    fetchApp(appId)
      .then((res) => setApp(res.data))
      .catch(() => toast.error('unable to get app data'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TopBar appId={appId} />

      <div className='flex justify-center'>
        <div className='w-3/5 my-5'>
          <p className='text-3xl mb-3'>Review</p>
          <ReviewCard
            name={app.title}
            status={app.status}
            reviewId={app.review_id}
          />
        </div>
      </div>
    </div>
  );
};
