import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/paymentMethods.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
  AlignmentEditor,
  TitleStyle,
  PaymentCardStyle,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

const defaultStyle = {
  paymentCard: {
    alignment: 'center',
    width: 330,
    height: 110,
  },
};

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const paymentMethods = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setPaymentMethodsStyling(paymentMethods));
  };

  const title = {
    visibility: paymentMethods.title.visibility,
    selectedFont: paymentMethods.title.text.fontFamily,
    fontSize: paymentMethods.title.text.fontSize,
    bold: paymentMethods.title.text.bold,
    italic: paymentMethods.title.text.italic,
    underline: paymentMethods.title.text.underline,
    textAlign: paymentMethods.title.text.textAlign,
    lineHeight: paymentMethods.title.text.lineHeight,
    color: paymentMethods.title.text.color,
    borderVisibility: paymentMethods.title.design.borderVisibility,
    borderColor: paymentMethods.title.design.borderColor,
    fillVisibility: paymentMethods.title.design.fillVisibility,
    fillColor: paymentMethods.title.design.fillColor,
    shadow: paymentMethods.title.design.shadow,
    borderRadius: paymentMethods.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      paymentMethods.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      paymentMethods.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      paymentMethods.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      paymentMethods.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      paymentMethods.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      paymentMethods.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      paymentMethods.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      paymentMethods.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      paymentMethods.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      paymentMethods.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      paymentMethods.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      paymentMethods.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      paymentMethods.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      paymentMethods.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      paymentMethods.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const paymentCard = {
    selectedFont: paymentMethods.paymentCard.text.fontFamily,
    fontSize: paymentMethods.paymentCard.text.fontSize,
    bold: paymentMethods.paymentCard.text.bold,
    italic: paymentMethods.paymentCard.text.italic,
    underline: paymentMethods.paymentCard.text.underline,
    textAlign: paymentMethods.paymentCard.text.textAlign,
    lineHeight: paymentMethods.paymentCard.text.lineHeight,
    color: paymentMethods.paymentCard.text.color,
    borderVisibility: paymentMethods.paymentCard.design.entireCard.borderVisibility,
    borderColor: paymentMethods.paymentCard.design.entireCard.borderColor,
    fillVisibility: paymentMethods.paymentCard.design.entireCard.fillVisibility,
    fillColor: paymentMethods.paymentCard.design.entireCard.fillColor,
    shadow: paymentMethods.paymentCard.design.entireCard.shadow,
    borderRadius: paymentMethods.paymentCard.design.entireCard.borderRadius,
    alignment: paymentMethods.paymentCard.design.size.alignment,
    width: paymentMethods.paymentCard.design.size.width,
    height: paymentMethods.paymentCard.design.size.height,
  };

  const handlePaymentCard = {
    fontChange: (font) => {
      paymentMethods.paymentCard.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      paymentMethods.paymentCard.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      paymentMethods.paymentCard.text.bold = !paymentCard.bold;
      setStyling();
    },
    italicClick: () => {
      paymentMethods.paymentCard.text.italic = !paymentCard.italic;
      setStyling();
    },
    underlineClick: () => {
      paymentMethods.paymentCard.text.underline = !paymentCard.underline;
      setStyling();
    },
    textAlign: (value) => {
      paymentMethods.paymentCard.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      paymentMethods.paymentCard.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      paymentMethods.paymentCard.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      paymentMethods.paymentCard.design.entireCard.borderVisibility = !paymentCard.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      paymentMethods.paymentCard.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      paymentMethods.paymentCard.design.entireCard.fillVisibility = !paymentCard.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      paymentMethods.paymentCard.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      paymentMethods.paymentCard.design.entireCard.shadow = !paymentCard.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      paymentMethods.paymentCard.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      paymentMethods.paymentCard.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      paymentMethods.paymentCard.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      paymentMethods.paymentCard.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      paymentMethods.paymentCard.design.size = defaultStyle.paymentCard;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerPaymentCard = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={paymentCard.borderVisibility}
          setBorderVisibility={handlePaymentCard.borderVisibility}
          borderColor={paymentCard.borderColor}
          setBorderColor={handlePaymentCard.borderColor}
          fillVisibility={paymentCard.fillVisibility}
          setFillVisibility={handlePaymentCard.fillVisibility}
          fillColor={paymentCard.fillColor}
          setFillColor={handlePaymentCard.fillColor}
          shadowVisibilty={paymentCard.shadow}
          setShadowVisibility={handlePaymentCard.shadow}
          borderRadius={paymentCard.borderRadius}
          setBorderRadius={handlePaymentCard.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={paymentCard.alignment}
          onRightClick={() => handlePaymentCard.elementAlign('end')}
          onCenterClick={() => handlePaymentCard.elementAlign('center')}
          onLeftClick={() => handlePaymentCard.elementAlign('start')}
          width={paymentCard.width}
          setElementWidth={handlePaymentCard.width}
          height={paymentCard.height}
          setElementHeight={handlePaymentCard.height}
          onResetPress={handlePaymentCard.resetSize}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Payment Card',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={paymentCard.selectedFont}
              onFontChange={handlePaymentCard.fontChange}
              fontSize={paymentCard.fontSize}
              onFontSizeChange={handlePaymentCard.fontSize}
              bold={paymentCard.bold}
              italic={paymentCard.italic}
              underline={paymentCard.underline}
              onBoldClick={handlePaymentCard.boldClick}
              onItalicClick={handlePaymentCard.italicClick}
              onUnderlineClick={handlePaymentCard.underlineClick}
              textAlign={paymentCard.textAlign}
              onLeftClick={() => handlePaymentCard.textAlign('left')}
              onCenterClick={() => handlePaymentCard.textAlign('center')}
              onRightClick={() => handlePaymentCard.textAlign('right')}
              onJustifyClick={() => handlePaymentCard.textAlign('justify')}
              lineHeight={paymentCard.lineHeight}
              onLineHeightChange={handlePaymentCard.lineHeight}
              color={paymentCard.color}
              setColor={handlePaymentCard.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerPaymentCard} />,
        },
        {
          heading: 'Style',
          component: <PaymentCardStyle />,
        },
      ],
    },
  ];
};
