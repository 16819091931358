import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductDefaultImage } from '../../../assets/images';
import * as icons from '../../../assets/icons';

export default () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='mx-5 my-2 px-4 py-2 bg-white filter drop-shadow-lg rounded-lg'>
      <div className='mb-1 flex justify-between items-center'>
        <p>3 items</p>
        <FontAwesomeIcon
          icon={expanded ? icons.chevronUp : icons.chevronDown}
          onClick={() => setExpanded(!expanded)}
          className='cursor-pointer'
        />
      </div>
      <p className='text-sm'>15.00 $</p>
      <p className='text-sm my-1'>Order Status: Shipped</p>
      <p className='text-sm'>date: 15.10.2021</p>

      {expanded && (
        <div>
          <hr className='my-2 border' />
          {[1, 2].map((index) => (
            <div
              key={index}
              className='my-3 flex items-center bg-white filter drop-shadow-md rounded-md'
            >
              <img
                alt='item'
                src={ProductDefaultImage}
                className='w-20 h-20 rounded-tl-md rounded-bl-md'
              />
              <div className='ml-6'>
                <p className='text-sm'>Ttile</p>
                <p className='text-sm'>0.00 $</p>
                <p className='text-sm'>Quantity: 5</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
