import React, { useState } from 'react';

export default () => {
  const [selectedOption, setSelectedOption] = useState('custom');

  const onValueChange = (value) => {
    setSelectedOption(value);
  };

  const data = [
    { light: true, type: 'light' },
    { light: false, type: 'dark' },
    { type: 'custom' },
  ];

  return (
    <div className='grid grid-cols-2'>
      {data &&
        data.map((item, index) => (
          <label key={index} className='w-60 mb-4 flex flex-col items-center'>
            {item.type === 'custom' ? (
              <div className='my-3 text-sm'>CUSTOM</div>
            ) : (
              <div
                className={`w-60 h-8 mb-3 filter drop-shadow-lg ${
                  item.light ? 'bg-white' : 'bg-gray-500'
                } rounded-full flex justify-center items-center`}
              >
                <p
                  className={`text-sm ${
                    item.light ? 'text-gray-500' : 'text-white'
                  }`}
                >
                  Title
                </p>
              </div>
            )}

            <input
              type='radio'
              style={{ height: '18px', width: '18px' }}
              checked={selectedOption === item.type}
              onChange={() => onValueChange(item.type)}
            />
          </label>
        ))}
    </div>
  );
};
