const initialState = {
  session: {
    isLoading: true,
    isSignedIn: false,
  },
  apps: {
    androidSubmitted: [],
    androidDraft: [],
    iosSubmitted: [],
    iosDraft: [],
  },
  templates: [],
  selectedApp: {
    colors: {},
    templateId: null,
  },
  homeScreen: {},
  categories: {},
  category: {},
  loginScreen: {},
  signupScreen: {},
  addressScreen: {},
  newAddressScreen: {},
  paymentMethods: {},
  newPaymentScreen: {},
  singleItemScreen: {},
};

export default initialState;
