import React from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
import reactCSS from 'reactcss';

const CustomPointer = () => {
  const styles = reactCSS({
    default: {
      picker: {
        width: '10px',
        height: '10px',
        borderRadius: '6px',
        marginLeft: '3px',
        boxShadow: 'inset 0 0 0 2px #fff',
        transform: 'translate(-6px, -6px)',
      },
    },
  });

  return <div style={styles.picker} />;
};

const HuePointer = () => {
  const styles = reactCSS({
    default: {
      picker: {
        width: '8px',
        height: '8px',
        borderRadius: '6px',
        marginLeft: '6px',
        boxShadow: 'inset 0 0 0 2px #fff',
        transform: 'translate(-6px, -6px)',
      },
    },
  });

  return <div style={styles.picker} />;
};

const CustomColor = (props) => {
  return (
    <div className='flex'>
      <div className='w-32 h-32 relative'>
        <Saturation {...props} pointer={CustomPointer} />
      </div>
      <div className='w-2 h-32 relative mx-4'>
        <Hue {...props} pointer={HuePointer} direction='vertical' />
      </div>
    </div>
  );
};

const CustomColorPicker = CustomPicker(CustomColor);

export default ({ color, setColor }) => {
  return (
    <div>
      <CustomColorPicker color={color} onChangeComplete={setColor} />
    </div>
  );
};
