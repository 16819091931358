import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/newPayment.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
  AlignmentEditor,
  TitleStyle,
  InputStyle,
  ButtonStyle,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

const defaultStyle = {
  input: {
    alignment: 'center',
    width: 330,
    height: 32,
  },
  smallInput: {
    alignment: 'center',
    width: 90,
    height: 32,
  },
  button: {
    alignment: 'center',
    width: 100,
    height: 35,
  },
};

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const newPaymentScreen = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setNewPaymentScreenStyling(newPaymentScreen));
  };

  const title = {
    visibility: newPaymentScreen.title.visibility,
    selectedFont: newPaymentScreen.title.text.fontFamily,
    fontSize: newPaymentScreen.title.text.fontSize,
    bold: newPaymentScreen.title.text.bold,
    italic: newPaymentScreen.title.text.italic,
    underline: newPaymentScreen.title.text.underline,
    textAlign: newPaymentScreen.title.text.textAlign,
    lineHeight: newPaymentScreen.title.text.lineHeight,
    color: newPaymentScreen.title.text.color,
    borderVisibility: newPaymentScreen.title.design.borderVisibility,
    borderColor: newPaymentScreen.title.design.borderColor,
    fillVisibility: newPaymentScreen.title.design.fillVisibility,
    fillColor: newPaymentScreen.title.design.fillColor,
    shadow: newPaymentScreen.title.design.shadow,
    borderRadius: newPaymentScreen.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      newPaymentScreen.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      newPaymentScreen.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newPaymentScreen.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newPaymentScreen.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      newPaymentScreen.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      newPaymentScreen.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      newPaymentScreen.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newPaymentScreen.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newPaymentScreen.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newPaymentScreen.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newPaymentScreen.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newPaymentScreen.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newPaymentScreen.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newPaymentScreen.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newPaymentScreen.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const cardNumber = {
    selectedFont: newPaymentScreen.cardNumber.text.fontFamily,
    fontSize: newPaymentScreen.cardNumber.text.fontSize,
    bold: newPaymentScreen.cardNumber.text.bold,
    italic: newPaymentScreen.cardNumber.text.italic,
    underline: newPaymentScreen.cardNumber.text.underline,
    textAlign: newPaymentScreen.cardNumber.text.textAlign,
    lineHeight: newPaymentScreen.cardNumber.text.lineHeight,
    color: newPaymentScreen.cardNumber.text.color,
    borderVisibility: newPaymentScreen.cardNumber.design.entireCard.borderVisibility,
    borderColor: newPaymentScreen.cardNumber.design.entireCard.borderColor,
    fillVisibility: newPaymentScreen.cardNumber.design.entireCard.fillVisibility,
    fillColor: newPaymentScreen.cardNumber.design.entireCard.fillColor,
    shadow: newPaymentScreen.cardNumber.design.entireCard.shadow,
    borderRadius: newPaymentScreen.cardNumber.design.entireCard.borderRadius,
    alignment: newPaymentScreen.cardNumber.design.size.alignment,
    width: newPaymentScreen.cardNumber.design.size.width,
    height: newPaymentScreen.cardNumber.design.size.height,
  };

  const handleCardNumber = {
    fontChange: (font) => {
      newPaymentScreen.cardNumber.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newPaymentScreen.cardNumber.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newPaymentScreen.cardNumber.text.bold = !cardNumber.bold;
      setStyling();
    },
    italicClick: () => {
      newPaymentScreen.cardNumber.text.italic = !cardNumber.italic;
      setStyling();
    },
    underlineClick: () => {
      newPaymentScreen.cardNumber.text.underline = !cardNumber.underline;
      setStyling();
    },
    textAlign: (value) => {
      newPaymentScreen.cardNumber.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newPaymentScreen.cardNumber.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newPaymentScreen.cardNumber.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newPaymentScreen.cardNumber.design.entireCard.borderVisibility = !cardNumber.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newPaymentScreen.cardNumber.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newPaymentScreen.cardNumber.design.entireCard.fillVisibility = !cardNumber.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newPaymentScreen.cardNumber.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newPaymentScreen.cardNumber.design.entireCard.shadow = !cardNumber.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newPaymentScreen.cardNumber.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newPaymentScreen.cardNumber.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newPaymentScreen.cardNumber.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newPaymentScreen.cardNumber.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newPaymentScreen.cardNumber.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const nameOnCard = {
    selectedFont: newPaymentScreen.nameOnCard.text.fontFamily,
    fontSize: newPaymentScreen.nameOnCard.text.fontSize,
    bold: newPaymentScreen.nameOnCard.text.bold,
    italic: newPaymentScreen.nameOnCard.text.italic,
    underline: newPaymentScreen.nameOnCard.text.underline,
    textAlign: newPaymentScreen.nameOnCard.text.textAlign,
    lineHeight: newPaymentScreen.nameOnCard.text.lineHeight,
    color: newPaymentScreen.nameOnCard.text.color,
    borderVisibility: newPaymentScreen.nameOnCard.design.entireCard.borderVisibility,
    borderColor: newPaymentScreen.nameOnCard.design.entireCard.borderColor,
    fillVisibility: newPaymentScreen.nameOnCard.design.entireCard.fillVisibility,
    fillColor: newPaymentScreen.nameOnCard.design.entireCard.fillColor,
    shadow: newPaymentScreen.nameOnCard.design.entireCard.shadow,
    borderRadius: newPaymentScreen.nameOnCard.design.entireCard.borderRadius,
    alignment: newPaymentScreen.nameOnCard.design.size.alignment,
    width: newPaymentScreen.nameOnCard.design.size.width,
    height: newPaymentScreen.nameOnCard.design.size.height,
  };

  const handleNameOnCard = {
    fontChange: (font) => {
      newPaymentScreen.nameOnCard.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newPaymentScreen.nameOnCard.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newPaymentScreen.nameOnCard.text.bold = !nameOnCard.bold;
      setStyling();
    },
    italicClick: () => {
      newPaymentScreen.nameOnCard.text.italic = !nameOnCard.italic;
      setStyling();
    },
    underlineClick: () => {
      newPaymentScreen.nameOnCard.text.underline = !nameOnCard.underline;
      setStyling();
    },
    textAlign: (value) => {
      newPaymentScreen.nameOnCard.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newPaymentScreen.nameOnCard.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newPaymentScreen.nameOnCard.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newPaymentScreen.nameOnCard.design.entireCard.borderVisibility = !nameOnCard.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newPaymentScreen.nameOnCard.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newPaymentScreen.nameOnCard.design.entireCard.fillVisibility = !nameOnCard.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newPaymentScreen.nameOnCard.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newPaymentScreen.nameOnCard.design.entireCard.shadow = !nameOnCard.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newPaymentScreen.nameOnCard.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newPaymentScreen.nameOnCard.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newPaymentScreen.nameOnCard.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newPaymentScreen.nameOnCard.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newPaymentScreen.nameOnCard.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const cvcInput = {
    selectedFont: newPaymentScreen.cvcInput.text.fontFamily,
    fontSize: newPaymentScreen.cvcInput.text.fontSize,
    bold: newPaymentScreen.cvcInput.text.bold,
    italic: newPaymentScreen.cvcInput.text.italic,
    underline: newPaymentScreen.cvcInput.text.underline,
    textAlign: newPaymentScreen.cvcInput.text.textAlign,
    lineHeight: newPaymentScreen.cvcInput.text.lineHeight,
    color: newPaymentScreen.cvcInput.text.color,
    borderVisibility: newPaymentScreen.cvcInput.design.entireCard.borderVisibility,
    borderColor: newPaymentScreen.cvcInput.design.entireCard.borderColor,
    fillVisibility: newPaymentScreen.cvcInput.design.entireCard.fillVisibility,
    fillColor: newPaymentScreen.cvcInput.design.entireCard.fillColor,
    shadow: newPaymentScreen.cvcInput.design.entireCard.shadow,
    borderRadius: newPaymentScreen.cvcInput.design.entireCard.borderRadius,
    alignment: newPaymentScreen.cvcInput.design.size.alignment,
    width: newPaymentScreen.cvcInput.design.size.width,
    height: newPaymentScreen.cvcInput.design.size.height,
  };

  const handleCvcInput = {
    fontChange: (font) => {
      newPaymentScreen.cvcInput.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newPaymentScreen.cvcInput.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newPaymentScreen.cvcInput.text.bold = !cvcInput.bold;
      setStyling();
    },
    italicClick: () => {
      newPaymentScreen.cvcInput.text.italic = !cvcInput.italic;
      setStyling();
    },
    underlineClick: () => {
      newPaymentScreen.cvcInput.text.underline = !cvcInput.underline;
      setStyling();
    },
    textAlign: (value) => {
      newPaymentScreen.cvcInput.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newPaymentScreen.cvcInput.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newPaymentScreen.cvcInput.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newPaymentScreen.cvcInput.design.entireCard.borderVisibility = !cvcInput.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newPaymentScreen.cvcInput.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newPaymentScreen.cvcInput.design.entireCard.fillVisibility = !cvcInput.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newPaymentScreen.cvcInput.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newPaymentScreen.cvcInput.design.entireCard.shadow = !cvcInput.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newPaymentScreen.cvcInput.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newPaymentScreen.cvcInput.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newPaymentScreen.cvcInput.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newPaymentScreen.cvcInput.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newPaymentScreen.cvcInput.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const expirationDate = {
    selectedFont: newPaymentScreen.expirationDate.text.fontFamily,
    fontSize: newPaymentScreen.expirationDate.text.fontSize,
    bold: newPaymentScreen.expirationDate.text.bold,
    italic: newPaymentScreen.expirationDate.text.italic,
    underline: newPaymentScreen.expirationDate.text.underline,
    textAlign: newPaymentScreen.expirationDate.text.textAlign,
    lineHeight: newPaymentScreen.expirationDate.text.lineHeight,
    color: newPaymentScreen.expirationDate.text.color,
    borderVisibility: newPaymentScreen.expirationDate.design.entireCard.borderVisibility,
    borderColor: newPaymentScreen.expirationDate.design.entireCard.borderColor,
    fillVisibility: newPaymentScreen.expirationDate.design.entireCard.fillVisibility,
    fillColor: newPaymentScreen.expirationDate.design.entireCard.fillColor,
    shadow: newPaymentScreen.expirationDate.design.entireCard.shadow,
    borderRadius: newPaymentScreen.expirationDate.design.entireCard.borderRadius,
    alignment: newPaymentScreen.expirationDate.design.size.alignment,
    width: newPaymentScreen.expirationDate.design.size.width,
    height: newPaymentScreen.expirationDate.design.size.height,
  };

  const handleExpirationDate = {
    fontChange: (font) => {
      newPaymentScreen.expirationDate.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newPaymentScreen.expirationDate.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newPaymentScreen.expirationDate.text.bold = !expirationDate.bold;
      setStyling();
    },
    italicClick: () => {
      newPaymentScreen.expirationDate.text.italic = !expirationDate.italic;
      setStyling();
    },
    underlineClick: () => {
      newPaymentScreen.expirationDate.text.underline = !expirationDate.underline;
      setStyling();
    },
    textAlign: (value) => {
      newPaymentScreen.expirationDate.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newPaymentScreen.expirationDate.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newPaymentScreen.expirationDate.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newPaymentScreen.expirationDate.design.entireCard.borderVisibility = !expirationDate.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newPaymentScreen.expirationDate.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newPaymentScreen.expirationDate.design.entireCard.fillVisibility = !expirationDate.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newPaymentScreen.expirationDate.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newPaymentScreen.expirationDate.design.entireCard.shadow = !expirationDate.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newPaymentScreen.expirationDate.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newPaymentScreen.expirationDate.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newPaymentScreen.expirationDate.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newPaymentScreen.expirationDate.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newPaymentScreen.expirationDate.design.size = defaultStyle.smallInput;
      setStyling();
    },
  };

  const button = {
    selectedFont: newPaymentScreen.button.text.fontFamily,
    fontSize: newPaymentScreen.button.text.fontSize,
    bold: newPaymentScreen.button.text.bold,
    italic: newPaymentScreen.button.text.italic,
    underline: newPaymentScreen.button.text.underline,
    textAlign: newPaymentScreen.button.text.textAlign,
    lineHeight: newPaymentScreen.button.text.lineHeight,
    color: newPaymentScreen.button.text.color,
    borderVisibility: newPaymentScreen.button.design.entireCard.borderVisibility,
    borderColor: newPaymentScreen.button.design.entireCard.borderColor,
    fillVisibility: newPaymentScreen.button.design.entireCard.fillVisibility,
    fillColor: newPaymentScreen.button.design.entireCard.fillColor,
    shadow: newPaymentScreen.button.design.entireCard.shadow,
    borderRadius: newPaymentScreen.button.design.entireCard.borderRadius,
    alignment: newPaymentScreen.button.design.size.alignment,
    width: newPaymentScreen.button.design.size.width,
    height: newPaymentScreen.button.design.size.height,
  };

  const handleButton = {
    fontChange: (font) => {
      newPaymentScreen.button.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newPaymentScreen.button.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newPaymentScreen.button.text.bold = !button.bold;
      setStyling();
    },
    italicClick: () => {
      newPaymentScreen.button.text.italic = !button.italic;
      setStyling();
    },
    underlineClick: () => {
      newPaymentScreen.button.text.underline = !button.underline;
      setStyling();
    },
    textAlign: (value) => {
      newPaymentScreen.button.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newPaymentScreen.button.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newPaymentScreen.button.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newPaymentScreen.button.design.entireCard.borderVisibility = !button.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newPaymentScreen.button.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newPaymentScreen.button.design.entireCard.fillVisibility = !button.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newPaymentScreen.button.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newPaymentScreen.button.design.entireCard.shadow = !button.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newPaymentScreen.button.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newPaymentScreen.button.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newPaymentScreen.button.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newPaymentScreen.button.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newPaymentScreen.button.design.size = defaultStyle.button;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerCardNumber = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={cardNumber.borderVisibility}
          setBorderVisibility={handleCardNumber.borderVisibility}
          borderColor={cardNumber.borderColor}
          setBorderColor={handleCardNumber.borderColor}
          fillVisibility={cardNumber.fillVisibility}
          setFillVisibility={handleCardNumber.fillVisibility}
          fillColor={cardNumber.fillColor}
          setFillColor={handleCardNumber.fillColor}
          shadowVisibilty={cardNumber.shadow}
          setShadowVisibility={handleCardNumber.shadow}
          borderRadius={cardNumber.borderRadius}
          setBorderRadius={handleCardNumber.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={cardNumber.alignment}
          onRightClick={() => handleCardNumber.elementAlign('end')}
          onCenterClick={() => handleCardNumber.elementAlign('center')}
          onLeftClick={() => handleCardNumber.elementAlign('start')}
          width={cardNumber.width}
          setElementWidth={handleCardNumber.width}
          height={cardNumber.height}
          setElementHeight={handleCardNumber.height}
          onResetPress={handleCardNumber.resetSize}
        />
      ),
    },
  ];

  const designChangerNameOnCard = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={nameOnCard.borderVisibility}
          setBorderVisibility={handleNameOnCard.borderVisibility}
          borderColor={nameOnCard.borderColor}
          setBorderColor={handleNameOnCard.borderColor}
          fillVisibility={nameOnCard.fillVisibility}
          setFillVisibility={handleNameOnCard.fillVisibility}
          fillColor={nameOnCard.fillColor}
          setFillColor={handleNameOnCard.fillColor}
          shadowVisibilty={nameOnCard.shadow}
          setShadowVisibility={handleNameOnCard.shadow}
          borderRadius={nameOnCard.borderRadius}
          setBorderRadius={handleNameOnCard.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={nameOnCard.alignment}
          onRightClick={() => handleNameOnCard.elementAlign('end')}
          onCenterClick={() => handleNameOnCard.elementAlign('center')}
          onLeftClick={() => handleNameOnCard.elementAlign('start')}
          width={nameOnCard.width}
          setElementWidth={handleNameOnCard.width}
          height={nameOnCard.height}
          setElementHeight={handleNameOnCard.height}
          onResetPress={handleNameOnCard.resetSize}
        />
      ),
    },
  ];

  const designChangerCvcInput = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={cvcInput.borderVisibility}
          setBorderVisibility={handleCvcInput.borderVisibility}
          borderColor={cvcInput.borderColor}
          setBorderColor={handleCvcInput.borderColor}
          fillVisibility={cvcInput.fillVisibility}
          setFillVisibility={handleCvcInput.fillVisibility}
          fillColor={cvcInput.fillColor}
          setFillColor={handleCvcInput.fillColor}
          shadowVisibilty={cvcInput.shadow}
          setShadowVisibility={handleCvcInput.shadow}
          borderRadius={cvcInput.borderRadius}
          setBorderRadius={handleCvcInput.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={cvcInput.alignment}
          onRightClick={() => handleCvcInput.elementAlign('end')}
          onCenterClick={() => handleCvcInput.elementAlign('center')}
          onLeftClick={() => handleCvcInput.elementAlign('start')}
          width={cvcInput.width}
          setElementWidth={handleCvcInput.width}
          height={cvcInput.height}
          setElementHeight={handleCvcInput.height}
          onResetPress={handleCvcInput.resetSize}
        />
      ),
    },
  ];

  const designChangerExpirationDate = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={expirationDate.borderVisibility}
          setBorderVisibility={handleExpirationDate.borderVisibility}
          borderColor={expirationDate.borderColor}
          setBorderColor={handleExpirationDate.borderColor}
          fillVisibility={expirationDate.fillVisibility}
          setFillVisibility={handleExpirationDate.fillVisibility}
          fillColor={expirationDate.fillColor}
          setFillColor={handleExpirationDate.fillColor}
          shadowVisibilty={expirationDate.shadow}
          setShadowVisibility={handleExpirationDate.shadow}
          borderRadius={expirationDate.borderRadius}
          setBorderRadius={handleExpirationDate.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={expirationDate.alignment}
          onRightClick={() => handleExpirationDate.elementAlign('end')}
          onCenterClick={() => handleExpirationDate.elementAlign('center')}
          onLeftClick={() => handleExpirationDate.elementAlign('start')}
          width={expirationDate.width}
          setElementWidth={handleExpirationDate.width}
          height={expirationDate.height}
          setElementHeight={handleExpirationDate.height}
          onResetPress={handleExpirationDate.resetSize}
        />
      ),
    },
  ];

  const designChangerButton = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={button.borderVisibility}
          setBorderVisibility={handleButton.borderVisibility}
          borderColor={button.borderColor}
          setBorderColor={handleButton.borderColor}
          fillVisibility={button.fillVisibility}
          setFillVisibility={handleButton.fillVisibility}
          fillColor={button.fillColor}
          setFillColor={handleButton.fillColor}
          shadowVisibilty={button.shadow}
          setShadowVisibility={handleButton.shadow}
          borderRadius={button.borderRadius}
          setBorderRadius={handleButton.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={button.alignment}
          onRightClick={() => handleButton.elementAlign('end')}
          onCenterClick={() => handleButton.elementAlign('center')}
          onLeftClick={() => handleButton.elementAlign('start')}
          width={button.width}
          setElementWidth={handleButton.width}
          height={button.height}
          setElementHeight={handleButton.height}
          onResetPress={handleButton.resetSize}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Input - Card Number',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={cardNumber.selectedFont}
              onFontChange={handleCardNumber.fontChange}
              fontSize={cardNumber.fontSize}
              onFontSizeChange={handleCardNumber.fontSize}
              bold={cardNumber.bold}
              italic={cardNumber.italic}
              underline={cardNumber.underline}
              onBoldClick={handleCardNumber.boldClick}
              onItalicClick={handleCardNumber.italicClick}
              onUnderlineClick={handleCardNumber.underlineClick}
              textAlign={cardNumber.textAlign}
              onLeftClick={() => handleCardNumber.textAlign('left')}
              onCenterClick={() => handleCardNumber.textAlign('center')}
              onRightClick={() => handleCardNumber.textAlign('right')}
              onJustifyClick={() => handleCardNumber.textAlign('justify')}
              lineHeight={cardNumber.lineHeight}
              onLineHeightChange={handleCardNumber.lineHeight}
              color={cardNumber.color}
              setColor={handleCardNumber.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerCardNumber} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Name on Card',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={nameOnCard.selectedFont}
              onFontChange={handleNameOnCard.fontChange}
              fontSize={nameOnCard.fontSize}
              onFontSizeChange={handleNameOnCard.fontSize}
              bold={nameOnCard.bold}
              italic={nameOnCard.italic}
              underline={nameOnCard.underline}
              onBoldClick={handleNameOnCard.boldClick}
              onItalicClick={handleNameOnCard.italicClick}
              onUnderlineClick={handleNameOnCard.underlineClick}
              textAlign={nameOnCard.textAlign}
              onLeftClick={() => handleNameOnCard.textAlign('left')}
              onCenterClick={() => handleNameOnCard.textAlign('center')}
              onRightClick={() => handleNameOnCard.textAlign('right')}
              onJustifyClick={() => handleNameOnCard.textAlign('justify')}
              lineHeight={nameOnCard.lineHeight}
              onLineHeightChange={handleNameOnCard.lineHeight}
              color={nameOnCard.color}
              setColor={handleNameOnCard.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerNameOnCard} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - CVC / CVV',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={cvcInput.selectedFont}
              onFontChange={handleCvcInput.fontChange}
              fontSize={cvcInput.fontSize}
              onFontSizeChange={handleCvcInput.fontSize}
              bold={cvcInput.bold}
              italic={cvcInput.italic}
              underline={cvcInput.underline}
              onBoldClick={handleCvcInput.boldClick}
              onItalicClick={handleCvcInput.italicClick}
              onUnderlineClick={handleCvcInput.underlineClick}
              textAlign={cvcInput.textAlign}
              onLeftClick={() => handleCvcInput.textAlign('left')}
              onCenterClick={() => handleCvcInput.textAlign('center')}
              onRightClick={() => handleCvcInput.textAlign('right')}
              onJustifyClick={() => handleCvcInput.textAlign('justify')}
              lineHeight={cvcInput.lineHeight}
              onLineHeightChange={handleCvcInput.lineHeight}
              color={cvcInput.color}
              setColor={handleCvcInput.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerCvcInput} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Expiration Date',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={expirationDate.selectedFont}
              onFontChange={handleExpirationDate.fontChange}
              fontSize={expirationDate.fontSize}
              onFontSizeChange={handleExpirationDate.fontSize}
              bold={expirationDate.bold}
              italic={expirationDate.italic}
              underline={expirationDate.underline}
              onBoldClick={handleExpirationDate.boldClick}
              onItalicClick={handleExpirationDate.italicClick}
              onUnderlineClick={handleExpirationDate.underlineClick}
              textAlign={expirationDate.textAlign}
              onLeftClick={() => handleExpirationDate.textAlign('left')}
              onCenterClick={() => handleExpirationDate.textAlign('center')}
              onRightClick={() => handleExpirationDate.textAlign('right')}
              onJustifyClick={() => handleExpirationDate.textAlign('justify')}
              lineHeight={expirationDate.lineHeight}
              onLineHeightChange={handleExpirationDate.lineHeight}
              color={expirationDate.color}
              setColor={handleExpirationDate.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerExpirationDate} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Button - Save',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={button.selectedFont}
              onFontChange={handleButton.fontChange}
              fontSize={button.fontSize}
              onFontSizeChange={handleButton.fontSize}
              bold={button.bold}
              italic={button.italic}
              underline={button.underline}
              onBoldClick={handleButton.boldClick}
              onItalicClick={handleButton.italicClick}
              onUnderlineClick={handleButton.underlineClick}
              textAlign={button.textAlign}
              onLeftClick={() => handleButton.textAlign('left')}
              onCenterClick={() => handleButton.textAlign('center')}
              onRightClick={() => handleButton.textAlign('right')}
              onJustifyClick={() => handleButton.textAlign('justify')}
              lineHeight={button.lineHeight}
              onLineHeightChange={handleButton.lineHeight}
              color={button.color}
              setColor={handleButton.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerButton} />,
        },
        {
          heading: 'Style',
          component: <ButtonStyle />,
        },
      ],
    },
  ];
};
