import React from 'react';
import { ColorPicker, RangeSelector } from '../../components/common';

export default ({ color, setColor, setOpacity }) => {
  return (
    <div>
      <div className='flex items-center mt-5'>
        <p className='text-sm mr-10'>Opacity</p>
        <RangeSelector value={color.rgb.a} setValue={setOpacity} />
        <p className='text-sm ml-10'>{Math.trunc(color.rgb.a * 100)} %</p>
      </div>

      <p className='text-sm mt-2 mb-1'>Fill</p>
      <ColorPicker color={color.rgb} setColor={setColor} />
    </div>
  );
};
