import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/singleItem.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const singleItemScreen = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setSingleItemScreenStyling(singleItemScreen));
  };

  const item = {
    description: {
      selectedFont: singleItemScreen.item.description.fontFamily,
      fontSize: singleItemScreen.item.description.fontSize,
      bold: singleItemScreen.item.description.bold,
      italic: singleItemScreen.item.description.italic,
      underline: singleItemScreen.item.description.underline,
      textAlign: singleItemScreen.item.description.textAlign,
      lineHeight: singleItemScreen.item.description.lineHeight,
      color: singleItemScreen.item.description.color,
    },
    itemName: {
      selectedFont: singleItemScreen.item.itemName.fontFamily,
      fontSize: singleItemScreen.item.itemName.fontSize,
      bold: singleItemScreen.item.itemName.bold,
      italic: singleItemScreen.item.itemName.italic,
      underline: singleItemScreen.item.itemName.underline,
      textAlign: singleItemScreen.item.itemName.textAlign,
      lineHeight: singleItemScreen.item.itemName.lineHeight,
      color: singleItemScreen.item.itemName.color,
    },
    price: {
      selectedFont: singleItemScreen.item.price.fontFamily,
      fontSize: singleItemScreen.item.price.fontSize,
      bold: singleItemScreen.item.price.bold,
      italic: singleItemScreen.item.price.italic,
      underline: singleItemScreen.item.price.underline,
      textAlign: singleItemScreen.item.price.textAlign,
      lineHeight: singleItemScreen.item.price.lineHeight,
      color: singleItemScreen.item.price.color,
    },
    borderVisibility: singleItemScreen.item.design.borderVisibility,
    borderColor: singleItemScreen.item.design.borderColor,
    fillVisibility: singleItemScreen.item.design.fillVisibility,
    fillColor: singleItemScreen.item.design.fillColor,
    shadow: singleItemScreen.item.design.shadow,
    borderRadius: singleItemScreen.item.design.borderRadius,
  };

  const handleItem = {
    description: {
      fontChange: (font) => {
        singleItemScreen.item.description.fontFamily = font;
        setStyling();
      },
      fontSize: (e) => {
        singleItemScreen.item.description.fontSize = Number(e.target.value);
        setStyling();
      },
      boldClick: () => {
        singleItemScreen.item.description.bold = !item.description.bold;
        setStyling();
      },
      italicClick: () => {
        singleItemScreen.item.description.italic = !item.description.italic;
        setStyling();
      },
      underlineClick: () => {
        singleItemScreen.item.description.underline = !item.description.underline;
        setStyling();
      },
      textAlign: (value) => {
        singleItemScreen.item.description.textAlign = value;
        setStyling();
      },
      lineHeight: (e) => {
        singleItemScreen.item.description.lineHeight = e.target.value;
        setStyling();
      },
      colorChange: (value) => {
        singleItemScreen.item.description.color = value.hex;
        setStyling();
      },
    },
    itemName: {
      fontChange: (font) => {
        singleItemScreen.item.itemName.fontFamily = font;
        setStyling();
      },
      fontSize: (e) => {
        singleItemScreen.item.itemName.fontSize = Number(e.target.value);
        setStyling();
      },
      boldClick: () => {
        singleItemScreen.item.itemName.bold = !item.itemName.bold;
        setStyling();
      },
      italicClick: () => {
        singleItemScreen.item.itemName.italic = !item.itemName.italic;
        setStyling();
      },
      underlineClick: () => {
        singleItemScreen.item.itemName.underline = !item.itemName.underline;
        setStyling();
      },
      textAlign: (value) => {
        singleItemScreen.item.itemName.textAlign = value;
        setStyling();
      },
      lineHeight: (e) => {
        singleItemScreen.item.itemName.lineHeight = e.target.value;
        setStyling();
      },
      colorChange: (value) => {
        singleItemScreen.item.itemName.color = value.hex;
        setStyling();
      },
    },
    price: {
      fontChange: (font) => {
        singleItemScreen.item.price.fontFamily = font;
        setStyling();
      },
      fontSize: (e) => {
        singleItemScreen.item.price.fontSize = Number(e.target.value);
        setStyling();
      },
      boldClick: () => {
        singleItemScreen.item.price.bold = !item.price.bold;
        setStyling();
      },
      italicClick: () => {
        singleItemScreen.item.price.italic = !item.price.italic;
        setStyling();
      },
      underlineClick: () => {
        singleItemScreen.item.price.underline = !item.price.underline;
        setStyling();
      },
      textAlign: (value) => {
        singleItemScreen.item.price.textAlign = value;
        setStyling();
      },
      lineHeight: (e) => {
        singleItemScreen.item.price.lineHeight = e.target.value;
        setStyling();
      },
      colorChange: (value) => {
        singleItemScreen.item.price.color = value.hex;
        setStyling();
      },
    },
    borderVisibility: () => {
      singleItemScreen.item.design.borderVisibility = !item.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      singleItemScreen.item.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      singleItemScreen.item.design.fillVisibility = !item.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      singleItemScreen.item.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      singleItemScreen.item.design.shadow = !item.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      singleItemScreen.item.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const designChangerItem = [
    {
      component: (
        <DesignEditor
          borderVisibility={item.borderVisibility}
          setBorderVisibility={handleItem.borderVisibility}
          borderColor={item.borderColor}
          setBorderColor={handleItem.borderColor}
          fillVisibility={item.fillVisibility}
          setFillVisibility={handleItem.fillVisibility}
          fillColor={item.fillColor}
          setFillColor={handleItem.fillColor}
          shadowVisibilty={item.shadow}
          setShadowVisibility={handleItem.shadow}
          borderRadius={item.borderRadius}
          setBorderRadius={handleItem.borderRadius}
        />
      ),
    },
  ];

  return [
    {
      title: 'Item',
      expanded: [
        {
          heading: 'Description',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={item.description.selectedFont}
              onFontChange={handleItem.description.fontChange}
              fontSize={item.description.fontSize}
              onFontSizeChange={handleItem.description.fontSize}
              bold={item.description.bold}
              italic={item.description.italic}
              underline={item.description.underline}
              onBoldClick={handleItem.description.boldClick}
              onItalicClick={handleItem.description.italicClick}
              onUnderlineClick={handleItem.description.underlineClick}
              textAlign={item.description.textAlign}
              onLeftClick={() => handleItem.description.textAlign('left')}
              onCenterClick={() => handleItem.description.textAlign('center')}
              onRightClick={() => handleItem.description.textAlign('right')}
              onJustifyClick={() => handleItem.description.textAlign('justify')}
              lineHeight={item.description.lineHeight}
              onLineHeightChange={handleItem.description.lineHeight}
              color={item.description.color}
              setColor={handleItem.description.colorChange}
            />
          ),
        },
        {
          heading: 'Item Name',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={item.itemName.selectedFont}
              onFontChange={handleItem.itemName.fontChange}
              fontSize={item.itemName.fontSize}
              onFontSizeChange={handleItem.itemName.fontSize}
              bold={item.itemName.bold}
              italic={item.itemName.italic}
              underline={item.itemName.underline}
              onBoldClick={handleItem.itemName.boldClick}
              onItalicClick={handleItem.itemName.italicClick}
              onUnderlineClick={handleItem.itemName.underlineClick}
              textAlign={item.itemName.textAlign}
              onLeftClick={() => handleItem.itemName.textAlign('left')}
              onCenterClick={() => handleItem.itemName.textAlign('center')}
              onRightClick={() => handleItem.itemName.textAlign('right')}
              onJustifyClick={() => handleItem.itemName.textAlign('justify')}
              lineHeight={item.itemName.lineHeight}
              onLineHeightChange={handleItem.itemName.lineHeight}
              color={item.itemName.color}
              setColor={handleItem.itemName.colorChange}
            />
          ),
        },
        {
          heading: 'Price',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={item.price.selectedFont}
              onFontChange={handleItem.price.fontChange}
              fontSize={item.price.fontSize}
              onFontSizeChange={handleItem.price.fontSize}
              bold={item.price.bold}
              italic={item.price.italic}
              underline={item.price.underline}
              onBoldClick={handleItem.price.boldClick}
              onItalicClick={handleItem.price.italicClick}
              onUnderlineClick={handleItem.price.underlineClick}
              textAlign={item.price.textAlign}
              onLeftClick={() => handleItem.price.textAlign('left')}
              onCenterClick={() => handleItem.price.textAlign('center')}
              onRightClick={() => handleItem.price.textAlign('right')}
              onJustifyClick={() => handleItem.price.textAlign('justify')}
              lineHeight={item.price.lineHeight}
              onLineHeightChange={handleItem.price.lineHeight}
              color={item.price.color}
              setColor={handleItem.price.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerItem} />,
        },
      ],
    },
  ];
};
