import React from 'react';
import { ImageUploader } from '../../components/common';

export default ({
  editable,
  imagesSrc,
  setScreenshotsURL,
  setScreenshotsSrc,
}) => {
  const imagesArray = [0, 1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      <p className='text-md'>Screen Shots</p>
      <p className='text-xs my-2'>
        JPEG or 24-bit PNG (no alpha). Min length for any side: 320px. Max
        length for any side: 3840px.
        <br />
        At least 2 screenshots are required overall. Max 8 screenshots per type.
        Drag to reorder or to move between types.
      </p>
      <div className='my-5 flex items-center flex-wrap'>
        {imagesArray.map((index) => (
          <div key={index}>
            <ImageUploader
              src={imagesSrc[index]}
              alt='Screen Shots'
              id={`image-id-${index}`}
              editable={editable}
              minWidth={320}
              maxWidth={3840}
              minHeight={320}
              maxHeight={3840}
              setSrc={(src) => setScreenshotsSrc(src, `image-id-${index}`)}
              setURL={(url) => setScreenshotsURL(url, `image-id-${index}`)}
            />
          </div>
        ))}
      </div>
    </>
  );
};
