export const setHeaders = (headers) => {
  localStorage.setItem('token', headers['access-token']);
  localStorage.setItem('client', headers.client);
  localStorage.setItem('uid', headers.uid);
};

export const getHeaders = () => {
  return {
    client: localStorage.getItem('client'),
    token: localStorage.getItem('token'),
    uid: localStorage.getItem('uid'),
  };
};

export const setUserId = (customerId) => {
  localStorage.setItem('customerId', customerId);
};

export const getUserId = () => {
  return localStorage.getItem('customerId');
};
