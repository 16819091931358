import React from 'react';

const Button = ({ text, selected, onClick }) => {
  return (
    <button
      className={`w-24 h-6 mr-2 flex justify-center items-center ${
        selected && 'bg-blue-500'
      } border border-blue-500 rounded-full`}
      onClick={onClick}
    >
      <p className={`text-sm ${selected ? 'text-white' : 'text-blue-500'}`}>
        {text}
      </p>
    </button>
  );
};

const White = ({ text, onClick }) => (
  <button
    onClick={onClick}
    className='w-40 h-8 flex justify-center items-center bg-white rounded filter drop-shadow-lg'
  >
    <p className='text-sm text-blue-500'>{text}</p>
  </button>
);

const Transparent = ({ text, icon, onClick }) => (
  <button onClick={onClick} className='transparent-button'>
    {text}
    {icon}
  </button>
);

Button.White = White;
Button.Transparent = Transparent;

export default Button;
