import * as types from '../../constants/actions.js';

export const setCategoryStyling = (payload) => ({
  type: types.SET_CATEGORY_STYLING,
  payload: payload,
});

export const setCategoryOrder = (payload) => ({
  type: types.SET_CATEGORY_ORDER,
  payload: payload,
});
