import React from 'react';
import { SpringSaleImage } from '../../../assets/images';
import { selectedColor } from '../../../helpers/colors.helper';

export default ({ styleData }) => {
  const backgroundImage = {
    borderColor: styleData.design.entireCard.borderColor,
    borderRadius: styleData.design.entireCard.borderRadius,
    backgroundImage: `url(${SpringSaleImage})`,
    backgroundPosition: 'center',
  };

  const backgroundStyling = {
    width: styleData.design.size.width,
    height: styleData.design.size.height,
    borderColor: styleData.design.entireCard.borderColor,
    borderRadius: styleData.design.entireCard.borderRadius,
    backgroundColor: styleData.design.entireCard.fillVisibility
      ? selectedColor(styleData.design.entireCard.fillColor)
      : 'transparent',
  };

  return (
    <div className={`flex justify-${styleData.design.size.alignment}`}>
      <div className='mt-5 flex justify-center items-center'>
        <div
          style={backgroundImage}
          className={`flex items-center ${
            styleData.design.entireCard.borderVisibility && 'border-2'
          } ${styleData.design.entireCard.shadow && 'filter drop-shadow-lg'}`}
        >
          <div
            style={backgroundStyling}
            className='flex justify-center items-center'
          >
            <p className='text-white text-2xl'>SPRING SALE</p>
          </div>
        </div>
      </div>
    </div>
  );
};
