import React from 'react';
import { ItemCardHome } from '../../components';

export default ({ styleData }) => {
  return (
    <div className={`flex justify-${styleData.design.size.alignment}`}>
      <div
        className={`mt-5 grid grid-cols-${styleData.category.itemsPerRow}`}
        style={{ width: '90%' }}
      >
        {[1, 2, 3, 4].map((index) => {
          return <ItemCardHome key={index} styleData={styleData} />;
        })}
      </div>
    </div>
  );
};
