import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactJsonViewCompare from 'react-json-view-compare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { setAppColors } from '../../actions/selectedApp.actions';
import { handleStylingActions } from '../../actions/mobileComponents.actions';
import { fetchAppPreview } from '../../api/apps.api';
import {
  HomeScreenPreview,
  CategoriesPreview,
  CategoryPreview,
  LoginScreenPreview,
  SignupScreenPreview,
  AddressScreenPreview,
  NewAddressScreenPreview,
  PaymentMethodsPreview,
  NewPaymentScreenPreview,
  SingleItemScreenPreview,
  FavouritesScreenPreview,
  OrdersScreenPreview,
  ProfileScreenPreview,
  ShoppingCartScreenPreview,
  ShippingScreenPreview,
  PaymentScreenPreview,
  ThankYouScreenPreview,
} from '../../AppPreview/screens';
import { PreviewPanel } from '../../AppPreview/components';
import * as icons from '../../assets/icons';

export default () => {
  const dispatch = useDispatch();
  const { appId, key } = useParams();
  const colors = useSelector((state) => state.selectedApp.colors);
  const [selectedScreen, setSelectedScreen] = useState('Home Screen');
  const [defaultSettings, setDefaultSettings] = useState({});
  const [modifiedSettings, setModifiedSettings] = useState([]);

  useEffect(() => {
    fetchAppPreview(appId, key)
      .then((res) => {
        setDefaultSettings(res.data.template.settings);
        setModifiedSettings(res.data.mobile_components);
        dispatch(setAppColors(res.data.app.configuration.colors));
      })
      .catch(() => toast.error('unable to fetch app preview'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    screenData[selectedScreen].styling?.modifiable !== false &&
      !_.isEmpty(modifiedSettings) &&
      handleStylingActions[screenData[selectedScreen].key](
        dispatch,
        modifiedSettings.find(
          (screen) => screen.name === screenData[selectedScreen].key
        )?.settings
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreen, modifiedSettings]);

  const screenData = {
    'Home Screen': {
      key: 'home_screen',
      styling: useSelector((state) => state.homeScreen),
      preview: <HomeScreenPreview />,
    },
    Categories: {
      key: 'categories',
      styling: useSelector((state) => state.categories),
      preview: <CategoriesPreview />,
    },
    Category: {
      key: 'category',
      styling: useSelector((state) => state.category),
      preview: <CategoryPreview />,
    },
    'Log In': {
      key: 'log_in',
      styling: useSelector((state) => state.loginScreen),
      preview: <LoginScreenPreview />,
    },
    'Sign Up': {
      key: 'sign_up',
      styling: useSelector((state) => state.signupScreen),
      preview: <SignupScreenPreview />,
    },
    Address: {
      key: 'saved_addresses',
      styling: useSelector((state) => state.addressScreen),
      preview: <AddressScreenPreview />,
    },
    'New Address': {
      key: 'new_address',
      styling: useSelector((state) => state.newAddressScreen),
      preview: <NewAddressScreenPreview />,
    },
    'Payment Methods': {
      key: 'saved_payment_methods',
      styling: useSelector((state) => state.paymentMethods),
      preview: <PaymentMethodsPreview />,
    },
    'N. Payment Method': {
      key: 'new_payment_method',
      styling: useSelector((state) => state.newPaymentScreen),
      preview: <NewPaymentScreenPreview />,
    },
    'Single Product': {
      key: 'item_page',
      styling: useSelector((state) => state.singleItemScreen),
      preview: <SingleItemScreenPreview />,
    },
    Favourites: {
      // key: 'favorites',
      styling: { modifiable: false },
      preview: <FavouritesScreenPreview />,
    },
    'My Orders': {
      // key: '',
      styling: { modifiable: false },
      preview: <OrdersScreenPreview />,
    },
    'My Profile': {
      // key: 'profile',
      styling: { modifiable: false },
      preview: <ProfileScreenPreview />,
    },
    'Shopping Cart': {
      // key: '',
      styling: { modifiable: false },
      preview: <ShoppingCartScreenPreview />,
    },
    Shipping: {
      // key: 'shipping_address',
      styling: { modifiable: false },
      preview: <ShippingScreenPreview />,
    },
    Payment: {
      // key: 'shipping_payment',
      styling: { modifiable: false },
      preview: <PaymentScreenPreview />,
    },
    'Thank You': {
      // key: 'thank_you',
      styling: { modifiable: false },
      preview: <ThankYouScreenPreview />,
    },
  };

  const screens = Object.keys(screenData);

  const navigateScreens = (value) => {
    const index = screens.findIndex((screen) => screen === selectedScreen);
    const newIndex =
      value === 'increment'
        ? index < screens.length - 1 ? index + 1 : index
        : index > 0 ? index - 1 : index;
    setSelectedScreen(screens[newIndex]);
  };

  return (
    <div className='my-5 grid grid-cols-2'>
      <div className='flex flex-col items-end'>
        <div className='w-3/5'>
          <div className='w-full mb-5'>
            <p className='text-4xl'>App Styling Preview</p>
          </div>

          {screenData[selectedScreen].styling?.modifiable === false ? (
            <div className='text-xl'>non-modifiable screen</div>
          ) : (
            <div className='h-screen overflow-y-scroll'>
              <ReactJsonViewCompare
                oldData={defaultSettings[screenData[selectedScreen].key]}
                newData={
                  modifiedSettings.find(
                    (screen) => screen.name === screenData[selectedScreen].key
                  )?.settings
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className='flex flex-col items-center'>
        <PreviewPanel>
          {!_.isEmpty(colors) &&
            !_.isEmpty(screenData[selectedScreen].styling) &&
            screenData[selectedScreen].preview}
        </PreviewPanel>
        <div className='w-2/5 my-5 flex justify-between items-center'>
          <div
            className='screen-navigation-button pr-1'
            onClick={() => navigateScreens('decrement')}
          >
            <FontAwesomeIcon icon={icons.chevronLeft} size='lg' />
          </div>
          <p>{selectedScreen}</p>
          <div
            className='screen-navigation-button pl-1'
            onClick={() => navigateScreens('increment')}
          >
            <FontAwesomeIcon icon={icons.chevronRight} size='lg' />
          </div>
        </div>
      </div>
    </div>
  );
};
