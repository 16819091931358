import React from 'react';
import {
  Header,
  InputPreview,
  BottomNavigationBar,
  RadioButtonPreview,
} from '../../components';

export default () => {
  const inputStyling = {
    text: {
      fontFamily: { value: 'sans-serif', label: 'Sans Serif' },
      fontStyle: '',
      color: '#9DA3B0',
      fontSize: 15,
      bold: false,
      italic: false,
      underline: false,
      textAlign: 'left',
      lineHeight: 1.5,
    },
    design: {
      entireCard: {
        borderVisibility: true,
        borderColor: '#9DA3B0',
        fillVisibility: true,
        fillColor: '#FFF',
        shadow: true,
        borderRadius: 20,
      },
      size: {
        alignment: 'center',
        width: 330,
        height: 32,
      },
    },
  };

  return (
    <div>
      <Header title='Shipping' color='white' />

      <RadioButtonPreview text='Primary address' selected={true} />
      <InputPreview text='Country' styleData={inputStyling} />

      <RadioButtonPreview text='Saved address' />
      <InputPreview text='Country' dropDown={true} styleData={inputStyling} />

      <RadioButtonPreview text='New address' />
      <InputPreview text='First Name' styleData={inputStyling} />
      <InputPreview text='Last Name' styleData={inputStyling} />
      <InputPreview text='E-Mail' styleData={inputStyling} />
      <InputPreview text='Country' dropDown={true} styleData={inputStyling} />
      <InputPreview text='State / Region' styleData={inputStyling} />
      <InputPreview text='Address' styleData={inputStyling} />
      <InputPreview text='Phone' styleData={inputStyling} />
      <InputPreview text='City' styleData={inputStyling} />
      <InputPreview text='ZIP / Postal Code' styleData={inputStyling} />

      <div className='mx-8 mt-3 flex justify-between items-center'>
        <p className='ml-5'>Back</p>
        <p className='px-5 py-1 text-blue-500 bg-white filter drop-shadow-lg rounded-md'>
          Next
        </p>
      </div>

      <BottomNavigationBar />
    </div>
  );
};
