import React from 'react';

export default ({ text, styleData }) => {
  const backgroundStyling = {
    borderColor: styleData.design.borderColor,
    borderRadius: styleData.design.borderRadius,
    backgroundColor: styleData.design.fillVisibility
      ? styleData.design.fillColor
      : 'transparent',
  };

  const textStyling = {
    color: styleData.text.color,
    fontSize: styleData.text.fontSize,
    textAlign: styleData.text.textAlign,
    lineHeight: styleData.text.lineHeight,
    fontFamily: styleData.text.fontFamily.value,
    fontWeight: styleData.text.bold && 'bold',
    fontStyle: styleData.text.italic && 'italic',
    textDecoration: styleData.text.underline && 'underline',
  };

  return (
    <div className='mt-3 flex justify-center'>
      <div
        style={backgroundStyling}
        className={`w-4/5 h-8 px-5 flex items-center ${
          styleData.design.borderVisibility && 'border-2'
        } ${styleData.design.shadow && 'filter drop-shadow-lg'}`}
      >
        <div className='w-full'>
          <p style={textStyling}>{text}</p>
        </div>
      </div>
    </div>
  );
};
