import React from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue, Alpha } from 'react-color/lib/components/common';
import reactCSS from 'reactcss';

const CustomPointer = () => {
  const styles = reactCSS({
    default: {
      picker: {
        width: '10px',
        height: '10px',
        borderRadius: '6px',
        marginLeft: '3px',
        boxShadow: 'inset 0 0 0 2px #fff',
        transform: 'translate(-6px, -6px)',
      },
    },
  });

  return <div style={styles.picker} />;
};

const HuePointer = () => {
  const styles = reactCSS({
    default: {
      picker: {
        width: '10px',
        height: '10px',
        borderRadius: '6px',
        boxShadow: 'inset 0 0 0 2px #fff',
        transform: 'translate(0px, -6px)',
      },
    },
  });

  return <div style={styles.picker} />;
};

const CustomColor = (props) => {
  return (
    <div className='flex'>
      <div className='w-72 h-72 relative'>
        <Saturation {...props} pointer={CustomPointer} />
      </div>
      <div className='w-2.5 h-72 relative mx-5'>
        <Hue {...props} pointer={HuePointer} direction='vertical' />
      </div>
      <div className='w-2.5 h-72 relative'>
        <Alpha {...props} pointer={CustomPointer} direction='vertical' />
      </div>
    </div>
  );
};

const CustomColorPicker = CustomPicker(CustomColor);

export default ({ color, colorHex, setColor, handleManualChange }) => {
  return (
    <>
      <CustomColorPicker color={color.rgb} onChangeComplete={setColor} />

      <div className='flex items-center my-5'>
        <div className='text-sm'>
          <p>Hex color</p>
          <input
            type='text'
            value={colorHex}
            readOnly={true}
            className='w-20 mr-5 border-b border-black text-center focus:outline-none'
          />
        </div>

        <div className='text-sm'>
          <p>R</p>
          <input
            type='number'
            placeholder='0'
            value={color.rgb.r}
            name='r'
            step='1'
            min={0}
            max={255}
            className='w-12 mr-5 border-b border-black text-center focus:outline-none'
            onChange={handleManualChange}
          />
        </div>

        <div className='text-sm'>
          <p>G</p>
          <input
            type='number'
            placeholder='0'
            value={color.rgb.g}
            name='g'
            step='1'
            min={0}
            max={255}
            className='w-12 mr-5 border-b border-black text-center focus:outline-none'
            onChange={handleManualChange}
          />
        </div>

        <div className='text-sm'>
          <p>B</p>
          <input
            type='number'
            placeholder='0'
            value={color.rgb.b}
            name='b'
            step='1'
            min={0}
            max={255}
            className='w-12 mr-5 border-b border-black text-center focus:outline-none'
            onChange={handleManualChange}
          />
        </div>

        <div className='text-sm'>
          <p>A</p>
          <input
            type='number'
            value={color.rgb.a}
            name='a'
            step='0.05'
            min={0}
            max={1}
            className='w-14 mr-5 border-b border-black text-center focus:outline-none'
            onChange={handleManualChange}
          />
        </div>
      </div>
    </>
  );
};
