import { toast } from 'react-toastify';
import { fetchMobileComponent } from '../api/mobileComponents.api';
import { setHomeScreenStyling } from '../AppEditor/actions/home.actions';
import { setCategoriesStyling } from '../AppEditor/actions/categories.actions';
import { setCategoryStyling } from '../AppEditor/actions/category.actions';
import { setLoginScreenStyling } from '../AppEditor/actions/login.actions';
import { setSignupScreenStyling } from '../AppEditor/actions/signup.actions';
import { setAddressScreenStyling } from '../AppEditor/actions/address.actions';
import { setNewAddressScreenStyling } from '../AppEditor/actions/newAddress.actions';
import { setPaymentMethodsStyling } from '../AppEditor/actions/paymentMethods.actions';
import { setNewPaymentScreenStyling } from '../AppEditor/actions/newPayment.actions';
import { setSingleItemScreenStyling } from '../AppEditor/actions/singleItem.actions';

export const handleStylingActions = {
  home_screen: (dispatch, data) => dispatch(setHomeScreenStyling(data)),
  categories: (dispatch, data) => dispatch(setCategoriesStyling(data)),
  category: (dispatch, data) => dispatch(setCategoryStyling(data)),
  log_in: (dispatch, data) => dispatch(setLoginScreenStyling(data)),
  sign_up: (dispatch, data) => dispatch(setSignupScreenStyling(data)),
  saved_addresses: (dispatch, data) => dispatch(setAddressScreenStyling(data)),
  new_address: (dispatch, data) => dispatch(setNewAddressScreenStyling(data)),
  saved_payment_methods: (dispatch, data) => dispatch(setPaymentMethodsStyling(data)),
  new_payment_method: (dispatch, data) => dispatch(setNewPaymentScreenStyling(data)),
  item_page: (dispatch, data) => dispatch(setSingleItemScreenStyling(data)),
};

export const getMobileComponent = (appId, name) => {
  return (dispatch) => {
    fetchMobileComponent(appId, name)
      .then((res) => {
        handleStylingActions[res.data.name](dispatch, res.data.settings);
      })
      .catch(() => toast.error('unable to get mobile components data'));
  };
};
