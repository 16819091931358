import React from 'react';
import { Header, BottomNavigationBar, ItemCardCart } from '../../components';

export default () => {
  return (
    <div>
      <Header title='Shopping Cart' color='white' />

      {[1, 2].map((index) => (
        <ItemCardCart key={index} />
      ))}

      <div className='w-full absolute bottom-20'>
        <hr className='mx-5 mb-2 border' />
        <div className='mx-5 flex justify-between items-center'>
          <p>Product Price:</p>
          <p>175 USD</p>
        </div>
        <div className='mx-5 mt-5 flex justify-between items-center'>
          <p className='ml-5'>Back</p>
          <p className='p-1 px-2 text-blue-500 bg-white filter drop-shadow-lg rounded-md'>
            Proceed to checkout
          </p>
        </div>
      </div>

      <BottomNavigationBar />
    </div>
  );
};
