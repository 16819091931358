import React from 'react';
import './style.css';

export default ({ value, setValue }) => {
  return (
    <div className='flex items-center'>
      <input
        type='range'
        min='0'
        max='1'
        step='0.05'
        value={value}
        className='slider'
        onChange={setValue}
      />
    </div>
  );
};
