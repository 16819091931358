import { toast } from 'react-toastify';
import * as types from '../constants/actions';
import { fetchApps } from '../api/apps.api.js';

const setAndroidDraftApps = (payload) => ({
  type: types.SET_ANDROID_DRAFT_APPS,
  payload: payload,
});

const setAndroidSubmittedApps = (payload) => ({
  type: types.SET_ANDROID_SUBMITTED_APPS,
  payload: payload,
});

export const getApps = () => {
  return (dispatch) => {
    fetchApps()
      .then((res) => {
        const androidDraft = res.data.filter(
          (app) => app.app_type === 'android' && app.status === 'draft'
        );
        const androidSubmitted = res.data.filter(
          (app) => app.app_type === 'android' && app.status !== 'draft'
        );

        dispatch(setAndroidDraftApps(androidDraft));
        dispatch(setAndroidSubmittedApps(androidSubmitted));
      })
      .catch(() => toast.error('unable to get apps'));
  };
};
