import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/categories.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
  TitleStyle,
  OverlayEditor,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const categories = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setCategoriesStyling(categories));
  };

  const title = {
    visibility: categories.title.visibility,
    selectedFont: categories.title.text.fontFamily,
    fontSize: categories.title.text.fontSize,
    bold: categories.title.text.bold,
    italic: categories.title.text.italic,
    underline: categories.title.text.underline,
    textAlign: categories.title.text.textAlign,
    lineHeight: categories.title.text.lineHeight,
    color: categories.title.text.color,
    borderVisibility: categories.title.design.borderVisibility,
    borderColor: categories.title.design.borderColor,
    fillVisibility: categories.title.design.fillVisibility,
    fillColor: categories.title.design.fillColor,
    shadow: categories.title.design.shadow,
    borderRadius: categories.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      categories.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      categories.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      categories.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      categories.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      categories.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      categories.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      categories.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      categories.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      categories.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      categories.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      categories.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      categories.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      categories.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      categories.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      categories.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const category = {
    visibility: categories.category.text.visibility,
    selectedFont: categories.category.text.fontFamily,
    fontSize: categories.category.text.fontSize,
    bold: categories.category.text.bold,
    italic: categories.category.text.italic,
    underline: categories.category.text.underline,
    textAlign: categories.category.text.textAlign,
    lineHeight: categories.category.text.lineHeight,
    color: categories.category.text.color,
    borderVisibility: categories.category.design.borderVisibility,
    borderColor: categories.category.design.borderColor,
    shadow: categories.category.design.shadow,
    borderRadius: categories.category.design.borderRadius,
  };

  const handleCategory = {
    visibility: () => {
      categories.category.text.visibility = !category.visibility;
      setStyling();
    },
    fontChange: (font) => {
      categories.category.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      categories.category.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      categories.category.text.bold = !category.bold;
      setStyling();
    },
    italicClick: () => {
      categories.category.text.italic = !category.italic;
      setStyling();
    },
    underlineClick: () => {
      categories.category.text.underline = !category.underline;
      setStyling();
    },
    textAlign: (value) => {
      categories.category.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      categories.category.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      categories.category.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      categories.category.design.borderVisibility = !category.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      categories.category.design.borderColor = value.hex;
      setStyling();
    },
    shadow: () => {
      categories.category.design.shadow = !category.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      categories.category.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const overlay = {
    color: categories.category.overlay.color,
  };

  const handleOverlay = {
    color: (value) => {
      categories.category.overlay.color.rgb = value.rgb;
      setStyling();
    },
    opacity: (e) => {
      categories.category.overlay.color.rgb.a = e.target.value;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerCategory = [
    {
      component: (
        <DesignEditor
          borderVisibility={category.borderVisibility}
          setBorderVisibility={handleCategory.borderVisibility}
          borderColor={category.borderColor}
          setBorderColor={handleCategory.borderColor}
          customShadow={true}
          shadowVisibilty={category.shadow}
          setShadowVisibility={handleCategory.shadow}
          shadowColor={''}
          setShadowColor={''}
          borderRadius={category.borderRadius}
          setBorderRadius={handleCategory.borderRadius}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Category',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              visibilitySelector={true}
              textVisibility={category.visibility}
              setTextVisibility={handleCategory.visibility}
              fontFamilies={fontFamilies}
              selectedFont={category.selectedFont}
              onFontChange={handleCategory.fontChange}
              fontSize={category.fontSize}
              onFontSizeChange={handleCategory.fontSize}
              bold={category.bold}
              italic={category.italic}
              underline={category.underline}
              onBoldClick={handleCategory.boldClick}
              onItalicClick={handleCategory.italicClick}
              onUnderlineClick={handleCategory.underlineClick}
              textAlign={category.textAlign}
              onLeftClick={() => handleCategory.textAlign('left')}
              onCenterClick={() => handleCategory.textAlign('center')}
              onRightClick={() => handleCategory.textAlign('right')}
              onJustifyClick={() => handleCategory.textAlign('justify')}
              lineHeight={category.lineHeight}
              onLineHeightChange={handleCategory.lineHeight}
              color={category.color}
              setColor={handleCategory.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerCategory} />,
        },
        {
          heading: 'Overlay',
          component: (
            <OverlayEditor
              overlayColor={overlay.color}
              setOverlayColor={handleOverlay.color}
              setOpacity={handleOverlay.opacity}
            />
          ),
        },
      ],
    },
  ];
};
