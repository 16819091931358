import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/signup.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
  AlignmentEditor,
  TitleStyle,
  InputStyle,
  ButtonStyle,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

const defaultStyle = {
  input: {
    alignment: 'center',
    width: 330,
    height: 32,
  },
  button: {
    alignment: 'center',
    width: 330,
    height: 35,
  },
};

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const signupScreen = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setSignupScreenStyling(signupScreen));
  };

  const title = {
    visibility: signupScreen.title.visibility,
    selectedFont: signupScreen.title.text.fontFamily,
    fontSize: signupScreen.title.text.fontSize,
    bold: signupScreen.title.text.bold,
    italic: signupScreen.title.text.italic,
    underline: signupScreen.title.text.underline,
    textAlign: signupScreen.title.text.textAlign,
    lineHeight: signupScreen.title.text.lineHeight,
    color: signupScreen.title.text.color,
    borderVisibility: signupScreen.title.design.borderVisibility,
    borderColor: signupScreen.title.design.borderColor,
    fillVisibility: signupScreen.title.design.fillVisibility,
    fillColor: signupScreen.title.design.fillColor,
    shadow: signupScreen.title.design.shadow,
    borderRadius: signupScreen.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      signupScreen.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      signupScreen.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const nameInput = {
    selectedFont: signupScreen.nameInput.text.fontFamily,
    fontSize: signupScreen.nameInput.text.fontSize,
    bold: signupScreen.nameInput.text.bold,
    italic: signupScreen.nameInput.text.italic,
    underline: signupScreen.nameInput.text.underline,
    textAlign: signupScreen.nameInput.text.textAlign,
    lineHeight: signupScreen.nameInput.text.lineHeight,
    color: signupScreen.nameInput.text.color,
    borderVisibility: signupScreen.nameInput.design.entireCard.borderVisibility,
    borderColor: signupScreen.nameInput.design.entireCard.borderColor,
    fillVisibility: signupScreen.nameInput.design.entireCard.fillVisibility,
    fillColor: signupScreen.nameInput.design.entireCard.fillColor,
    shadow: signupScreen.nameInput.design.entireCard.shadow,
    borderRadius: signupScreen.nameInput.design.entireCard.borderRadius,
    alignment: signupScreen.nameInput.design.size.alignment,
    width: signupScreen.nameInput.design.size.width,
    height: signupScreen.nameInput.design.size.height,
  };

  const handleNameInput = {
    fontChange: (font) => {
      signupScreen.nameInput.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.nameInput.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.nameInput.text.bold = !nameInput.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.nameInput.text.italic = !nameInput.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.nameInput.text.underline = !nameInput.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.nameInput.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.nameInput.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.nameInput.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.nameInput.design.entireCard.borderVisibility = !nameInput.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.nameInput.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.nameInput.design.entireCard.fillVisibility = !nameInput.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.nameInput.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.nameInput.design.entireCard.shadow = !nameInput.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.nameInput.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.nameInput.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.nameInput.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.nameInput.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.nameInput.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const emailInput = {
    selectedFont: signupScreen.emailInput.text.fontFamily,
    fontSize: signupScreen.emailInput.text.fontSize,
    bold: signupScreen.emailInput.text.bold,
    italic: signupScreen.emailInput.text.italic,
    underline: signupScreen.emailInput.text.underline,
    textAlign: signupScreen.emailInput.text.textAlign,
    lineHeight: signupScreen.emailInput.text.lineHeight,
    color: signupScreen.emailInput.text.color,
    borderVisibility: signupScreen.emailInput.design.entireCard.borderVisibility,
    borderColor: signupScreen.emailInput.design.entireCard.borderColor,
    fillVisibility: signupScreen.emailInput.design.entireCard.fillVisibility,
    fillColor: signupScreen.emailInput.design.entireCard.fillColor,
    shadow: signupScreen.emailInput.design.entireCard.shadow,
    borderRadius: signupScreen.emailInput.design.entireCard.borderRadius,
    alignment: signupScreen.emailInput.design.size.alignment,
    width: signupScreen.emailInput.design.size.width,
    height: signupScreen.emailInput.design.size.height,
  };

  const handleEmailInput = {
    fontChange: (font) => {
      signupScreen.emailInput.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.emailInput.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.emailInput.text.bold = !emailInput.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.emailInput.text.italic = !emailInput.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.emailInput.text.underline = !emailInput.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.emailInput.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.emailInput.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.emailInput.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.emailInput.design.entireCard.borderVisibility = !emailInput.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.emailInput.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.emailInput.design.entireCard.fillVisibility = !emailInput.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.emailInput.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.emailInput.design.entireCard.shadow = !emailInput.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.emailInput.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.emailInput.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.emailInput.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.emailInput.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.emailInput.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const password = {
    selectedFont: signupScreen.password.text.fontFamily,
    fontSize: signupScreen.password.text.fontSize,
    bold: signupScreen.password.text.bold,
    italic: signupScreen.password.text.italic,
    underline: signupScreen.password.text.underline,
    textAlign: signupScreen.password.text.textAlign,
    lineHeight: signupScreen.password.text.lineHeight,
    color: signupScreen.password.text.color,
    borderVisibility: signupScreen.password.design.entireCard.borderVisibility,
    borderColor: signupScreen.password.design.entireCard.borderColor,
    fillVisibility: signupScreen.password.design.entireCard.fillVisibility,
    fillColor: signupScreen.password.design.entireCard.fillColor,
    shadow: signupScreen.password.design.entireCard.shadow,
    borderRadius: signupScreen.password.design.entireCard.borderRadius,
    alignment: signupScreen.password.design.size.alignment,
    width: signupScreen.password.design.size.width,
    height: signupScreen.password.design.size.height,
  };

  const handlePassword = {
    fontChange: (font) => {
      signupScreen.password.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.password.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.password.text.bold = !password.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.password.text.italic = !password.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.password.text.underline = !password.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.password.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.password.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.password.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.password.design.entireCard.borderVisibility = !password.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.password.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.password.design.entireCard.fillVisibility = !password.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.password.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.password.design.entireCard.shadow = !password.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.password.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.password.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.password.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.password.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.password.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const confirmPassword = {
    selectedFont: signupScreen.confirmPassword.text.fontFamily,
    fontSize: signupScreen.confirmPassword.text.fontSize,
    bold: signupScreen.confirmPassword.text.bold,
    italic: signupScreen.confirmPassword.text.italic,
    underline: signupScreen.confirmPassword.text.underline,
    textAlign: signupScreen.confirmPassword.text.textAlign,
    lineHeight: signupScreen.confirmPassword.text.lineHeight,
    color: signupScreen.confirmPassword.text.color,
    borderVisibility: signupScreen.confirmPassword.design.entireCard.borderVisibility,
    borderColor: signupScreen.confirmPassword.design.entireCard.borderColor,
    fillVisibility: signupScreen.confirmPassword.design.entireCard.fillVisibility,
    fillColor: signupScreen.confirmPassword.design.entireCard.fillColor,
    shadow: signupScreen.confirmPassword.design.entireCard.shadow,
    borderRadius: signupScreen.confirmPassword.design.entireCard.borderRadius,
    alignment: signupScreen.confirmPassword.design.size.alignment,
    width: signupScreen.confirmPassword.design.size.width,
    height: signupScreen.confirmPassword.design.size.height,
  };

  const handleConfirmPassword = {
    fontChange: (font) => {
      signupScreen.confirmPassword.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.confirmPassword.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.confirmPassword.text.bold = !confirmPassword.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.confirmPassword.text.italic = !confirmPassword.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.confirmPassword.text.underline = !confirmPassword.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.confirmPassword.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.confirmPassword.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.confirmPassword.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.confirmPassword.design.entireCard.borderVisibility = !confirmPassword.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.confirmPassword.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.confirmPassword.design.entireCard.fillVisibility = !confirmPassword.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.confirmPassword.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.confirmPassword.design.entireCard.shadow = !confirmPassword.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.confirmPassword.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.confirmPassword.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.confirmPassword.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.confirmPassword.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.confirmPassword.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const phoneNumber = {
    selectedFont: signupScreen.phoneNumber.text.fontFamily,
    fontSize: signupScreen.phoneNumber.text.fontSize,
    bold: signupScreen.phoneNumber.text.bold,
    italic: signupScreen.phoneNumber.text.italic,
    underline: signupScreen.phoneNumber.text.underline,
    textAlign: signupScreen.phoneNumber.text.textAlign,
    lineHeight: signupScreen.phoneNumber.text.lineHeight,
    color: signupScreen.phoneNumber.text.color,
    borderVisibility: signupScreen.phoneNumber.design.entireCard.borderVisibility,
    borderColor: signupScreen.phoneNumber.design.entireCard.borderColor,
    fillVisibility: signupScreen.phoneNumber.design.entireCard.fillVisibility,
    fillColor: signupScreen.phoneNumber.design.entireCard.fillColor,
    shadow: signupScreen.phoneNumber.design.entireCard.shadow,
    borderRadius: signupScreen.phoneNumber.design.entireCard.borderRadius,
    alignment: signupScreen.phoneNumber.design.size.alignment,
    width: signupScreen.phoneNumber.design.size.width,
    height: signupScreen.phoneNumber.design.size.height,
  };

  const handlePhoneNumber = {
    fontChange: (font) => {
      signupScreen.phoneNumber.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.phoneNumber.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.phoneNumber.text.bold = !phoneNumber.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.phoneNumber.text.italic = !phoneNumber.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.phoneNumber.text.underline = !phoneNumber.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.phoneNumber.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.phoneNumber.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.phoneNumber.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.phoneNumber.design.entireCard.borderVisibility = !phoneNumber.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.phoneNumber.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.phoneNumber.design.entireCard.fillVisibility = !phoneNumber.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.phoneNumber.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.phoneNumber.design.entireCard.shadow = !phoneNumber.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.phoneNumber.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.phoneNumber.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.phoneNumber.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.phoneNumber.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.phoneNumber.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const checkList = {
    selectedFont: signupScreen.checkList.text.fontFamily,
    fontSize: signupScreen.checkList.text.fontSize,
    bold: signupScreen.checkList.text.bold,
    italic: signupScreen.checkList.text.italic,
    underline: signupScreen.checkList.text.underline,
    textAlign: signupScreen.checkList.text.textAlign,
    lineHeight: signupScreen.checkList.text.lineHeight,
    color: signupScreen.checkList.text.color,
  };

  const handleCheckList = {
    fontChange: (font) => {
      signupScreen.checkList.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.checkList.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.checkList.text.bold = !checkList.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.checkList.text.italic = !checkList.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.checkList.text.underline = !checkList.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.checkList.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.checkList.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.checkList.text.color = value.hex;
      setStyling();
    },
  };

  const loginButton = {
    selectedFont: signupScreen.loginButton.text.fontFamily,
    fontSize: signupScreen.loginButton.text.fontSize,
    bold: signupScreen.loginButton.text.bold,
    italic: signupScreen.loginButton.text.italic,
    underline: signupScreen.loginButton.text.underline,
    textAlign: signupScreen.loginButton.text.textAlign,
    lineHeight: signupScreen.loginButton.text.lineHeight,
    color: signupScreen.loginButton.text.color,
    borderVisibility: signupScreen.loginButton.design.entireCard.borderVisibility,
    borderColor: signupScreen.loginButton.design.entireCard.borderColor,
    fillVisibility: signupScreen.loginButton.design.entireCard.fillVisibility,
    fillColor: signupScreen.loginButton.design.entireCard.fillColor,
    shadow: signupScreen.loginButton.design.entireCard.shadow,
    borderRadius: signupScreen.loginButton.design.entireCard.borderRadius,
    alignment: signupScreen.loginButton.design.size.alignment,
    width: signupScreen.loginButton.design.size.width,
    height: signupScreen.loginButton.design.size.height,
  };

  const handleLoginButton = {
    fontChange: (font) => {
      signupScreen.loginButton.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.loginButton.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.loginButton.text.bold = !loginButton.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.loginButton.text.italic = !loginButton.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.loginButton.text.underline = !loginButton.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.loginButton.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.loginButton.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.loginButton.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.loginButton.design.entireCard.borderVisibility = !loginButton.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.loginButton.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.loginButton.design.entireCard.fillVisibility = !loginButton.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.loginButton.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.loginButton.design.entireCard.shadow = !loginButton.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.loginButton.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.loginButton.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.loginButton.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.loginButton.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.loginButton.design.size = defaultStyle.button;
      setStyling();
    },
  };

  const signupButton = {
    selectedFont: signupScreen.signupButton.text.fontFamily,
    fontSize: signupScreen.signupButton.text.fontSize,
    bold: signupScreen.signupButton.text.bold,
    italic: signupScreen.signupButton.text.italic,
    underline: signupScreen.signupButton.text.underline,
    textAlign: signupScreen.signupButton.text.textAlign,
    lineHeight: signupScreen.signupButton.text.lineHeight,
    color: signupScreen.signupButton.text.color,
    borderVisibility: signupScreen.signupButton.design.entireCard.borderVisibility,
    borderColor: signupScreen.signupButton.design.entireCard.borderColor,
    fillVisibility: signupScreen.signupButton.design.entireCard.fillVisibility,
    fillColor: signupScreen.signupButton.design.entireCard.fillColor,
    shadow: signupScreen.signupButton.design.entireCard.shadow,
    borderRadius: signupScreen.signupButton.design.entireCard.borderRadius,
    alignment: signupScreen.signupButton.design.size.alignment,
    width: signupScreen.signupButton.design.size.width,
    height: signupScreen.signupButton.design.size.height,
  };

  const handleSignupButton = {
    fontChange: (font) => {
      signupScreen.signupButton.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      signupScreen.signupButton.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      signupScreen.signupButton.text.bold = !signupButton.bold;
      setStyling();
    },
    italicClick: () => {
      signupScreen.signupButton.text.italic = !signupButton.italic;
      setStyling();
    },
    underlineClick: () => {
      signupScreen.signupButton.text.underline = !signupButton.underline;
      setStyling();
    },
    textAlign: (value) => {
      signupScreen.signupButton.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      signupScreen.signupButton.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      signupScreen.signupButton.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      signupScreen.signupButton.design.entireCard.borderVisibility = !signupButton.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      signupScreen.signupButton.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      signupScreen.signupButton.design.entireCard.fillVisibility = !signupButton.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      signupScreen.signupButton.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      signupScreen.signupButton.design.entireCard.shadow = !signupButton.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      signupScreen.signupButton.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      signupScreen.signupButton.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      signupScreen.signupButton.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      signupScreen.signupButton.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      signupScreen.signupButton.design.size = defaultStyle.button;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerNameInput = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={nameInput.borderVisibility}
          setBorderVisibility={handleNameInput.borderVisibility}
          borderColor={nameInput.borderColor}
          setBorderColor={handleNameInput.borderColor}
          fillVisibility={nameInput.fillVisibility}
          setFillVisibility={handleNameInput.fillVisibility}
          fillColor={nameInput.fillColor}
          setFillColor={handleNameInput.fillColor}
          shadowVisibilty={nameInput.shadow}
          setShadowVisibility={handleNameInput.shadow}
          borderRadius={nameInput.borderRadius}
          setBorderRadius={handleNameInput.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={nameInput.alignment}
          onRightClick={() => handleNameInput.elementAlign('end')}
          onCenterClick={() => handleNameInput.elementAlign('center')}
          onLeftClick={() => handleNameInput.elementAlign('start')}
          width={nameInput.width}
          setElementWidth={handleNameInput.width}
          height={nameInput.height}
          setElementHeight={handleNameInput.height}
          onResetPress={handleNameInput.resetSize}
        />
      ),
    },
  ];

  const designChangerEmailInput = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={emailInput.borderVisibility}
          setBorderVisibility={handleEmailInput.borderVisibility}
          borderColor={emailInput.borderColor}
          setBorderColor={handleEmailInput.borderColor}
          fillVisibility={emailInput.fillVisibility}
          setFillVisibility={handleEmailInput.fillVisibility}
          fillColor={emailInput.fillColor}
          setFillColor={handleEmailInput.fillColor}
          shadowVisibilty={emailInput.shadow}
          setShadowVisibility={handleEmailInput.shadow}
          borderRadius={emailInput.borderRadius}
          setBorderRadius={handleEmailInput.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={emailInput.alignment}
          onRightClick={() => handleEmailInput.elementAlign('end')}
          onCenterClick={() => handleEmailInput.elementAlign('center')}
          onLeftClick={() => handleEmailInput.elementAlign('start')}
          width={emailInput.width}
          setElementWidth={handleEmailInput.width}
          height={emailInput.height}
          setElementHeight={handleEmailInput.height}
          onResetPress={handleEmailInput.resetSize}
        />
      ),
    },
  ];

  const designChangerPassword = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={password.borderVisibility}
          setBorderVisibility={handlePassword.borderVisibility}
          borderColor={password.borderColor}
          setBorderColor={handlePassword.borderColor}
          fillVisibility={password.fillVisibility}
          setFillVisibility={handlePassword.fillVisibility}
          fillColor={password.fillColor}
          setFillColor={handlePassword.fillColor}
          shadowVisibilty={password.shadow}
          setShadowVisibility={handlePassword.shadow}
          borderRadius={password.borderRadius}
          setBorderRadius={handlePassword.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={password.alignment}
          onRightClick={() => handlePassword.elementAlign('end')}
          onCenterClick={() => handlePassword.elementAlign('center')}
          onLeftClick={() => handlePassword.elementAlign('start')}
          width={password.width}
          setElementWidth={handlePassword.width}
          height={password.height}
          setElementHeight={handlePassword.height}
          onResetPress={handlePassword.resetSize}
        />
      ),
    },
  ];

  const designChangerConfirmPassword = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={confirmPassword.borderVisibility}
          setBorderVisibility={handleConfirmPassword.borderVisibility}
          borderColor={confirmPassword.borderColor}
          setBorderColor={handleConfirmPassword.borderColor}
          fillVisibility={confirmPassword.fillVisibility}
          setFillVisibility={handleConfirmPassword.fillVisibility}
          fillColor={confirmPassword.fillColor}
          setFillColor={handleConfirmPassword.fillColor}
          shadowVisibilty={confirmPassword.shadow}
          setShadowVisibility={handleConfirmPassword.shadow}
          borderRadius={confirmPassword.borderRadius}
          setBorderRadius={handleConfirmPassword.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={confirmPassword.alignment}
          onRightClick={() => handleConfirmPassword.elementAlign('end')}
          onCenterClick={() => handleConfirmPassword.elementAlign('center')}
          onLeftClick={() => handleConfirmPassword.elementAlign('start')}
          width={confirmPassword.width}
          setElementWidth={handleConfirmPassword.width}
          height={confirmPassword.height}
          setElementHeight={handleConfirmPassword.height}
          onResetPress={handleConfirmPassword.resetSize}
        />
      ),
    },
  ];

  const designChangerPhoneNumber = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={phoneNumber.borderVisibility}
          setBorderVisibility={handlePhoneNumber.borderVisibility}
          borderColor={phoneNumber.borderColor}
          setBorderColor={handlePhoneNumber.borderColor}
          fillVisibility={phoneNumber.fillVisibility}
          setFillVisibility={handlePhoneNumber.fillVisibility}
          fillColor={phoneNumber.fillColor}
          setFillColor={handlePhoneNumber.fillColor}
          shadowVisibilty={phoneNumber.shadow}
          setShadowVisibility={handlePhoneNumber.shadow}
          borderRadius={phoneNumber.borderRadius}
          setBorderRadius={handlePhoneNumber.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={phoneNumber.alignment}
          onRightClick={() => handlePhoneNumber.elementAlign('end')}
          onCenterClick={() => handlePhoneNumber.elementAlign('center')}
          onLeftClick={() => handlePhoneNumber.elementAlign('start')}
          width={phoneNumber.width}
          setElementWidth={handlePhoneNumber.width}
          height={phoneNumber.height}
          setElementHeight={handlePhoneNumber.height}
          onResetPress={handlePhoneNumber.resetSize}
        />
      ),
    },
  ];

  const designChangerLoginButton = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={loginButton.borderVisibility}
          setBorderVisibility={handleLoginButton.borderVisibility}
          borderColor={loginButton.borderColor}
          setBorderColor={handleLoginButton.borderColor}
          fillVisibility={loginButton.fillVisibility}
          setFillVisibility={handleLoginButton.fillVisibility}
          fillColor={loginButton.fillColor}
          setFillColor={handleLoginButton.fillColor}
          shadowVisibilty={loginButton.shadow}
          setShadowVisibility={handleLoginButton.shadow}
          borderRadius={loginButton.borderRadius}
          setBorderRadius={handleLoginButton.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={loginButton.alignment}
          onRightClick={() => handleLoginButton.elementAlign('end')}
          onCenterClick={() => handleLoginButton.elementAlign('center')}
          onLeftClick={() => handleLoginButton.elementAlign('start')}
          width={loginButton.width}
          setElementWidth={handleLoginButton.width}
          height={loginButton.height}
          setElementHeight={handleLoginButton.height}
          onResetPress={handleLoginButton.resetSize}
        />
      ),
    },
  ];

  const designChangerSignupButton = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={signupButton.borderVisibility}
          setBorderVisibility={handleSignupButton.borderVisibility}
          borderColor={signupButton.borderColor}
          setBorderColor={handleSignupButton.borderColor}
          fillVisibility={signupButton.fillVisibility}
          setFillVisibility={handleSignupButton.fillVisibility}
          fillColor={signupButton.fillColor}
          setFillColor={handleSignupButton.fillColor}
          shadowVisibilty={signupButton.shadow}
          setShadowVisibility={handleSignupButton.shadow}
          borderRadius={signupButton.borderRadius}
          setBorderRadius={handleSignupButton.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={signupButton.alignment}
          onRightClick={() => handleSignupButton.elementAlign('end')}
          onCenterClick={() => handleSignupButton.elementAlign('center')}
          onLeftClick={() => handleSignupButton.elementAlign('start')}
          width={signupButton.width}
          setElementWidth={handleSignupButton.width}
          height={signupButton.height}
          setElementHeight={handleSignupButton.height}
          onResetPress={handleSignupButton.resetSize}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Input - Name',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={nameInput.selectedFont}
              onFontChange={handleNameInput.fontChange}
              fontSize={nameInput.fontSize}
              onFontSizeChange={handleNameInput.fontSize}
              bold={nameInput.bold}
              italic={nameInput.italic}
              underline={nameInput.underline}
              onBoldClick={handleNameInput.boldClick}
              onItalicClick={handleNameInput.italicClick}
              onUnderlineClick={handleNameInput.underlineClick}
              textAlign={nameInput.textAlign}
              onLeftClick={() => handleNameInput.textAlign('left')}
              onCenterClick={() => handleNameInput.textAlign('center')}
              onRightClick={() => handleNameInput.textAlign('right')}
              onJustifyClick={() => handleNameInput.textAlign('justify')}
              lineHeight={nameInput.lineHeight}
              onLineHeightChange={handleNameInput.lineHeight}
              color={nameInput.color}
              setColor={handleNameInput.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerNameInput} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - E-Mail',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={emailInput.selectedFont}
              onFontChange={handleEmailInput.fontChange}
              fontSize={emailInput.fontSize}
              onFontSizeChange={handleEmailInput.fontSize}
              bold={emailInput.bold}
              italic={emailInput.italic}
              underline={emailInput.underline}
              onBoldClick={handleEmailInput.boldClick}
              onItalicClick={handleEmailInput.italicClick}
              onUnderlineClick={handleEmailInput.underlineClick}
              textAlign={emailInput.textAlign}
              onLeftClick={() => handleEmailInput.textAlign('left')}
              onCenterClick={() => handleEmailInput.textAlign('center')}
              onRightClick={() => handleEmailInput.textAlign('right')}
              onJustifyClick={() => handleEmailInput.textAlign('justify')}
              lineHeight={emailInput.lineHeight}
              onLineHeightChange={handleEmailInput.lineHeight}
              color={emailInput.color}
              setColor={handleEmailInput.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerEmailInput} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Password',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={password.selectedFont}
              onFontChange={handlePassword.fontChange}
              fontSize={password.fontSize}
              onFontSizeChange={handlePassword.fontSize}
              bold={password.bold}
              italic={password.italic}
              underline={password.underline}
              onBoldClick={handlePassword.boldClick}
              onItalicClick={handlePassword.italicClick}
              onUnderlineClick={handlePassword.underlineClick}
              textAlign={password.textAlign}
              onLeftClick={() => handlePassword.textAlign('left')}
              onCenterClick={() => handlePassword.textAlign('center')}
              onRightClick={() => handlePassword.textAlign('right')}
              onJustifyClick={() => handlePassword.textAlign('justify')}
              lineHeight={password.lineHeight}
              onLineHeightChange={handlePassword.lineHeight}
              color={password.color}
              setColor={handlePassword.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerPassword} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Confirm Password',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={confirmPassword.selectedFont}
              onFontChange={handleConfirmPassword.fontChange}
              fontSize={confirmPassword.fontSize}
              onFontSizeChange={handleConfirmPassword.fontSize}
              bold={confirmPassword.bold}
              italic={confirmPassword.italic}
              underline={confirmPassword.underline}
              onBoldClick={handleConfirmPassword.boldClick}
              onItalicClick={handleConfirmPassword.italicClick}
              onUnderlineClick={handleConfirmPassword.underlineClick}
              textAlign={confirmPassword.textAlign}
              onLeftClick={() => handleConfirmPassword.textAlign('left')}
              onCenterClick={() => handleConfirmPassword.textAlign('center')}
              onRightClick={() => handleConfirmPassword.textAlign('right')}
              onJustifyClick={() => handleConfirmPassword.textAlign('justify')}
              lineHeight={confirmPassword.lineHeight}
              onLineHeightChange={handleConfirmPassword.lineHeight}
              color={confirmPassword.color}
              setColor={handleConfirmPassword.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerConfirmPassword} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Phone Number',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={phoneNumber.selectedFont}
              onFontChange={handlePhoneNumber.fontChange}
              fontSize={phoneNumber.fontSize}
              onFontSizeChange={handlePhoneNumber.fontSize}
              bold={phoneNumber.bold}
              italic={phoneNumber.italic}
              underline={phoneNumber.underline}
              onBoldClick={handlePhoneNumber.boldClick}
              onItalicClick={handlePhoneNumber.italicClick}
              onUnderlineClick={handlePhoneNumber.underlineClick}
              textAlign={phoneNumber.textAlign}
              onLeftClick={() => handlePhoneNumber.textAlign('left')}
              onCenterClick={() => handlePhoneNumber.textAlign('center')}
              onRightClick={() => handlePhoneNumber.textAlign('right')}
              onJustifyClick={() => handlePhoneNumber.textAlign('justify')}
              lineHeight={phoneNumber.lineHeight}
              onLineHeightChange={handlePhoneNumber.lineHeight}
              color={phoneNumber.color}
              setColor={handlePhoneNumber.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerPhoneNumber} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Check List',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={checkList.selectedFont}
              onFontChange={handleCheckList.fontChange}
              fontSize={checkList.fontSize}
              onFontSizeChange={handleCheckList.fontSize}
              bold={checkList.bold}
              italic={checkList.italic}
              underline={checkList.underline}
              onBoldClick={handleCheckList.boldClick}
              onItalicClick={handleCheckList.italicClick}
              onUnderlineClick={handleCheckList.underlineClick}
              textAlign={checkList.textAlign}
              onLeftClick={() => handleCheckList.textAlign('left')}
              onCenterClick={() => handleCheckList.textAlign('center')}
              onRightClick={() => handleCheckList.textAlign('right')}
              onJustifyClick={() => handleCheckList.textAlign('justify')}
              lineHeight={checkList.lineHeight}
              onLineHeightChange={handleCheckList.lineHeight}
              color={checkList.color}
              setColor={handleCheckList.colorChange}
            />
          ),
        },
      ],
    },
    {
      title: 'Button - Log In',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={loginButton.selectedFont}
              onFontChange={handleLoginButton.fontChange}
              fontSize={loginButton.fontSize}
              onFontSizeChange={handleLoginButton.fontSize}
              bold={loginButton.bold}
              italic={loginButton.italic}
              underline={loginButton.underline}
              onBoldClick={handleLoginButton.boldClick}
              onItalicClick={handleLoginButton.italicClick}
              onUnderlineClick={handleLoginButton.underlineClick}
              textAlign={loginButton.textAlign}
              onLeftClick={() => handleLoginButton.textAlign('left')}
              onCenterClick={() => handleLoginButton.textAlign('center')}
              onRightClick={() => handleLoginButton.textAlign('right')}
              onJustifyClick={() => handleLoginButton.textAlign('justify')}
              lineHeight={loginButton.lineHeight}
              onLineHeightChange={handleLoginButton.lineHeight}
              color={loginButton.color}
              setColor={handleLoginButton.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerLoginButton} />,
        },
        {
          heading: 'Style',
          component: <ButtonStyle />,
        },
      ],
    },
    {
      title: 'Button - Sign Up',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={signupButton.selectedFont}
              onFontChange={handleSignupButton.fontChange}
              fontSize={signupButton.fontSize}
              onFontSizeChange={handleSignupButton.fontSize}
              bold={signupButton.bold}
              italic={signupButton.italic}
              underline={signupButton.underline}
              onBoldClick={handleSignupButton.boldClick}
              onItalicClick={handleSignupButton.italicClick}
              onUnderlineClick={handleSignupButton.underlineClick}
              textAlign={signupButton.textAlign}
              onLeftClick={() => handleSignupButton.textAlign('left')}
              onCenterClick={() => handleSignupButton.textAlign('center')}
              onRightClick={() => handleSignupButton.textAlign('right')}
              onJustifyClick={() => handleSignupButton.textAlign('justify')}
              lineHeight={signupButton.lineHeight}
              onLineHeightChange={handleSignupButton.lineHeight}
              color={signupButton.color}
              setColor={handleSignupButton.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerSignupButton} />,
        },
        {
          heading: 'Style',
          component: <ButtonStyle />,
        },
      ],
    },
  ];
};
