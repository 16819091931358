import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.newAddressScreen, action) => {
  switch (action.type) {
    case types.SET_NEW_ADDRESS_SCREEN_STYLING: {
      let newAddressScreen = _.cloneDeep(state);

      newAddressScreen = action.payload;

      return newAddressScreen;
    }
    case types.SET_NEW_ADDRESS_SCREEN_ORDER: {
      let newAddressScreen = _.cloneDeep(state);

      newAddressScreen.order = action.payload;

      return newAddressScreen;
    }
    default:
      return state;
  }
};
