import React, { useState } from 'react';
import { Button } from '../../components/common';

export default ({ items }) => {
  const [selected, setSelected] = useState(0);

  return (
    <>
      {items && items.length > 1 && (
        <div className='flex items-center mb-2'>
          {items.map((item, index) => {
            return (
              <Button
                key={index}
                text={item.heading}
                selected={selected === index}
                onClick={() => setSelected(index)}
              />
            );
          })}
        </div>
      )}

      <div>{items[selected]?.component}</div>
    </>
  );
};
