import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import { Header, Title, AddressCard } from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const addressScreen = useSelector((state) => state.addressScreen);

  const headerColor = selectedColor(colors, 'primary');
  const title = addressScreen.title.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${addressScreen.order[0]}`}>
        {title && (
          <Title text='Saved Addresses' styleData={addressScreen.title} />
        )}
      </div>
      <div className={`order-${addressScreen.order[1]}`}>
        <AddressCard styleData={addressScreen.addressCard} />
      </div>
    </div>
  );
};
