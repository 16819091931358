import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '../../components/common';
import * as icons from '../../../assets/icons';

export default ({
  items,
  moveUp,
  moveDown,
  itemVisibility,
  setItemVisibility,
}) => {
  const [expanded, setExpanded] = useState(null);
  const [num, setNumber] = useState(0);

  return (
    <div>
      <div className='mt-2 filter drop-shadow-lg bg-white h-12 flex justify-between items-center'>
        <div className='flex items-center'>
          {!expanded && (
            <div className='mx-4 flex flex-col justify-between h-10'>
              <FontAwesomeIcon
                className='cursor-pointer'
                icon={icons.caretUp}
                onClick={moveUp}
              />
              <FontAwesomeIcon
                className='cursor-pointer'
                icon={icons.caretDown}
                onClick={moveDown}
              />
            </div>
          )}
          <p className='text-lg ml-4'>{items.title}</p>
        </div>
        <div className='flex items-center mr-6'>
          {items.button && (
            <div className='mr-20 flex items-center'>
              <Toggle checked={itemVisibility} onClick={setItemVisibility} />
            </div>
          )}
          <p className='cursor-pointer' onClick={() => setExpanded(!expanded)}>
            <FontAwesomeIcon
              icon={expanded ? icons.chevronUp : icons.chevronDown}
            />
          </p>
        </div>
      </div>
      {expanded && (
        <div className='filter drop-shadow-lg bg-white h-72'>
          <div className='pl-4 pb-2 flex'>
            {items.expanded.map((item, index) => {
              return (
                <p
                  key={index}
                  className={`mr-4 px-3 pb-0.5 cursor-pointer ${
                    num === index && 'border-b-2 border-blue-500'
                  }`}
                  onClick={() => setNumber(index)}
                >
                  {item.heading}
                </p>
              );
            })}
          </div>
          <div className='mx-4 my-2'>{items.expanded[num]?.component}</div>
        </div>
      )}
    </div>
  );
};
