import React from 'react';
import { SubmittedApp, DraftApp, NewApp, NavBar } from '../../components';

export default () => {
  const submittedApps = ['New App'];
  const draftApps = ['New App', 'New App'];

  return (
    <div>
      <NavBar />
      <div>
        <h1 className='text-3xl ml-28 mt-10'>My Apps</h1>
        <div className='ml-32 mt-4 flex flex-wrap'>
          {submittedApps.map((app, index) => (
            <SubmittedApp key={index} />
          ))}
        </div>
      </div>
      <div>
        <h2 className='text-2xl ml-28 mt-10'>Draft Apps</h2>
        <div className='ml-32 mt-4 flex flex-wrap'>
          {draftApps.map((app, index) => (
            <DraftApp key={index} />
          ))}
          <NewApp />
        </div>
      </div>
    </div>
  );
};
