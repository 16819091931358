import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ itemsPerRow, increaseItemsPerRow, decreaseItemsPerRow }) => {
  const options = [
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'banana', label: 'Banana' },
    { value: 'apple', label: 'Apple' },
  ];

  return (
    <div className='grid grid-cols-2'>
      <div>
        <p className='text-sm'>Category displayed</p>
        <Select options={options} maxMenuHeight={125} className='w-4/5 my-2' />
      </div>

      <div>
        <p className='text-sm'>Number of items per row</p>
        <div className='flex items-center mt-3'>
          <div
            onClick={increaseItemsPerRow}
            className='w-6 h-6 rounded-full bg-blue-500 flex justify-center items-center cursor-pointer'
          >
            <FontAwesomeIcon icon={icons.plus} color='white' size='sm' />
          </div>
          <p className='mx-8 text-sm'>{itemsPerRow}</p>
          <div
            onClick={decreaseItemsPerRow}
            className='w-6 h-6 rounded-full bg-blue-500 flex justify-center items-center cursor-pointer'
          >
            <FontAwesomeIcon icon={icons.minus} color='white' size='sm' />
          </div>
        </div>
        <p className='text-xs mt-2 ml-24'>Max. 3</p>
      </div>
    </div>
  );
};
