import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.signupScreen, action) => {
  switch (action.type) {
    case types.SET_SIGNUP_SCREEN_STYLING: {
      let signupScreen = _.cloneDeep(state);

      signupScreen = action.payload;

      return signupScreen;
    }
    case types.SET_SIGNUP_SCREEN_ORDER: {
      let signupScreen = _.cloneDeep(state);

      signupScreen.order = action.payload;

      return signupScreen;
    }
    default:
      return state;
  }
};
