import { toast } from 'react-toastify';
import * as types from '../constants/actions';
import { fetchApp } from '../api/apps.api';

export const setAppColors = (payload) => ({
  type: types.SET_APP_COLORS,
  payload: payload,
});

export const setAppTemplateId = (payload) => ({
  type: types.SET_APP_TEMPLATE,
  payload: payload,
});

export const getApp = (appId) => {
  return (dispatch) => {
    fetchApp(appId)
      .then((res) => {
        dispatch(setAppColors(res.data.configuration.colors));
        dispatch(setAppTemplateId(res.data.mobile_template_id));
      })
      .catch(() => toast.error('unable to get app data'));
  };
};
