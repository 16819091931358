import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/category.actions.js';
import {
  TextEditor,
  CategoryPickerHome,
  DesignChanger,
  DesignEditor,
  DesignDescription,
  AlignmentEditor,
  TitleStyle,
  SearchbarStyle,
  ItemCardStyle,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

const defaultStyle = {
  categoryImage: {
    alignment: 'center',
    width: 330,
    height: 130,
  },
  itemList: {
    alignment: 'center',
    width: 160,
    height: 160,
  },
  searchBar: {
    alignment: 'center',
    width: 330,
    height: 32,
  },
};

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const category = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setCategoryStyling(category));
  };

  const title = {
    visibility: category.title.visibility,
    selectedFont: category.title.text.fontFamily,
    fontSize: category.title.text.fontSize,
    bold: category.title.text.bold,
    italic: category.title.text.italic,
    underline: category.title.text.underline,
    textAlign: category.title.text.textAlign,
    lineHeight: category.title.text.lineHeight,
    color: category.title.text.color,
    borderVisibility: category.title.design.borderVisibility,
    borderColor: category.title.design.borderColor,
    fillVisibility: category.title.design.fillVisibility,
    fillColor: category.title.design.fillColor,
    shadow: category.title.design.shadow,
    borderRadius: category.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      category.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      category.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      category.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      category.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      category.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      category.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      category.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      category.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      category.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      category.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      category.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      category.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      category.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      category.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      category.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const image = {
    visibility: category.categoryImage.visibility,
    borderVisibility: category.categoryImage.design.entireCard.borderVisibility,
    borderColor: category.categoryImage.design.entireCard.borderColor,
    fillVisibility: category.categoryImage.design.entireCard.fillVisibility,
    fillColor: category.categoryImage.design.entireCard.fillColor.rgb,
    shadow: category.categoryImage.design.entireCard.shadow,
    borderRadius: category.categoryImage.design.entireCard.borderRadius,
    alignment: category.categoryImage.design.size.alignment,
    width: category.categoryImage.design.size.width,
    height: category.categoryImage.design.size.height,
  };

  const handleImage = {
    visibility: () => {
      category.categoryImage.visibility = !image.visibility;
      setStyling();
    },
    borderVisibility: () => {
      category.categoryImage.design.entireCard.borderVisibility = !image.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      category.categoryImage.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      category.categoryImage.design.entireCard.fillVisibility = !image.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      category.categoryImage.design.entireCard.fillColor.rgb = value.rgb;
      setStyling();
    },
    shadow: () => {
      category.categoryImage.design.entireCard.shadow = !image.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      category.categoryImage.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      category.categoryImage.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      category.categoryImage.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      category.categoryImage.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      category.categoryImage.design.size = defaultStyle.categoryImage;
      setStyling();
    },
  };

  const scrollList = {
    visibility: category.itemScrollList.visibility,
    selectedFont: category.itemScrollList.text.fontFamily,
    fontSize: category.itemScrollList.text.fontSize,
    bold: category.itemScrollList.text.bold,
    italic: category.itemScrollList.text.italic,
    underline: category.itemScrollList.text.underline,
    textAlign: category.itemScrollList.text.textAlign,
    lineHeight: category.itemScrollList.text.lineHeight,
    color: category.itemScrollList.text.color,
  };

  const handleScrollList = {
    visibility: () => {
      category.itemScrollList.visibility = !scrollList.visibility;
      setStyling();
    },
    fontChange: (font) => {
      category.itemScrollList.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      category.itemScrollList.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      category.itemScrollList.text.bold = !scrollList.bold;
      setStyling();
    },
    italicClick: () => {
      category.itemScrollList.text.italic = !scrollList.italic;
      setStyling();
    },
    underlineClick: () => {
      category.itemScrollList.text.underline = !scrollList.underline;
      setStyling();
    },
    textAlign: (value) => {
      category.itemScrollList.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      category.itemScrollList.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      category.itemScrollList.text.color = value.hex;
      setStyling();
    },
  };

  const itemList = {
    title: {
      visibility: category.itemList.title.visibility,
      text: category.itemList.title.text,
      selectedFont: category.itemList.title.fontFamily,
      fontSize: category.itemList.title.fontSize,
      bold: category.itemList.title.bold,
      italic: category.itemList.title.italic,
      underline: category.itemList.title.underline,
      textAlign: category.itemList.title.textAlign,
      lineHeight: category.itemList.title.lineHeight,
      color: category.itemList.title.color,
    },
    itemsPerRow: category.itemList.category.itemsPerRow,
    itemName: {
      selectedFont: category.itemList.itemName.fontFamily,
      fontSize: category.itemList.itemName.fontSize,
      bold: category.itemList.itemName.bold,
      italic: category.itemList.itemName.italic,
      underline: category.itemList.itemName.underline,
      textAlign: category.itemList.itemName.textAlign,
      lineHeight: category.itemList.itemName.lineHeight,
      color: category.itemList.itemName.color,
    },
    price: {
      selectedFont: category.itemList.price.fontFamily,
      fontSize: category.itemList.price.fontSize,
      bold: category.itemList.price.bold,
      italic: category.itemList.price.italic,
      underline: category.itemList.price.underline,
      textAlign: category.itemList.price.textAlign,
      lineHeight: category.itemList.price.lineHeight,
      color: category.itemList.price.color,
    },
    borderVisibility: category.itemList.design.entireCard.borderVisibility,
    borderColor: category.itemList.design.entireCard.borderColor,
    fillVisibility: category.itemList.design.entireCard.fillVisibility,
    fillColor: category.itemList.design.entireCard.fillColor.rgb,
    shadow: category.itemList.design.entireCard.shadow,
    borderRadius: category.itemList.design.entireCard.borderRadius,
    descriptionColor: category.itemList.design.description.color,
    alignment: category.itemList.design.size.alignment,
    width: category.itemList.design.size.width,
    height: category.itemList.design.size.height,
  };

  const handleItemList = {
    title: {
      visibility: () => {
        category.itemList.title.visibility = !itemList.title.visibility;
        setStyling();
      },
      text: (e) => {
        category.itemList.title.text = e.target.value;
        setStyling();
      },
      fontChange: (font) => {
        category.itemList.title.fontFamily = font;
        setStyling();
      },
      fontSize: (e) => {
        category.itemList.title.fontSize = Number(e.target.value);
        setStyling();
      },
      boldClick: () => {
        category.itemList.title.bold = !itemList.title.bold;
        setStyling();
      },
      italicClick: () => {
        category.itemList.title.italic = !itemList.title.italic;
        setStyling();
      },
      underlineClick: () => {
        category.itemList.title.underline = !itemList.title.underline;
        setStyling();
      },
      textAlign: (value) => {
        category.itemList.title.textAlign = value;
        setStyling();
      },
      lineHeight: (e) => {
        category.itemList.title.lineHeight = e.target.value;
        setStyling();
      },
      colorChange: (value) => {
        category.itemList.title.color = value.hex;
        setStyling();
      },
    },
    increaseItemsPerRow: () => {
      category.itemList.category.itemsPerRow = itemList.itemsPerRow + 1;
      itemList.itemsPerRow < 3 && setStyling();
    },
    decreaseItemsPerRow: () => {
      category.itemList.category.itemsPerRow = itemList.itemsPerRow - 1;
      itemList.itemsPerRow > 1 && setStyling();
    },
    itemName: {
      fontChange: (font) => {
        category.itemList.itemName.fontFamily = font;
        setStyling();
      },
      fontSize: (e) => {
        category.itemList.itemName.fontSize = Number(e.target.value);
        setStyling();
      },
      boldClick: () => {
        category.itemList.itemName.bold = !itemList.itemName.bold;
        setStyling();
      },
      italicClick: () => {
        category.itemList.itemName.italic = !itemList.itemName.italic;
        setStyling();
      },
      underlineClick: () => {
        category.itemList.itemName.underline = !itemList.itemName.underline;
        setStyling();
      },
      textAlign: (value) => {
        category.itemList.itemName.textAlign = value;
        setStyling();
      },
      lineHeight: (e) => {
        category.itemList.itemName.lineHeight = e.target.value;
        setStyling();
      },
      colorChange: (value) => {
        category.itemList.itemName.color = value.hex;
        setStyling();
      },
    },
    price: {
      fontChange: (font) => {
        category.itemList.price.fontFamily = font;
        setStyling();
      },
      fontSize: (e) => {
        category.itemList.price.fontSize = Number(e.target.value);
        setStyling();
      },
      boldClick: () => {
        category.itemList.price.bold = !itemList.price.bold;
        setStyling();
      },
      italicClick: () => {
        category.itemList.price.italic = !itemList.price.italic;
        setStyling();
      },
      underlineClick: () => {
        category.itemList.price.underline = !itemList.price.underline;
        setStyling();
      },
      textAlign: (value) => {
        category.itemList.price.textAlign = value;
        setStyling();
      },
      lineHeight: (e) => {
        category.itemList.price.lineHeight = e.target.value;
        setStyling();
      },
      colorChange: (value) => {
        category.itemList.price.color = value.hex;
        setStyling();
      },
    },
    borderVisibility: () => {
      category.itemList.design.entireCard.borderVisibility = !itemList.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      category.itemList.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      category.itemList.design.entireCard.fillVisibility = !itemList.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      category.itemList.design.entireCard.fillColor.rgb = value.rgb;
      setStyling();
    },
    shadow: () => {
      category.itemList.design.entireCard.shadow = !itemList.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      category.itemList.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    descriptionColor: (value) => {
      category.itemList.design.description.color.rgb = value.rgb;
      setStyling();
    },
    descriptionOpacity: (e) => {
      category.itemList.design.description.color.rgb.a = e.target.value;
      setStyling();
    },
    elementAlign: (value) => {
      category.itemList.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      category.itemList.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      category.itemList.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      category.itemList.design.size = defaultStyle.itemList;
      setStyling();
    },
  };

  const searchBar = {
    selectedFont: category.searchBar.text.fontFamily,
    fontSize: category.searchBar.text.fontSize,
    bold: category.searchBar.text.bold,
    italic: category.searchBar.text.italic,
    underline: category.searchBar.text.underline,
    textAlign: category.searchBar.text.textAlign,
    lineHeight: category.searchBar.text.lineHeight,
    color: category.searchBar.text.color,
    borderVisibility: category.searchBar.design.entireBar.borderVisibility,
    borderColor: category.searchBar.design.entireBar.borderColor,
    fillVisibility: category.searchBar.design.entireBar.fillVisibility,
    fillColor: category.searchBar.design.entireBar.fillColor,
    shadow: category.searchBar.design.entireBar.shadow,
    borderRadius: category.searchBar.design.entireBar.borderRadius,
    icon: category.searchBar.design.entireBar.icon,
    alignment: category.searchBar.design.size.alignment,
    width: category.searchBar.design.size.width,
    height: category.searchBar.design.size.height,
  };

  const handleSearchbar = {
    fontChange: (font) => {
      category.searchBar.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      category.searchBar.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      category.searchBar.text.bold = !searchBar.bold;
      setStyling();
    },
    italicClick: () => {
      category.searchBar.text.italic = !searchBar.italic;
      setStyling();
    },
    underlineClick: () => {
      category.searchBar.text.underline = !searchBar.underline;
      setStyling();
    },
    textAlign: (value) => {
      category.searchBar.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      category.searchBar.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      category.searchBar.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      category.searchBar.design.entireBar.borderVisibility = !searchBar.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      category.searchBar.design.entireBar.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      category.searchBar.design.entireBar.fillVisibility = !searchBar.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      category.searchBar.design.entireBar.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      category.searchBar.design.entireBar.shadow = !searchBar.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      category.searchBar.design.entireBar.borderRadius = Number(e.target.value);
      setStyling();
    },
    icon: () => {
      category.searchBar.design.entireBar.icon = !searchBar.icon;
      setStyling();
    },
    elementAlign: (value) => {
      category.searchBar.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      category.searchBar.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      category.searchBar.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      category.searchBar.design.size = defaultStyle.searchBar;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerImage = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={image.borderVisibility}
          setBorderVisibility={handleImage.borderVisibility}
          borderColor={image.borderColor}
          setBorderColor={handleImage.borderColor}
          fillVisibility={image.fillVisibility}
          setFillVisibility={handleImage.fillVisibility}
          fillColor={image.fillColor}
          setFillColor={handleImage.fillColor}
          shadowVisibilty={image.shadow}
          setShadowVisibility={handleImage.shadow}
          borderRadius={image.borderRadius}
          setBorderRadius={handleImage.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={image.alignment}
          onRightClick={() => handleImage.elementAlign('end')}
          onCenterClick={() => handleImage.elementAlign('center')}
          onLeftClick={() => handleImage.elementAlign('start')}
          width={image.width}
          setElementWidth={handleImage.width}
          height={image.height}
          setElementHeight={handleImage.height}
          onResetPress={handleImage.resetSize}
        />
      ),
    },
  ];

  const designChangerItemList = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={itemList.borderVisibility}
          setBorderVisibility={handleItemList.borderVisibility}
          borderColor={itemList.borderColor}
          setBorderColor={handleItemList.borderColor}
          fillVisibility={itemList.fillVisibility}
          setFillVisibility={handleItemList.fillVisibility}
          fillColor={itemList.fillColor}
          setFillColor={handleItemList.fillColor}
          shadowVisibilty={itemList.shadow}
          setShadowVisibility={handleItemList.shadow}
          borderRadius={itemList.borderRadius}
          setBorderRadius={handleItemList.borderRadius}
        />
      ),
    },
    {
      heading: 'Description',
      component: (
        <DesignDescription
          color={itemList.descriptionColor}
          setColor={handleItemList.descriptionColor}
          setOpacity={handleItemList.descriptionOpacity}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={itemList.alignment}
          onRightClick={() => handleItemList.elementAlign('end')}
          onCenterClick={() => handleItemList.elementAlign('center')}
          onLeftClick={() => handleItemList.elementAlign('start')}
          width={itemList.width}
          setElementWidth={handleItemList.width}
          height={itemList.height}
          setElementHeight={handleItemList.height}
          onResetPress={handleItemList.resetSize}
        />
      ),
    },
  ];

  const designChangerSearchbar = [
    {
      heading: 'Entire Bar',
      component: (
        <DesignEditor
          iconSelector={true}
          borderVisibility={searchBar.borderVisibility}
          setBorderVisibility={handleSearchbar.borderVisibility}
          borderColor={searchBar.borderColor}
          setBorderColor={handleSearchbar.borderColor}
          fillVisibility={searchBar.fillVisibility}
          setFillVisibility={handleSearchbar.fillVisibility}
          fillColor={searchBar.fillColor}
          setFillColor={handleSearchbar.fillColor}
          shadowVisibilty={searchBar.shadow}
          setShadowVisibility={handleSearchbar.shadow}
          borderRadius={searchBar.borderRadius}
          setBorderRadius={handleSearchbar.borderRadius}
          iconVisibility={searchBar.icon}
          setIconVisibility={handleSearchbar.icon}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
        alignment={searchBar.alignment}
        onRightClick={() => handleSearchbar.elementAlign('end')}
        onCenterClick={() => handleSearchbar.elementAlign('center')}
        onLeftClick={() => handleSearchbar.elementAlign('start')}
        width={searchBar.width}
        setElementWidth={handleSearchbar.width}
        height={searchBar.height}
        setElementHeight={handleSearchbar.height}
        onResetPress={handleSearchbar.resetSize}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Category Image',
      button: true,
      visibility: image.visibility,
      setVisibility: handleImage.visibility,
      expanded: [
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerImage} />,
        },
      ],
    },
    {
      title: 'Item Scroll List',
      button: true,
      visibility: scrollList.visibility,
      setVisibility: handleScrollList.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={scrollList.selectedFont}
              onFontChange={handleScrollList.fontChange}
              fontSize={scrollList.fontSize}
              onFontSizeChange={handleScrollList.fontSize}
              bold={scrollList.bold}
              italic={scrollList.italic}
              underline={scrollList.underline}
              onBoldClick={handleScrollList.boldClick}
              onItalicClick={handleScrollList.italicClick}
              onUnderlineClick={handleScrollList.underlineClick}
              textAlign={scrollList.textAlign}
              onLeftClick={() => handleScrollList.textAlign('left')}
              onCenterClick={() => handleScrollList.textAlign('center')}
              onRightClick={() => handleScrollList.textAlign('right')}
              onJustifyClick={() => handleScrollList.textAlign('justify')}
              lineHeight={scrollList.lineHeight}
              onLineHeightChange={handleScrollList.lineHeight}
              color={scrollList.color}
              setColor={handleScrollList.colorChange}
            />
          ),
        },
      ],
    },
    {
      title: 'Item List',
      expanded: [
        {
          heading: 'Title',
          component: (
            <TextEditor
              title={true}
              visibilitySelector={true}
              fontFamilies={fontFamilies}
              titleText={itemList.title.text}
              onTitleChange={handleItemList.title.text}
              textVisibility={itemList.title.visibility}
              setTextVisibility={handleItemList.title.visibility}
              selectedFont={itemList.title.selectedFont}
              onFontChange={handleItemList.title.fontChange}
              fontSize={itemList.title.fontSize}
              onFontSizeChange={handleItemList.title.fontSize}
              bold={itemList.title.bold}
              italic={itemList.title.italic}
              underline={itemList.title.underline}
              onBoldClick={handleItemList.title.boldClick}
              onItalicClick={handleItemList.title.italicClick}
              onUnderlineClick={handleItemList.title.underlineClick}
              textAlign={itemList.title.textAlign}
              onLeftClick={() => handleItemList.title.textAlign('left')}
              onCenterClick={() => handleItemList.title.textAlign('center')}
              onRightClick={() => handleItemList.title.textAlign('right')}
              onJustifyClick={() => handleItemList.title.textAlign('justify')}
              lineHeight={itemList.title.lineHeight}
              onLineHeightChange={handleItemList.title.lineHeight}
              color={itemList.title.color}
              setColor={handleItemList.title.colorChange}
            />
          ),
        },
        {
          heading: 'Category',
          component: (
            <CategoryPickerHome
            itemsPerRow={itemList.itemsPerRow}
            increaseItemsPerRow={handleItemList.increaseItemsPerRow}
            decreaseItemsPerRow={handleItemList.decreaseItemsPerRow}
            />
          ),
        },
        {
          heading: 'Item Name',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={itemList.itemName.selectedFont}
              onFontChange={handleItemList.itemName.fontChange}
              fontSize={itemList.itemName.fontSize}
              onFontSizeChange={handleItemList.itemName.fontSize}
              bold={itemList.itemName.bold}
              italic={itemList.itemName.italic}
              underline={itemList.itemName.underline}
              onBoldClick={handleItemList.itemName.boldClick}
              onItalicClick={handleItemList.itemName.italicClick}
              onUnderlineClick={handleItemList.itemName.underlineClick}
              textAlign={itemList.itemName.textAlign}
              onLeftClick={() => handleItemList.itemName.textAlign('left')}
              onCenterClick={() => handleItemList.itemName.textAlign('center')}
              onRightClick={() => handleItemList.itemName.textAlign('right')}
              onJustifyClick={() => handleItemList.itemName.textAlign('justify')}
              lineHeight={itemList.itemName.lineHeight}
              onLineHeightChange={handleItemList.itemName.lineHeight}
              color={itemList.itemName.color}
              setColor={handleItemList.itemName.colorChange}
            />
          ),
        },
        {
          heading: 'Price',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={itemList.price.selectedFont}
              onFontChange={handleItemList.price.fontChange}
              fontSize={itemList.price.fontSize}
              onFontSizeChange={handleItemList.price.fontSize}
              bold={itemList.price.bold}
              italic={itemList.price.italic}
              underline={itemList.price.underline}
              onBoldClick={handleItemList.price.boldClick}
              onItalicClick={handleItemList.price.italicClick}
              onUnderlineClick={handleItemList.price.underlineClick}
              textAlign={itemList.price.textAlign}
              onLeftClick={() => handleItemList.price.textAlign('left')}
              onCenterClick={() => handleItemList.price.textAlign('center')}
              onRightClick={() => handleItemList.price.textAlign('right')}
              onJustifyClick={() => handleItemList.price.textAlign('justify')}
              lineHeight={itemList.price.lineHeight}
              onLineHeightChange={handleItemList.price.lineHeight}
              color={itemList.price.color}
              setColor={handleItemList.price.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerItemList} />,
        },
        { heading: 'Style', component: <ItemCardStyle /> },
      ],
    },
    {
      title: 'Searchbar',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={searchBar.selectedFont}
              onFontChange={handleSearchbar.fontChange}
              fontSize={searchBar.fontSize}
              onFontSizeChange={handleSearchbar.fontSize}
              bold={searchBar.bold}
              italic={searchBar.italic}
              underline={searchBar.underline}
              onBoldClick={handleSearchbar.boldClick}
              onItalicClick={handleSearchbar.italicClick}
              onUnderlineClick={handleSearchbar.underlineClick}
              textAlign={searchBar.textAlign}
              onLeftClick={() => handleSearchbar.textAlign('left')}
              onCenterClick={() => handleSearchbar.textAlign('center')}
              onRightClick={() => handleSearchbar.textAlign('right')}
              onJustifyClick={() => handleSearchbar.textAlign('justify')}
              lineHeight={searchBar.lineHeight}
              onLineHeightChange={handleSearchbar.lineHeight}
              color={searchBar.color}
              setColor={handleSearchbar.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerSearchbar} />,
        },
        { heading: 'Style', component: <SearchbarStyle /> },
      ],
    },
  ];
};
