export const fontFamilies = [
  { value: 'sans-serif', label: 'Sans Serif' },
  { value: 'garamond', label: 'Garamond' },
  { value: 'arial', label: 'Arial' },
  { value: 'verdana', label: 'Verdana' },
  { value: 'helvetica', label: 'Helvetica' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'cursive', label: 'Cursive' },
  { value: 'monospace', label: 'Monospace' },
];
