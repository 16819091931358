import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '.././../../assets/icons';

export default ({ styleData }) => {
  const list = [
    'Agree to Cookie Policy',
    'Agree to Privacy Policy',
    'Agree to Terms of Service',
  ];

  const textStyling = {
    color: styleData.text.color,
    fontSize: styleData.text.fontSize,
    textAlign: styleData.text.textAlign,
    lineHeight: styleData.text.lineHeight,
    fontFamily: styleData.text.fontFamily.value,
    fontWeight: styleData.text.bold && 'bold',
    fontStyle: styleData.text.italic && 'italic',
    textDecoration: styleData.text.underline && 'underline',
  };

  return (
    <div className='flex flex-col items-center'>
      {list.map((item) => (
        <div
          key={item}
          className='w-10/12 my-2 flex justify-between items-center'
        >
          <div className='flex items-center'>
            <FontAwesomeIcon icon={icons.checkBox} color='blue' size='lg' />
            <div className='w-52 ml-5'>
              <p style={textStyling}>{item}</p>
            </div>
          </div>
          <p className='px-4 py-0.5 bg-white text-gray-500 text-sm filter drop-shadow-lg rounded-full'>
            Read
          </p>
        </div>
      ))}
    </div>
  );
};
