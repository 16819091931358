import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryDefaultImage } from '../../../assets/images';
import * as icons from '../../../assets/icons';

export default () => (
  <div className='mt-4 flex justify-center items-center'>
    <div className='w-11/12 bg-white filter drop-shadow-lg rounded-lg'>
      <div className='flex justify-between border-b-2'>
        <div className='flex'>
          <img
            alt='item'
            src={CategoryDefaultImage}
            className='w-28 h-28 -ml-1.5 -mt-1 -mb-2'
          />
          <div className='ml-4 flex flex-col justify-center'>
            <p className='mb-2'>Title</p>
            <p>0.00 $</p>
          </div>
        </div>
        <FontAwesomeIcon className='mt-2 mr-3' icon={icons.close} size='lg' />
      </div>

      <div className='h-20 mx-3 flex flex-col justify-center'>
        <div className='flex items-center'>
          <p className='w-3/5 text-lg'>Quantity</p>
          <div className='w-2/5 mx-2 flex justify-between items-center'>
            <FontAwesomeIcon icon={icons.plus} size='lg' />
            <p className='text-lg'>1</p>
            <FontAwesomeIcon icon={icons.minus} size='lg' />
          </div>
        </div>

        <div className='mt-2 flex justify-end items-center'>
          <FontAwesomeIcon icon={icons.heart} className='mr-7' />
          <FontAwesomeIcon icon={icons.cart} className='mr-3' />
        </div>
      </div>
    </div>
  </div>
);
