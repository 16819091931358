import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.paymentMethods, action) => {
  switch (action.type) {
    case types.SET_PAYMENT_METHODS_STYLING: {
      let paymentMethods = _.cloneDeep(state);

      paymentMethods = action.payload;

      return paymentMethods;
    }
    case types.SET_PAYMENT_METHODS_ORDER: {
      let paymentMethods = _.cloneDeep(state);

      paymentMethods.order = action.payload;

      return paymentMethods;
    }
    default:
      return state;
  }
};
