import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default () => {
  const [selectedOption, setSelectedOption] = useState('custom');

  const onValueChange = (value) => {
    setSelectedOption(value);
  };

  const data = [
    { light: true, icon: false, type: 'light' },
    { light: false, icon: false, type: 'dark' },
    { light: true, icon: true, type: 'lightIcon' },
    { light: false, icon: true, type: 'darkIcon' },
    { light: false, icon: false, type: 'custom' },
  ];

  return (
    <div className='grid grid-cols-2'>
      {data &&
        data.map((item, index) => {
          return (
            <label key={index} className='w-60 mb-4 flex flex-col items-center'>
              {item.type === 'custom' ? (
                <div className='my-3 text-sm'>CUSTOM</div>
              ) : (
                <div
                  className={`w-60 h-8 mb-2 filter drop-shadow-lg ${
                    item.light ? 'bg-white' : 'bg-gray-500'
                  } rounded-full flex items-center`}
                >
                  {item.icon && (
                    <FontAwesomeIcon
                      icon={icons.search}
                      className='ml-4'
                      color={item.light ? 'gray' : 'white'}
                    />
                  )}
                  <p
                    className={` text-sm ${
                      item.light ? 'text-gray-500' : 'text-white'
                    } ${item.icon ? 'ml-2' : 'ml-5'}`}
                  >
                    Search
                  </p>
                </div>
              )}

              <input
                type='radio'
                style={{ height: '18px', width: '18px' }}
                checked={selectedOption === item.type}
                onChange={() => onValueChange(item.type)}
              />
            </label>
          );
        })}
    </div>
  );
};
