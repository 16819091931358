import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.homeScreen, action) => {
  switch (action.type) {
    case types.SET_HOMESCREEN_STYLING: {
      let homeScreen = _.cloneDeep(state);

      homeScreen = action.payload;

      return homeScreen;
    }
    case types.SET_HOMESCREEN_ORDER: {
      let homeScreen = _.cloneDeep(state);

      homeScreen.order = action.payload;

      return homeScreen;
    }
    default:
      return state;
  }
};
