import request from './request';

export const fetchMobileComponent = (appId, name) => {
  return request.get(`/apps/${appId}/mobile_components/${name}`);
};

export const updateMobileComponent = (appId, name, mobileComponent) => {
  return request.put(
    `/apps/${appId}/mobile_components/${name}`,
    mobileComponent
  );
};
