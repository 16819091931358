import React from 'react';
import {
  Header,
  ItemCardFavourites,
  BottomNavigationBar,
} from '../../components';

export default () => {
  return (
    <div>
      <Header title='Favourites' color='white' />

      {[1, 2].map((index) => (
        <ItemCardFavourites key={index} />
      ))}

      <BottomNavigationBar />
    </div>
  );
};
