import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default ({ title, color }) => {
  return (
    <div
      className={`flex justify-between items-center px-5 pt-10 pb-2 ${
        color === 'white' && 'filter drop-shadow-lg'
      }`}
      style={{ backgroundColor: color }}
    >
      <FontAwesomeIcon
        icon={icons.bars}
        color={`${color === 'white' ? 'black' : 'white'}`}
      />
      <p className={`${color === 'white' ? 'text-black' : 'text-white'}`}>
        {title ? title : 'Name'}
      </p>
      <FontAwesomeIcon icon={icons.cart} color='white' />
    </div>
  );
};
