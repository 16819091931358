import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/address.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
  AlignmentEditor,
  TitleStyle,
  AddressCardStyle,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

const defaultStyle = {
  addressCard: {
    alignment: 'center',
    width: 330,
    height: 130,
  },
};

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const addressScreen = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setAddressScreenStyling(addressScreen));
  };

  const title = {
    visibility: addressScreen.title.visibility,
    selectedFont: addressScreen.title.text.fontFamily,
    fontSize: addressScreen.title.text.fontSize,
    bold: addressScreen.title.text.bold,
    italic: addressScreen.title.text.italic,
    underline: addressScreen.title.text.underline,
    textAlign: addressScreen.title.text.textAlign,
    lineHeight: addressScreen.title.text.lineHeight,
    color: addressScreen.title.text.color,
    borderVisibility: addressScreen.title.design.borderVisibility,
    borderColor: addressScreen.title.design.borderColor,
    fillVisibility: addressScreen.title.design.fillVisibility,
    fillColor: addressScreen.title.design.fillColor,
    shadow: addressScreen.title.design.shadow,
    borderRadius: addressScreen.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      addressScreen.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      addressScreen.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      addressScreen.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      addressScreen.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      addressScreen.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      addressScreen.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      addressScreen.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      addressScreen.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      addressScreen.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      addressScreen.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      addressScreen.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      addressScreen.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      addressScreen.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      addressScreen.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      addressScreen.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const addressCard = {
    selectedFont: addressScreen.addressCard.text.fontFamily,
    fontSize: addressScreen.addressCard.text.fontSize,
    bold: addressScreen.addressCard.text.bold,
    italic: addressScreen.addressCard.text.italic,
    underline: addressScreen.addressCard.text.underline,
    textAlign: addressScreen.addressCard.text.textAlign,
    lineHeight: addressScreen.addressCard.text.lineHeight,
    color: addressScreen.addressCard.text.color,
    borderVisibility: addressScreen.addressCard.design.entireCard.borderVisibility,
    borderColor: addressScreen.addressCard.design.entireCard.borderColor,
    fillVisibility: addressScreen.addressCard.design.entireCard.fillVisibility,
    fillColor: addressScreen.addressCard.design.entireCard.fillColor,
    shadow: addressScreen.addressCard.design.entireCard.shadow,
    borderRadius: addressScreen.addressCard.design.entireCard.borderRadius,
    alignment: addressScreen.addressCard.design.size.alignment,
    width: addressScreen.addressCard.design.size.width,
    height: addressScreen.addressCard.design.size.height,
  };

  const handleAddressCard = {
    fontChange: (font) => {
      addressScreen.addressCard.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      addressScreen.addressCard.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      addressScreen.addressCard.text.bold = !addressCard.bold;
      setStyling();
    },
    italicClick: () => {
      addressScreen.addressCard.text.italic = !addressCard.italic;
      setStyling();
    },
    underlineClick: () => {
      addressScreen.addressCard.text.underline = !addressCard.underline;
      setStyling();
    },
    textAlign: (value) => {
      addressScreen.addressCard.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      addressScreen.addressCard.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      addressScreen.addressCard.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      addressScreen.addressCard.design.entireCard.borderVisibility = !addressCard.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      addressScreen.addressCard.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      addressScreen.addressCard.design.entireCard.fillVisibility = !addressCard.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      addressScreen.addressCard.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      addressScreen.addressCard.design.entireCard.shadow = !addressCard.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      addressScreen.addressCard.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      addressScreen.addressCard.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      addressScreen.addressCard.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      addressScreen.addressCard.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      addressScreen.addressCard.design.size = defaultStyle.addressCard;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerAddressCard = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={addressCard.borderVisibility}
          setBorderVisibility={handleAddressCard.borderVisibility}
          borderColor={addressCard.borderColor}
          setBorderColor={handleAddressCard.borderColor}
          fillVisibility={addressCard.fillVisibility}
          setFillVisibility={handleAddressCard.fillVisibility}
          fillColor={addressCard.fillColor}
          setFillColor={handleAddressCard.fillColor}
          shadowVisibilty={addressCard.shadow}
          setShadowVisibility={handleAddressCard.shadow}
          borderRadius={addressCard.borderRadius}
          setBorderRadius={handleAddressCard.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={addressCard.alignment}
          onRightClick={() => handleAddressCard.elementAlign('end')}
          onCenterClick={() => handleAddressCard.elementAlign('center')}
          onLeftClick={() => handleAddressCard.elementAlign('start')}
          width={addressCard.width}
          setElementWidth={handleAddressCard.width}
          height={addressCard.height}
          setElementHeight={handleAddressCard.height}
          onResetPress={handleAddressCard.resetSize}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Address Card',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={addressCard.selectedFont}
              onFontChange={handleAddressCard.fontChange}
              fontSize={addressCard.fontSize}
              onFontSizeChange={handleAddressCard.fontSize}
              bold={addressCard.bold}
              italic={addressCard.italic}
              underline={addressCard.underline}
              onBoldClick={handleAddressCard.boldClick}
              onItalicClick={handleAddressCard.italicClick}
              onUnderlineClick={handleAddressCard.underlineClick}
              textAlign={addressCard.textAlign}
              onLeftClick={() => handleAddressCard.textAlign('left')}
              onCenterClick={() => handleAddressCard.textAlign('center')}
              onRightClick={() => handleAddressCard.textAlign('right')}
              onJustifyClick={() => handleAddressCard.textAlign('justify')}
              lineHeight={addressCard.lineHeight}
              onLineHeightChange={handleAddressCard.lineHeight}
              color={addressCard.color}
              setColor={handleAddressCard.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerAddressCard} />,
        },
        {
          heading: 'Style',
          component: <AddressCardStyle />,
        },
      ],
    },
  ];
};
