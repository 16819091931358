import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectedColor } from '../../../helpers/colors.helper';
import { ProductDefaultImage } from '../../../assets/images';
import * as icons from '../../../assets/icons';

export default ({ styleData }) => {
  const height = {
    1: 180,
    2: 170,
    3: 130,
  };

  const itemCardStyling = {
    // width: styleData.design.size.width,
    // height: styleData.design.size.height,
    height: height[styleData.category.itemsPerRow],
    borderColor: styleData.design.entireCard.borderColor,
    borderRadius: styleData.design.entireCard.borderRadius,
  };

  const backgroundImage = {
    borderTopLeftRadius: styleData.design.entireCard.borderRadius,
    borderTopRightRadius: styleData.design.entireCard.borderRadius,
    backgroundImage: `url(${ProductDefaultImage})`,
    backgroundPosition: 'center',
  };

  const imageOverlay = {
    borderTopLeftRadius: styleData.design.entireCard.borderRadius,
    borderTopRightRadius: styleData.design.entireCard.borderRadius,
    backgroundColor: styleData.design.entireCard.fillVisibility
      ? selectedColor(styleData.design.entireCard.fillColor)
      : 'transparent',
  };

  const descriptionStyling = {
    backgroundColor: selectedColor(styleData.design.description.color),
    borderBottomLeftRadius: styleData.design.entireCard.borderRadius,
    borderBottomRightRadius: styleData.design.entireCard.borderRadius,
  };

  const itemNameStyling = {
    fontSize:
      styleData.category.itemsPerRow === 3
        ? styleData.itemName.fontSize - 2
        : styleData.itemName.fontSize,
    color: styleData.itemName.color,
    textAlign: styleData.itemName.textAlign,
    lineHeight: styleData.itemName.lineHeight,
    fontFamily: styleData.itemName.fontFamily.value,
    fontWeight: styleData.itemName.bold && 'bold',
    fontStyle: styleData.itemName.italic && 'italic',
    textDecoration: styleData.itemName.underline && 'underline',
  };

  const priceStyling = {
    fontSize:
      styleData.category.itemsPerRow === 3
        ? styleData.price.fontSize - 2
        : styleData.price.fontSize,
    color: styleData.price.color,
    textAlign: styleData.price.textAlign,
    lineHeight: styleData.price.lineHeight,
    fontFamily: styleData.price.fontFamily.value,
    fontWeight: styleData.price.bold && 'bold',
    fontStyle: styleData.price.italic && 'italic',
    textDecoration: styleData.price.underline && 'underline',
  };

  return (
    <div
      style={itemCardStyling}
      className={`mb-4 mx-2 ${
        styleData.design.entireCard.shadow && 'filter drop-shadow-lg'
      } ${styleData.design.entireCard.borderVisibility && 'border-2'} `}
    >
      <div className='h-3/5' style={backgroundImage}>
        <div className='h-full' style={imageOverlay} />
      </div>

      <div
        style={descriptionStyling}
        className={`h-2/5 flex flex-col justify-center ${
          styleData.category.itemsPerRow ? 'px-1' : 'px-3'
        }`}
      >
        <p style={itemNameStyling}>Product Name</p>
        <p style={priceStyling}>0.00 $</p>
        <div
          className={`mx-3 flex justify-between items-center ${
            styleData.category.itemsPerRow !== 3 && 'mt-1'
          }`}
        >
          <FontAwesomeIcon icon={icons.heart} />
          <FontAwesomeIcon icon={icons.cart} />
        </div>
      </div>
    </div>
  );
};
