import request from './request';

export const fetchApps = (app_type = '') => {
  return request.get(`/apps?app_type=${app_type}`);
};

export const fetchApp = (appId) => {
  return request.get(`/apps/${appId}`);
};

export const updateApp = (appId, app) => {
  return request.put(`/apps/${appId}`, app);
};

export const createAppRequest = (app) => {
  return request.post('/apps', app);
};

export const deleteAppRequest = (appId) => {
  return request.delete(`/apps/${appId}`);
};

export const fetchAppPreview = (id, key) => {
  return request.get(`/apps/${id}/fetch_review?key=${key}`);
};
