import React from 'react';
import { useSelector } from 'react-redux';
import { selectedColor } from '../../../helpers/colors.helper';
import { Header, Title, InputPreview, Button } from '../../components';

export default () => {
  const colors = useSelector((state) => state.selectedApp.colors);
  const newPaymentScreen = useSelector((state) => state.newPaymentScreen);

  const headerColor = selectedColor(colors, 'primary');
  const title = newPaymentScreen.title.visibility;

  return (
    <div className='flex flex-col'>
      <Header color={headerColor} />

      <div className={`order-${newPaymentScreen.order[0]} ${title && 'mb-5'}`}>
        {title && (
          <Title text='New Payment Method' styleData={newPaymentScreen.title} />
        )}
      </div>
      <div className={`order-${newPaymentScreen.order[1]}`}>
        <InputPreview text='Card Number' styleData={newPaymentScreen.cardNumber} />
      </div>
      <div className={`order-${newPaymentScreen.order[2]}`}>
        <InputPreview text='Name on Card' styleData={newPaymentScreen.nameOnCard} />
      </div>
      <div className={`order-${newPaymentScreen.order[3]}`}>
        <InputPreview text='CVC / CVV' styleData={newPaymentScreen.cvcInput} />
      </div>
      <div
        className={`order-${newPaymentScreen.order[4]} mx-6 flex justify-between items-center`}
      >
        <p className='ml-2 text-gray-500'>Expiration</p>
        <InputPreview text='MM' styleData={newPaymentScreen.expirationDate} />
        <InputPreview text='YY' styleData={newPaymentScreen.expirationDate} />
      </div>
      <div className={`order-${newPaymentScreen.order[5]} mt-10`}>
        <Button text='Save' styleData={newPaymentScreen.button} />
      </div>
    </div>
  );
};
