import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryDefaultImage } from '../../../assets/images';
import * as icons from '../../../assets/icons';

export default ({ styleData }) => {
  const textStyling = {
    color: styleData.text.color,
    fontSize: styleData.text.fontSize,
    textAlign: styleData.text.textAlign,
    lineHeight: styleData.text.lineHeight,
    fontFamily: styleData.text.fontFamily.value,
    fontWeight: styleData.text.bold && 'bold',
    fontStyle: styleData.text.italic && 'italic',
    textDecoration: styleData.text.underline && 'underline',
  };

  const backgroundImage = {
    backgroundImage: `url(${CategoryDefaultImage})`,
    backgroundPosition: 'center',
  };

  return (
    <div className='flex justify-center'>
      <div className='w-11/12 mt-5 flex justify-between items-center'>
        <div className='flex items-center'>
          <FontAwesomeIcon
            icon={icons.chevronLeft}
            className='mb-6 mr-3'
            size='lg'
          />
          <div>
            <div className='w-32 h-32 rounded-lg' style={backgroundImage} />
            <p className='mt-1' style={textStyling}>
              Category Name
            </p>
          </div>
        </div>

        <div className='flex items-center'>
          <div>
            <div className='w-32 h-32 rounded-lg' style={backgroundImage} />
            <p className='mt-1' style={textStyling}>
              Category Name
            </p>
          </div>
          <FontAwesomeIcon
            icon={icons.chevronRight}
            className='mb-6 ml-3'
            size='lg'
          />
        </div>
      </div>
    </div>
  );
};
