import React from 'react';

export default ({ text, selected }) => (
  <div className='mx-6 mt-2 flex items-center'>
    <div
      className={`h-5 w-5 border-2 rounded-full flex justify-center items-center ${
        selected ? 'border-blue-500' : 'border-gray-500'
      }`}
    >
      <div
        className={`h-2.5 w-2.5 rounded-full ${selected && 'bg-blue-500'}`}
      />
    </div>
    <p className='ml-10'>{text}</p>
  </div>
);
