import React from 'react';
import { InputPreview } from '../../components';

export default ({ onSaveClick }) => {
  const inputStyling = (size) => {
    return {
      text: {
        fontFamily: { value: 'sans-serif', label: 'Sans Serif' },
        fontStyle: '',
        color: '#9DA3B0',
        fontSize: 15,
        bold: false,
        italic: false,
        underline: false,
        textAlign: 'left',
        lineHeight: 1.5,
      },
      design: {
        entireCard: {
          borderVisibility: true,
          borderColor: '#9DA3B0',
          fillVisibility: true,
          fillColor: '#FFF',
          shadow: true,
          borderRadius: 20,
        },
        size: {
          alignment: 'center',
          width: size === 'short' ? 70 : 330,
          height: 35,
        },
      },
    };
  };

  return (
    <div>
      <p className='mt-10 text-lg text-center'>Modify Profile</p>
      <p className='mt-3 ml-8 mb-1'>Primary Payment Method</p>

      <InputPreview text='Card number' styleData={inputStyling()} />
      <InputPreview text='Name on card' styleData={inputStyling()} />
      <InputPreview text='CVC / CVV' styleData={inputStyling()} />
      <div className='mx-2 flex justify-evenly items-center'>
        <p>Expiration</p>
        <InputPreview text='MM' styleData={inputStyling('short')} />
        <InputPreview text='YY' styleData={inputStyling('short')} />
      </div>

      <div className='w-full absolute bottom-20'>
        <div className='flex justify-center items-center'>
          <p
            className='px-5 py-1 text-blue-500 bg-white filter drop-shadow-lg rounded-md cursor-pointer'
            onClick={onSaveClick}
          >
            Save
          </p>
        </div>
      </div>
    </div>
  );
};
