import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryDefaultImage } from '../../../assets/images';
import * as icons from '../../../assets/icons';

export default ({ styleData }) => {
  const titleStyling = {
    color: styleData.itemName.color,
    fontSize: styleData.itemName.fontSize,
    textAlign: styleData.itemName.textAlign,
    lineHeight: styleData.itemName.lineHeight,
    fontFamily: styleData.itemName.fontFamily.value,
    fontWeight: styleData.itemName.bold && 'bold',
    fontStyle: styleData.itemName.italic && 'italic',
    textDecoration: styleData.itemName.underline && 'underline',
  };

  const priceStyling = {
    color: styleData.price.color,
    fontSize: styleData.price.fontSize,
    textAlign: styleData.price.textAlign,
    lineHeight: styleData.price.lineHeight,
    fontFamily: styleData.price.fontFamily.value,
    fontWeight: styleData.price.bold && 'bold',
    fontStyle: styleData.price.italic && 'italic',
    textDecoration: styleData.price.underline && 'underline',
  };

  const descriptionStyling = {
    color: styleData.description.color,
    fontSize: styleData.tdescriptionfontSize,
    textAlign: styleData.description.textAlign,
    lineHeight: styleData.description.lineHeight,
    fontFamily: styleData.description.fontFamily.value,
    fontWeight: styleData.description.bold && 'bold',
    fontStyle: styleData.description.italic && 'italic',
    textDecoration: styleData.description.underline && 'underline',
  };

  const backgroundStyling = {
    borderColor: styleData.design.borderColor,
    borderRadius: styleData.design.borderRadius,
    backgroundColor: styleData.design.fillVisibility
      ? styleData.design.fillColor
      : 'transparent',
  };

  const imageStyling = {
    borderRadius: styleData.design.borderRadius,
    backgroundImage: `url(${CategoryDefaultImage})`,
    backgroundPosition: 'center',
  };

  return (
    <div
      style={backgroundStyling}
      className={`m-5 bg-white ${
        styleData.design.borderVisibility && 'border-2'
      } ${styleData.design.shadow && 'filter drop-shadow-lg'}`}
    >
      <div className='h-48' style={imageStyling} />

      <div className='mt-2 flex justify-center items-center'>
        <div className='w-2.5 h-2.5 mx-1 rounded-full bg-blue-500' />
        <div className='w-2 h-2 mx-1 rounded-full border border-blue-500' />
        <div className='w-2 h-2 mx-1 rounded-full border border-blue-500' />
        <div className='w-2 h-2 mx-1 rounded-full border border-blue-500' />
        <div className='w-2 h-2 mx-1 rounded-full border border-blue-500' />
      </div>

      <div className='mx-5'>
        <div className='my-3'>
          <div className='flex justify-between items-center'>
            <p className='w-11/12' style={titleStyling}>
              Title
            </p>
            <FontAwesomeIcon icon={icons.heart} />
          </div>
          <p className='w-11/12' style={priceStyling}>
            0.00 $
          </p>
        </div>
        <hr className='border-black' />
        <div className=' my-3 flex justify-between items-center'>
          <p>Quantity</p>
          <div className='w-2/5 flex justify-between items-center'>
            <FontAwesomeIcon icon={icons.plus} />
            <p>1</p>
            <FontAwesomeIcon icon={icons.minus} />
          </div>
        </div>
        <hr className='border-black' />
        <div className='my-3'>
          <p style={descriptionStyling}>Description</p>
          <p style={descriptionStyling}>
            lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non
            congue turpis. Donec ac felis molestie dolor id.
          </p>
        </div>
      </div>
    </div>
  );
};
