import React from 'react';
import { ColorPicker, RangeSelector } from '../common';

export default ({ overlayColor, setOverlayColor, setOpacity }) => {
  return (
    <div className='flex'>
      <div className='w-2/5 flex flex-col items-center'>
        <div>
          <p className='text-sm mb-2'>Fill</p>
          <ColorPicker color={overlayColor.rgb} setColor={setOverlayColor} />
        </div>
      </div>

      <div className='w-2/5'>
        <p className='text-sm mb-3'>Opacity</p>
        <RangeSelector value={overlayColor.rgb.a} setValue={setOpacity} />
      </div>
      <p className='ml-5 mt-5'>{Math.trunc(overlayColor.rgb.a * 100)} %</p>
    </div>
  );
};
