import { toast } from 'react-toastify';
import * as types from '../constants/actions';
import { fetchMobileTemplates } from '../api/templates.api';

const setMobileTemplates = (payload) => ({
  type: types.SET_MOBILE_TEMPLATES,
  payload: payload,
});

export const getMobileTemplates = () => {
  return (dispatch) => {
    fetchMobileTemplates()
      .then((res) => dispatch(setMobileTemplates(res.data)))
      .catch(() => toast.error('unable to get mobile templates'));
  };
};
