import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.selectedApp, action) => {
  switch (action.type) {
    case types.SET_APP_COLORS: {
      let selectedApp = _.cloneDeep(state);

      selectedApp.colors = action.payload;

      return selectedApp;
    }
    case types.SET_APP_TEMPLATE: {
      let selectedApp = _.cloneDeep(state);

      selectedApp.templateId = action.payload;

      return selectedApp;
    }
    default:
      return state;
  }
};
