import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../../assets/icons';

export default () => {
  return (
    <div className='w-24 h-24 mr-3 border border-black rounded flex justify-center items-center cursor-pointer'>
      <FontAwesomeIcon icon={icons.upload} />
    </div>
  );
};
