import * as types from '../../constants/actions.js';

export const setNewPaymentScreenStyling = (payload) => ({
  type: types.SET_NEW_PAYMENT_SCREEN_STYLING,
  payload: payload,
});

export const setNewPaymentScreenOrder = (payload) => ({
  type: types.SET_NEW_PAYMENT_SCREEN_ORDER,
  payload: payload,
});
