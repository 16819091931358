import initialState from './initialState';
import * as types from '../constants/actions.js';
import _ from 'lodash';

export default (state = initialState.addressScreen, action) => {
  switch (action.type) {
    case types.SET_ADDRESS_SCREEN_STYLING: {
      let addressScreen = _.cloneDeep(state);

      addressScreen = action.payload;

      return addressScreen;
    }
    case types.SET_ADDRESS_SCREEN_ORDER: {
      let addressScreen = _.cloneDeep(state);

      addressScreen.order = action.payload;

      return addressScreen;
    }
    default:
      return state;
  }
};
