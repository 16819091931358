import React from 'react';
import { ImageUploader } from '../../components/common';

export default ({
  editable,
  title,
  setTitle,
  shortDescription,
  setShortDescription,
  description,
  setDescription,
  iconSrc,
  setIconSrc,
  setIconURL,
}) => {
  return (
    <>
      <div className='w-full mb-4 flex'>
        <div className='w-1/5'>
          <p className='text-md'>Title</p>
          <p className='text-xs'>English - en - GB</p>
        </div>
        <div className='w-3/5 ml-10'>
          <input
            type='text'
            placeholder='Title'
            value={title}
            maxLength={50}
            minLength={3}
            required={true}
            disabled={!editable}
            onChange={setTitle}
            className='w-full border-b-2 focus:outline-none'
          />
          <p className='text-right text-sm'>{title.length} / 50</p>
        </div>
      </div>

      <div className='w-full mb-4 flex'>
        <div className='w-1/5'>
          <p className='text-md'>Short Description</p>
          <p className='text-xs'>English - en - GB</p>
        </div>
        <div className='w-3/5 ml-10'>
          <input
            type='text'
            placeholder='Short Description'
            value={shortDescription}
            maxLength={80}
            required={true}
            disabled={!editable}
            onChange={setShortDescription}
            className='w-full border-b-2 focus:outline-none'
          />
          <p className='text-right text-sm'>{shortDescription.length} / 80</p>
        </div>
      </div>

      <div className='w-full mb-4 flex'>
        <div className='w-1/5'>
          <p className='text-md'>Description</p>
          <p className='text-xs'>English - en - GB</p>
        </div>
        <div className='w-3/5 ml-10'>
          <textarea
            type='textarea'
            placeholder='Description'
            value={description}
            maxLength={4000}
            rows={4}
            required={true}
            disabled={!editable}
            onChange={setDescription}
            className='w-full border-b-2 focus:outline-none'
          />
          <p className='text-right text-sm'>{description.length} / 4000</p>
        </div>
      </div>

      <div className='w-full mb-4 flex'>
        <div className='w-1/5'>
          <p className='mb-1'>Hi-res icon</p>
          <p className='text-xs'>512 x 512</p>
          <p className='text-xs'>32bit PNG</p>
        </div>
        <div className='w-3/5 ml-10'>
          <ImageUploader
            src={iconSrc}
            alt='app-icon'
            id='app-icon'
            height={512}
            width={512}
            editable={editable}
            setSrc={setIconSrc}
            setURL={setIconURL}
          />
        </div>
      </div>
    </>
  );
};
