import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getApp, setAppColors } from '../../actions/selectedApp.actions';
import { getMobileComponent } from '../../actions/mobileComponents.actions';
import { updateApp } from '../../api/apps.api';
import { RouterHelper } from '../../helpers/router.helper';
import { TopBar, ColorEditor } from '../../components';
import { HomeScreenPreview } from '../../AppPreview/screens';
import { PreviewPanel } from '../../AppPreview/components';
import { Button } from '../../AppEditor/components/common';

export default () => {
  const history = useHistory();
  const { appId } = useParams();
  const dispatch = useDispatch();
  const colors = useSelector((state) => state.selectedApp.colors);
  const homeScreen = useSelector((state) => state.homeScreen);
  const [selectedConfig, setSelectedConfig] = useState('primary');

  useEffect(() => {
    dispatch(getApp(appId));
    dispatch(getMobileComponent(appId, 'home_screen'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColorChange = (color) => {
    let value = _.cloneDeep(colors);

    selectedConfig === 'primary'
      ? (value.primary.rgb = color.rgb)
      : (value.secondary.rgb = color.rgb);

    dispatch(setAppColors(value));
  };

  const handleManualChange = (e) => {
    let value = _.cloneDeep(colors);
    value[selectedConfig].rgb[e.target.name] = e.target.value;

    dispatch(setAppColors(value));
  };

  const saveColors = () => {
    const data = {
      app: {
        configuration: {
          colors: colors,
        },
      },
    };

    updateApp(appId, data)
      .then(() => {
        history.push(RouterHelper.appDesignPath(appId));
        toast.success('Colors Saved Successfully!');
      })
      .catch(() => toast.error('unable to save colors'));
  };

  const submitApp = () => {
    updateApp(appId, { status: 'submitted' })
      .then((res) => {
        res.data.message && toast.warning(res.data.message);
        _.isEmpty(res.data.errors) &&
          history.push(RouterHelper.appReviewPath(appId));
      })
      .catch(() => toast.error('unable to submit'));
  };

  return (
    <>
      <TopBar appId={appId} />

      <div className='my-5 grid grid-cols-2'>
        <div className='flex flex-col items-end'>
          <div className='w-4/5'>
            <div className='w-full mb-5 flex justify-between items-center'>
              <p className='text-4xl'>Colours</p>
              <Button.Transparent text='SAVE' onClick={saveColors} />
            </div>

            {!_.isEmpty(colors) && (
              <ColorEditor
                colors={colors}
                selectedConfig={selectedConfig}
                setPrimary={() => setSelectedConfig('primary')}
                setSecondary={() => setSelectedConfig('secondary')}
                handleColorChange={handleColorChange}
                handleManualChange={handleManualChange}
              />
            )}
          </div>
          <div className='w-4/5 flex justify-center mt-10'>
            <Button.Transparent text='Submit' onClick={submitApp} />
          </div>
        </div>

        <div className='flex justify-center'>
          <PreviewPanel>
            {!_.isEmpty(homeScreen) && !_.isEmpty(colors) && (
              <HomeScreenPreview />
            )}
          </PreviewPanel>
        </div>
      </div>
    </>
  );
};
