import React from 'react';
import _ from 'lodash';
import store from '../../../config/store.js';
import * as actions from '../../actions/newAddress.actions.js';
import {
  TextEditor,
  DesignChanger,
  DesignEditor,
  AlignmentEditor,
  TitleStyle,
  InputStyle,
  ButtonStyle,
} from '../../components';
import { fontFamilies } from '../../../constants/fontFamilies.js';

const defaultStyle = {
  input: {
    alignment: 'center',
    width: 330,
    height: 32,
  },
  button: {
    alignment: 'center',
    width: 100,
    height: 35,
  },
};

export const editorMapping = (styleData) => {
  const dispatch = store.dispatch;
  const newAddressScreen = _.cloneDeep(styleData);

  const setStyling = () => {
    dispatch(actions.setNewAddressScreenStyling(newAddressScreen));
  };

  const title = {
    visibility: newAddressScreen.title.visibility,
    selectedFont: newAddressScreen.title.text.fontFamily,
    fontSize: newAddressScreen.title.text.fontSize,
    bold: newAddressScreen.title.text.bold,
    italic: newAddressScreen.title.text.italic,
    underline: newAddressScreen.title.text.underline,
    textAlign: newAddressScreen.title.text.textAlign,
    lineHeight: newAddressScreen.title.text.lineHeight,
    color: newAddressScreen.title.text.color,
    borderVisibility: newAddressScreen.title.design.borderVisibility,
    borderColor: newAddressScreen.title.design.borderColor,
    fillVisibility: newAddressScreen.title.design.fillVisibility,
    fillColor: newAddressScreen.title.design.fillColor,
    shadow: newAddressScreen.title.design.shadow,
    borderRadius: newAddressScreen.title.design.borderRadius,
  };

  const handleTitle = {
    visibility: () => {
      newAddressScreen.title.visibility = !title.visibility;
      setStyling();
    },
    fontChange: (font) => {
      newAddressScreen.title.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.title.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.title.text.bold = !title.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.title.text.italic = !title.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.title.text.underline = !title.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.title.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.title.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.title.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.title.design.borderVisibility = !title.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.title.design.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.title.design.fillVisibility = !title.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.title.design.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.title.design.shadow = !title.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.title.design.borderRadius = Number(e.target.value);
      setStyling();
    },
  };

  const firstName = {
    selectedFont: newAddressScreen.firstName.text.fontFamily,
    fontSize: newAddressScreen.firstName.text.fontSize,
    bold: newAddressScreen.firstName.text.bold,
    italic: newAddressScreen.firstName.text.italic,
    underline: newAddressScreen.firstName.text.underline,
    textAlign: newAddressScreen.firstName.text.textAlign,
    lineHeight: newAddressScreen.firstName.text.lineHeight,
    color: newAddressScreen.firstName.text.color,
    borderVisibility: newAddressScreen.firstName.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.firstName.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.firstName.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.firstName.design.entireCard.fillColor,
    shadow: newAddressScreen.firstName.design.entireCard.shadow,
    borderRadius: newAddressScreen.firstName.design.entireCard.borderRadius,
    alignment: newAddressScreen.firstName.design.size.alignment,
    width: newAddressScreen.firstName.design.size.width,
    height: newAddressScreen.firstName.design.size.height,
  };

  const handleFirstName = {
    fontChange: (font) => {
      newAddressScreen.firstName.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.firstName.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.firstName.text.bold = !firstName.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.firstName.text.italic = !firstName.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.firstName.text.underline = !firstName.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.firstName.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.firstName.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.firstName.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.firstName.design.entireCard.borderVisibility = !firstName.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.firstName.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.firstName.design.entireCard.fillVisibility = !firstName.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.firstName.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.firstName.design.entireCard.shadow = !firstName.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.firstName.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.firstName.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.firstName.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.firstName.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.firstName.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const lastName = {
    selectedFont: newAddressScreen.lastName.text.fontFamily,
    fontSize: newAddressScreen.lastName.text.fontSize,
    bold: newAddressScreen.lastName.text.bold,
    italic: newAddressScreen.lastName.text.italic,
    underline: newAddressScreen.lastName.text.underline,
    textAlign: newAddressScreen.lastName.text.textAlign,
    lineHeight: newAddressScreen.lastName.text.lineHeight,
    color: newAddressScreen.lastName.text.color,
    borderVisibility: newAddressScreen.lastName.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.lastName.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.lastName.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.lastName.design.entireCard.fillColor,
    shadow: newAddressScreen.lastName.design.entireCard.shadow,
    borderRadius: newAddressScreen.lastName.design.entireCard.borderRadius,
    alignment: newAddressScreen.lastName.design.size.alignment,
    width: newAddressScreen.lastName.design.size.width,
    height: newAddressScreen.lastName.design.size.height,
  };

  const handleLastName = {
    fontChange: (font) => {
      newAddressScreen.lastName.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.lastName.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.lastName.text.bold = !lastName.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.lastName.text.italic = !lastName.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.lastName.text.underline = !lastName.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.lastName.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.lastName.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.lastName.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.lastName.design.entireCard.borderVisibility = !lastName.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.lastName.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.lastName.design.entireCard.fillVisibility = !lastName.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.lastName.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.lastName.design.entireCard.shadow = !lastName.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.lastName.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.lastName.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.lastName.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.lastName.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.lastName.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const email = {
    selectedFont: newAddressScreen.email.text.fontFamily,
    fontSize: newAddressScreen.email.text.fontSize,
    bold: newAddressScreen.email.text.bold,
    italic: newAddressScreen.email.text.italic,
    underline: newAddressScreen.email.text.underline,
    textAlign: newAddressScreen.email.text.textAlign,
    lineHeight: newAddressScreen.email.text.lineHeight,
    color: newAddressScreen.email.text.color,
    borderVisibility: newAddressScreen.email.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.email.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.email.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.email.design.entireCard.fillColor,
    shadow: newAddressScreen.email.design.entireCard.shadow,
    borderRadius: newAddressScreen.email.design.entireCard.borderRadius,
    alignment: newAddressScreen.email.design.size.alignment,
    width: newAddressScreen.email.design.size.width,
    height: newAddressScreen.email.design.size.height,
  };

  const handleEmail = {
    fontChange: (font) => {
      newAddressScreen.email.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.email.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.email.text.bold = !email.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.email.text.italic = !email.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.email.text.underline = !email.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.email.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.email.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.email.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.email.design.entireCard.borderVisibility = !email.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.email.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.email.design.entireCard.fillVisibility = !email.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.email.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.email.design.entireCard.shadow = !email.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.email.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.email.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.email.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.email.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.email.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const country = {
    selectedFont: newAddressScreen.country.text.fontFamily,
    fontSize: newAddressScreen.country.text.fontSize,
    bold: newAddressScreen.country.text.bold,
    italic: newAddressScreen.country.text.italic,
    underline: newAddressScreen.country.text.underline,
    textAlign: newAddressScreen.country.text.textAlign,
    lineHeight: newAddressScreen.country.text.lineHeight,
    color: newAddressScreen.country.text.color,
    borderVisibility: newAddressScreen.country.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.country.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.country.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.country.design.entireCard.fillColor,
    shadow: newAddressScreen.country.design.entireCard.shadow,
    borderRadius: newAddressScreen.country.design.entireCard.borderRadius,
    alignment: newAddressScreen.country.design.size.alignment,
    width: newAddressScreen.country.design.size.width,
    height: newAddressScreen.country.design.size.height,
  };

  const handleCountry = {
    fontChange: (font) => {
      newAddressScreen.country.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.country.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.country.text.bold = !country.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.country.text.italic = !country.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.country.text.underline = !country.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.country.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.country.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.country.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.country.design.entireCard.borderVisibility = !country.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.country.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.country.design.entireCard.fillVisibility = !country.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.country.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.country.design.entireCard.shadow = !country.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.country.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.country.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.country.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.country.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.country.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const state = {
    selectedFont: newAddressScreen.state.text.fontFamily,
    fontSize: newAddressScreen.state.text.fontSize,
    bold: newAddressScreen.state.text.bold,
    italic: newAddressScreen.state.text.italic,
    underline: newAddressScreen.state.text.underline,
    textAlign: newAddressScreen.state.text.textAlign,
    lineHeight: newAddressScreen.state.text.lineHeight,
    color: newAddressScreen.state.text.color,
    borderVisibility: newAddressScreen.state.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.state.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.state.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.state.design.entireCard.fillColor,
    shadow: newAddressScreen.state.design.entireCard.shadow,
    borderRadius: newAddressScreen.state.design.entireCard.borderRadius,
    alignment: newAddressScreen.state.design.size.alignment,
    width: newAddressScreen.state.design.size.width,
    height: newAddressScreen.state.design.size.height,
  };

  const handleState = {
    fontChange: (font) => {
      newAddressScreen.state.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.state.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.state.text.bold = !state.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.state.text.italic = !state.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.state.text.underline = !state.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.state.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.state.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.state.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.state.design.entireCard.borderVisibility = !state.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.state.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.state.design.entireCard.fillVisibility = !state.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.state.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.state.design.entireCard.shadow = !state.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.state.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.state.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.state.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.state.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.state.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const address = {
    selectedFont: newAddressScreen.address.text.fontFamily,
    fontSize: newAddressScreen.address.text.fontSize,
    bold: newAddressScreen.address.text.bold,
    italic: newAddressScreen.address.text.italic,
    underline: newAddressScreen.address.text.underline,
    textAlign: newAddressScreen.address.text.textAlign,
    lineHeight: newAddressScreen.address.text.lineHeight,
    color: newAddressScreen.address.text.color,
    borderVisibility: newAddressScreen.address.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.address.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.address.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.address.design.entireCard.fillColor,
    shadow: newAddressScreen.address.design.entireCard.shadow,
    borderRadius: newAddressScreen.address.design.entireCard.borderRadius,
    alignment: newAddressScreen.address.design.size.alignment,
    width: newAddressScreen.address.design.size.width,
    height: newAddressScreen.address.design.size.height,
  };

  const handleAddress = {
    fontChange: (font) => {
      newAddressScreen.address.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.address.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.address.text.bold = !address.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.address.text.italic = !address.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.address.text.underline = !address.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.address.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.address.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.address.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.address.design.entireCard.borderVisibility = !address.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.address.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.address.design.entireCard.fillVisibility = !address.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.address.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.address.design.entireCard.shadow = !address.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.address.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.address.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.address.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.address.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.address.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const phone = {
    selectedFont: newAddressScreen.phone.text.fontFamily,
    fontSize: newAddressScreen.phone.text.fontSize,
    bold: newAddressScreen.phone.text.bold,
    italic: newAddressScreen.phone.text.italic,
    underline: newAddressScreen.phone.text.underline,
    textAlign: newAddressScreen.phone.text.textAlign,
    lineHeight: newAddressScreen.phone.text.lineHeight,
    color: newAddressScreen.phone.text.color,
    borderVisibility: newAddressScreen.phone.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.phone.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.phone.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.phone.design.entireCard.fillColor,
    shadow: newAddressScreen.phone.design.entireCard.shadow,
    borderRadius: newAddressScreen.phone.design.entireCard.borderRadius,
    alignment: newAddressScreen.phone.design.size.alignment,
    width: newAddressScreen.phone.design.size.width,
    height: newAddressScreen.phone.design.size.height,
  };

  const handlePhone = {
    fontChange: (font) => {
      newAddressScreen.phone.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.phone.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.phone.text.bold = !phone.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.phone.text.italic = !phone.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.phone.text.underline = !phone.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.phone.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.phone.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.phone.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.phone.design.entireCard.borderVisibility = !phone.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.phone.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.phone.design.entireCard.fillVisibility = !phone.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.phone.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.phone.design.entireCard.shadow = !phone.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.phone.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.phone.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.phone.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.phone.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.phone.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const city = {
    selectedFont: newAddressScreen.city.text.fontFamily,
    fontSize: newAddressScreen.city.text.fontSize,
    bold: newAddressScreen.city.text.bold,
    italic: newAddressScreen.city.text.italic,
    underline: newAddressScreen.city.text.underline,
    textAlign: newAddressScreen.city.text.textAlign,
    lineHeight: newAddressScreen.city.text.lineHeight,
    color: newAddressScreen.city.text.color,
    borderVisibility: newAddressScreen.city.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.city.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.city.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.city.design.entireCard.fillColor,
    shadow: newAddressScreen.city.design.entireCard.shadow,
    borderRadius: newAddressScreen.city.design.entireCard.borderRadius,
    alignment: newAddressScreen.city.design.size.alignment,
    width: newAddressScreen.city.design.size.width,
    height: newAddressScreen.city.design.size.height,
  };

  const handleCity = {
    fontChange: (font) => {
      newAddressScreen.city.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.city.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.city.text.bold = !city.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.city.text.italic = !city.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.city.text.underline = !city.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.city.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.city.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.city.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.city.design.entireCard.borderVisibility = !city.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.city.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.city.design.entireCard.fillVisibility = !city.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.city.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.city.design.entireCard.shadow = !city.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.city.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.city.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.city.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.city.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.city.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const zipCode = {
    selectedFont: newAddressScreen.zipCode.text.fontFamily,
    fontSize: newAddressScreen.zipCode.text.fontSize,
    bold: newAddressScreen.zipCode.text.bold,
    italic: newAddressScreen.zipCode.text.italic,
    underline: newAddressScreen.zipCode.text.underline,
    textAlign: newAddressScreen.zipCode.text.textAlign,
    lineHeight: newAddressScreen.zipCode.text.lineHeight,
    color: newAddressScreen.zipCode.text.color,
    borderVisibility: newAddressScreen.zipCode.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.zipCode.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.zipCode.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.zipCode.design.entireCard.fillColor,
    shadow: newAddressScreen.zipCode.design.entireCard.shadow,
    borderRadius: newAddressScreen.zipCode.design.entireCard.borderRadius,
    alignment: newAddressScreen.zipCode.design.size.alignment,
    width: newAddressScreen.zipCode.design.size.width,
    height: newAddressScreen.zipCode.design.size.height,
  };

  const handleZipCode = {
    fontChange: (font) => {
      newAddressScreen.zipCode.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.zipCode.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.zipCode.text.bold = !zipCode.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.zipCode.text.italic = !zipCode.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.zipCode.text.underline = !zipCode.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.zipCode.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.zipCode.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.zipCode.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.zipCode.design.entireCard.borderVisibility = !zipCode.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.zipCode.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.zipCode.design.entireCard.fillVisibility = !zipCode.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.zipCode.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.zipCode.design.entireCard.shadow = !zipCode.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.zipCode.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.zipCode.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.zipCode.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.zipCode.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.zipCode.design.size = defaultStyle.input;
      setStyling();
    },
  };

  const button = {
    selectedFont: newAddressScreen.button.text.fontFamily,
    fontSize: newAddressScreen.button.text.fontSize,
    bold: newAddressScreen.button.text.bold,
    italic: newAddressScreen.button.text.italic,
    underline: newAddressScreen.button.text.underline,
    textAlign: newAddressScreen.button.text.textAlign,
    lineHeight: newAddressScreen.button.text.lineHeight,
    color: newAddressScreen.button.text.color,
    borderVisibility: newAddressScreen.button.design.entireCard.borderVisibility,
    borderColor: newAddressScreen.button.design.entireCard.borderColor,
    fillVisibility: newAddressScreen.button.design.entireCard.fillVisibility,
    fillColor: newAddressScreen.button.design.entireCard.fillColor,
    shadow: newAddressScreen.button.design.entireCard.shadow,
    borderRadius: newAddressScreen.button.design.entireCard.borderRadius,
    alignment: newAddressScreen.button.design.size.alignment,
    width: newAddressScreen.button.design.size.width,
    height: newAddressScreen.button.design.size.height,
  };

  const handleButton = {
    fontChange: (font) => {
      newAddressScreen.button.text.fontFamily = font;
      setStyling();
    },
    fontSize: (e) => {
      newAddressScreen.button.text.fontSize = Number(e.target.value);
      setStyling();
    },
    boldClick: () => {
      newAddressScreen.button.text.bold = !button.bold;
      setStyling();
    },
    italicClick: () => {
      newAddressScreen.button.text.italic = !button.italic;
      setStyling();
    },
    underlineClick: () => {
      newAddressScreen.button.text.underline = !button.underline;
      setStyling();
    },
    textAlign: (value) => {
      newAddressScreen.button.text.textAlign = value;
      setStyling();
    },
    lineHeight: (e) => {
      newAddressScreen.button.text.lineHeight = e.target.value;
      setStyling();
    },
    colorChange: (value) => {
      newAddressScreen.button.text.color = value.hex;
      setStyling();
    },
    borderVisibility: () => {
      newAddressScreen.button.design.entireCard.borderVisibility = !button.borderVisibility;
      setStyling();
    },
    borderColor: (value) => {
      newAddressScreen.button.design.entireCard.borderColor = value.hex;
      setStyling();
    },
    fillVisibility: () => {
      newAddressScreen.button.design.entireCard.fillVisibility = !button.fillVisibility;
      setStyling();
    },
    fillColor: (value) => {
      newAddressScreen.button.design.entireCard.fillColor = value.hex;
      setStyling();
    },
    shadow: () => {
      newAddressScreen.button.design.entireCard.shadow = !button.shadow;
      setStyling();
    },
    borderRadius: (e) => {
      newAddressScreen.button.design.entireCard.borderRadius = Number(e.target.value);
      setStyling();
    },
    elementAlign: (value) => {
      newAddressScreen.button.design.size.alignment = value;
      setStyling();
    },
    width: (e) => {
      newAddressScreen.button.design.size.width = Number(e.target.value);
      setStyling();
    },
    height: (e) => {
      newAddressScreen.button.design.size.height = Number(e.target.value);
      setStyling();
    },
    resetSize: () => {
      newAddressScreen.button.design.size = defaultStyle.button;
      setStyling();
    },
  };

  const designChangerTitle = [
    {
      component: (
        <DesignEditor
          borderVisibility={title.borderVisibility}
          setBorderVisibility={handleTitle.borderVisibility}
          borderColor={title.borderColor}
          setBorderColor={handleTitle.borderColor}
          fillVisibility={title.fillVisibility}
          setFillVisibility={handleTitle.fillVisibility}
          fillColor={title.fillColor}
          setFillColor={handleTitle.fillColor}
          shadowVisibilty={title.shadow}
          setShadowVisibility={handleTitle.shadow}
          borderRadius={title.borderRadius}
          setBorderRadius={handleTitle.borderRadius}
        />
      ),
    },
  ];

  const designChangerFirstName = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={firstName.borderVisibility}
          setBorderVisibility={handleFirstName.borderVisibility}
          borderColor={firstName.borderColor}
          setBorderColor={handleFirstName.borderColor}
          fillVisibility={firstName.fillVisibility}
          setFillVisibility={handleFirstName.fillVisibility}
          fillColor={firstName.fillColor}
          setFillColor={handleFirstName.fillColor}
          shadowVisibilty={firstName.shadow}
          setShadowVisibility={handleFirstName.shadow}
          borderRadius={firstName.borderRadius}
          setBorderRadius={handleFirstName.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={firstName.alignment}
          onRightClick={() => handleFirstName.elementAlign('end')}
          onCenterClick={() => handleFirstName.elementAlign('center')}
          onLeftClick={() => handleFirstName.elementAlign('start')}
          width={firstName.width}
          setElementWidth={handleFirstName.width}
          height={firstName.height}
          setElementHeight={handleFirstName.height}
          onResetPress={handleFirstName.resetSize}
        />
      ),
    },
  ];

  const designChangerLastName = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={lastName.borderVisibility}
          setBorderVisibility={handleLastName.borderVisibility}
          borderColor={lastName.borderColor}
          setBorderColor={handleLastName.borderColor}
          fillVisibility={lastName.fillVisibility}
          setFillVisibility={handleLastName.fillVisibility}
          fillColor={lastName.fillColor}
          setFillColor={handleLastName.fillColor}
          shadowVisibilty={lastName.shadow}
          setShadowVisibility={handleLastName.shadow}
          borderRadius={lastName.borderRadius}
          setBorderRadius={handleLastName.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={lastName.alignment}
          onRightClick={() => handleLastName.elementAlign('end')}
          onCenterClick={() => handleLastName.elementAlign('center')}
          onLeftClick={() => handleLastName.elementAlign('start')}
          width={lastName.width}
          setElementWidth={handleLastName.width}
          height={lastName.height}
          setElementHeight={handleLastName.height}
          onResetPress={handleLastName.resetSize}
        />
      ),
    },
  ];

  const designChangerEmail = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={email.borderVisibility}
          setBorderVisibility={handleEmail.borderVisibility}
          borderColor={email.borderColor}
          setBorderColor={handleEmail.borderColor}
          fillVisibility={email.fillVisibility}
          setFillVisibility={handleEmail.fillVisibility}
          fillColor={email.fillColor}
          setFillColor={handleEmail.fillColor}
          shadowVisibilty={email.shadow}
          setShadowVisibility={handleEmail.shadow}
          borderRadius={email.borderRadius}
          setBorderRadius={handleEmail.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={email.alignment}
          onRightClick={() => handleEmail.elementAlign('end')}
          onCenterClick={() => handleEmail.elementAlign('center')}
          onLeftClick={() => handleEmail.elementAlign('start')}
          width={email.width}
          setElementWidth={handleEmail.width}
          height={email.height}
          setElementHeight={handleEmail.height}
          onResetPress={handleEmail.resetSize}
        />
      ),
    },
  ];

  const designChangerCountry = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={country.borderVisibility}
          setBorderVisibility={handleCountry.borderVisibility}
          borderColor={country.borderColor}
          setBorderColor={handleCountry.borderColor}
          fillVisibility={country.fillVisibility}
          setFillVisibility={handleCountry.fillVisibility}
          fillColor={country.fillColor}
          setFillColor={handleCountry.fillColor}
          shadowVisibilty={country.shadow}
          setShadowVisibility={handleCountry.shadow}
          borderRadius={country.borderRadius}
          setBorderRadius={handleCountry.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={country.alignment}
          onRightClick={() => handleCountry.elementAlign('end')}
          onCenterClick={() => handleCountry.elementAlign('center')}
          onLeftClick={() => handleCountry.elementAlign('start')}
          width={country.width}
          setElementWidth={handleCountry.width}
          height={country.height}
          setElementHeight={handleCountry.height}
          onResetPress={handleCountry.resetSize}
        />
      ),
    },
  ];

  const designChangerState = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={state.borderVisibility}
          setBorderVisibility={handleState.borderVisibility}
          borderColor={state.borderColor}
          setBorderColor={handleState.borderColor}
          fillVisibility={state.fillVisibility}
          setFillVisibility={handleState.fillVisibility}
          fillColor={state.fillColor}
          setFillColor={handleState.fillColor}
          shadowVisibilty={state.shadow}
          setShadowVisibility={handleState.shadow}
          borderRadius={state.borderRadius}
          setBorderRadius={handleState.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={state.alignment}
          onRightClick={() => handleState.elementAlign('end')}
          onCenterClick={() => handleState.elementAlign('center')}
          onLeftClick={() => handleState.elementAlign('start')}
          width={state.width}
          setElementWidth={handleState.width}
          height={state.height}
          setElementHeight={handleState.height}
          onResetPress={handleState.resetSize}
        />
      ),
    },
  ];

  const designChangerAddress = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={address.borderVisibility}
          setBorderVisibility={handleAddress.borderVisibility}
          borderColor={address.borderColor}
          setBorderColor={handleAddress.borderColor}
          fillVisibility={address.fillVisibility}
          setFillVisibility={handleAddress.fillVisibility}
          fillColor={address.fillColor}
          setFillColor={handleAddress.fillColor}
          shadowVisibilty={address.shadow}
          setShadowVisibility={handleAddress.shadow}
          borderRadius={address.borderRadius}
          setBorderRadius={handleAddress.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={address.alignment}
          onRightClick={() => handleAddress.elementAlign('end')}
          onCenterClick={() => handleAddress.elementAlign('center')}
          onLeftClick={() => handleAddress.elementAlign('start')}
          width={address.width}
          setElementWidth={handleAddress.width}
          height={address.height}
          setElementHeight={handleAddress.height}
          onResetPress={handleAddress.resetSize}
        />
      ),
    },
  ];

  const designChangerPhone = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={phone.borderVisibility}
          setBorderVisibility={handlePhone.borderVisibility}
          borderColor={phone.borderColor}
          setBorderColor={handlePhone.borderColor}
          fillVisibility={phone.fillVisibility}
          setFillVisibility={handlePhone.fillVisibility}
          fillColor={phone.fillColor}
          setFillColor={handlePhone.fillColor}
          shadowVisibilty={phone.shadow}
          setShadowVisibility={handlePhone.shadow}
          borderRadius={phone.borderRadius}
          setBorderRadius={handlePhone.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={phone.alignment}
          onRightClick={() => handlePhone.elementAlign('end')}
          onCenterClick={() => handlePhone.elementAlign('center')}
          onLeftClick={() => handlePhone.elementAlign('start')}
          width={phone.width}
          setElementWidth={handlePhone.width}
          height={phone.height}
          setElementHeight={handlePhone.height}
          onResetPress={handlePhone.resetSize}
        />
      ),
    },
  ];

  const designChangerCity = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={city.borderVisibility}
          setBorderVisibility={handleCity.borderVisibility}
          borderColor={city.borderColor}
          setBorderColor={handleCity.borderColor}
          fillVisibility={city.fillVisibility}
          setFillVisibility={handleCity.fillVisibility}
          fillColor={city.fillColor}
          setFillColor={handleCity.fillColor}
          shadowVisibilty={city.shadow}
          setShadowVisibility={handleCity.shadow}
          borderRadius={city.borderRadius}
          setBorderRadius={handleCity.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={city.alignment}
          onRightClick={() => handleCity.elementAlign('end')}
          onCenterClick={() => handleCity.elementAlign('center')}
          onLeftClick={() => handleCity.elementAlign('start')}
          width={city.width}
          setElementWidth={handleCity.width}
          height={city.height}
          setElementHeight={handleCity.height}
          onResetPress={handleCity.resetSize}
        />
      ),
    },
  ];

  const designChangerZipCode = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={zipCode.borderVisibility}
          setBorderVisibility={handleZipCode.borderVisibility}
          borderColor={zipCode.borderColor}
          setBorderColor={handleZipCode.borderColor}
          fillVisibility={zipCode.fillVisibility}
          setFillVisibility={handleZipCode.fillVisibility}
          fillColor={zipCode.fillColor}
          setFillColor={handleZipCode.fillColor}
          shadowVisibilty={zipCode.shadow}
          setShadowVisibility={handleZipCode.shadow}
          borderRadius={zipCode.borderRadius}
          setBorderRadius={handleZipCode.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={zipCode.alignment}
          onRightClick={() => handleZipCode.elementAlign('end')}
          onCenterClick={() => handleZipCode.elementAlign('center')}
          onLeftClick={() => handleZipCode.elementAlign('start')}
          width={zipCode.width}
          setElementWidth={handleZipCode.width}
          height={zipCode.height}
          setElementHeight={handleZipCode.height}
          onResetPress={handleZipCode.resetSize}
        />
      ),
    },
  ];

  const designChangerButton = [
    {
      heading: 'Entire Card',
      component: (
        <DesignEditor
          borderVisibility={button.borderVisibility}
          setBorderVisibility={handleButton.borderVisibility}
          borderColor={button.borderColor}
          setBorderColor={handleButton.borderColor}
          fillVisibility={button.fillVisibility}
          setFillVisibility={handleButton.fillVisibility}
          fillColor={button.fillColor}
          setFillColor={handleButton.fillColor}
          shadowVisibilty={button.shadow}
          setShadowVisibility={handleButton.shadow}
          borderRadius={button.borderRadius}
          setBorderRadius={handleButton.borderRadius}
        />
      ),
    },
    {
      heading: 'Size',
      component: (
        <AlignmentEditor
          alignment={button.alignment}
          onRightClick={() => handleButton.elementAlign('end')}
          onCenterClick={() => handleButton.elementAlign('center')}
          onLeftClick={() => handleButton.elementAlign('start')}
          width={button.width}
          setElementWidth={handleButton.width}
          height={button.height}
          setElementHeight={handleButton.height}
          onResetPress={handleButton.resetSize}
        />
      ),
    },
  ];

  return [
    {
      title: 'Title',
      button: true,
      visibility: title.visibility,
      setVisibility: handleTitle.visibility,
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={title.selectedFont}
              onFontChange={handleTitle.fontChange}
              fontSize={title.fontSize}
              onFontSizeChange={handleTitle.fontSize}
              bold={title.bold}
              italic={title.italic}
              underline={title.underline}
              onBoldClick={handleTitle.boldClick}
              onItalicClick={handleTitle.italicClick}
              onUnderlineClick={handleTitle.underlineClick}
              textAlign={title.textAlign}
              onLeftClick={() => handleTitle.textAlign('left')}
              onCenterClick={() => handleTitle.textAlign('center')}
              onRightClick={() => handleTitle.textAlign('right')}
              onJustifyClick={() => handleTitle.textAlign('justify')}
              lineHeight={title.lineHeight}
              onLineHeightChange={handleTitle.lineHeight}
              color={title.color}
              setColor={handleTitle.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerTitle} />,
        },
        {
          heading: 'Style',
          component: <TitleStyle />,
        },
      ],
    },
    {
      title: 'Input - First Name',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={firstName.selectedFont}
              onFontChange={handleFirstName.fontChange}
              fontSize={firstName.fontSize}
              onFontSizeChange={handleFirstName.fontSize}
              bold={firstName.bold}
              italic={firstName.italic}
              underline={firstName.underline}
              onBoldClick={handleFirstName.boldClick}
              onItalicClick={handleFirstName.italicClick}
              onUnderlineClick={handleFirstName.underlineClick}
              textAlign={firstName.textAlign}
              onLeftClick={() => handleFirstName.textAlign('left')}
              onCenterClick={() => handleFirstName.textAlign('center')}
              onRightClick={() => handleFirstName.textAlign('right')}
              onJustifyClick={() => handleFirstName.textAlign('justify')}
              lineHeight={firstName.lineHeight}
              onLineHeightChange={handleFirstName.lineHeight}
              color={firstName.color}
              setColor={handleFirstName.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerFirstName} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Last Name',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={lastName.selectedFont}
              onFontChange={handleLastName.fontChange}
              fontSize={lastName.fontSize}
              onFontSizeChange={handleLastName.fontSize}
              bold={lastName.bold}
              italic={lastName.italic}
              underline={lastName.underline}
              onBoldClick={handleLastName.boldClick}
              onItalicClick={handleLastName.italicClick}
              onUnderlineClick={handleLastName.underlineClick}
              textAlign={lastName.textAlign}
              onLeftClick={() => handleLastName.textAlign('left')}
              onCenterClick={() => handleLastName.textAlign('center')}
              onRightClick={() => handleLastName.textAlign('right')}
              onJustifyClick={() => handleLastName.textAlign('justify')}
              lineHeight={lastName.lineHeight}
              onLineHeightChange={handleLastName.lineHeight}
              color={lastName.color}
              setColor={handleLastName.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerLastName} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Email',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={email.selectedFont}
              onFontChange={handleEmail.fontChange}
              fontSize={email.fontSize}
              onFontSizeChange={handleEmail.fontSize}
              bold={email.bold}
              italic={email.italic}
              underline={email.underline}
              onBoldClick={handleEmail.boldClick}
              onItalicClick={handleEmail.italicClick}
              onUnderlineClick={handleEmail.underlineClick}
              textAlign={email.textAlign}
              onLeftClick={() => handleEmail.textAlign('left')}
              onCenterClick={() => handleEmail.textAlign('center')}
              onRightClick={() => handleEmail.textAlign('right')}
              onJustifyClick={() => handleEmail.textAlign('justify')}
              lineHeight={email.lineHeight}
              onLineHeightChange={handleEmail.lineHeight}
              color={email.color}
              setColor={handleEmail.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerEmail} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Country',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={country.selectedFont}
              onFontChange={handleCountry.fontChange}
              fontSize={country.fontSize}
              onFontSizeChange={handleCountry.fontSize}
              bold={country.bold}
              italic={country.italic}
              underline={country.underline}
              onBoldClick={handleCountry.boldClick}
              onItalicClick={handleCountry.italicClick}
              onUnderlineClick={handleCountry.underlineClick}
              textAlign={country.textAlign}
              onLeftClick={() => handleCountry.textAlign('left')}
              onCenterClick={() => handleCountry.textAlign('center')}
              onRightClick={() => handleCountry.textAlign('right')}
              onJustifyClick={() => handleCountry.textAlign('justify')}
              lineHeight={country.lineHeight}
              onLineHeightChange={handleCountry.lineHeight}
              color={country.color}
              setColor={handleCountry.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerCountry} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - State / Region',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={state.selectedFont}
              onFontChange={handleState.fontChange}
              fontSize={state.fontSize}
              onFontSizeChange={handleState.fontSize}
              bold={state.bold}
              italic={state.italic}
              underline={state.underline}
              onBoldClick={handleState.boldClick}
              onItalicClick={handleState.italicClick}
              onUnderlineClick={handleState.underlineClick}
              textAlign={state.textAlign}
              onLeftClick={() => handleState.textAlign('left')}
              onCenterClick={() => handleState.textAlign('center')}
              onRightClick={() => handleState.textAlign('right')}
              onJustifyClick={() => handleState.textAlign('justify')}
              lineHeight={state.lineHeight}
              onLineHeightChange={handleState.lineHeight}
              color={state.color}
              setColor={handleState.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerState} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Address',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={address.selectedFont}
              onFontChange={handleAddress.fontChange}
              fontSize={address.fontSize}
              onFontSizeChange={handleAddress.fontSize}
              bold={address.bold}
              italic={address.italic}
              underline={address.underline}
              onBoldClick={handleAddress.boldClick}
              onItalicClick={handleAddress.italicClick}
              onUnderlineClick={handleAddress.underlineClick}
              textAlign={address.textAlign}
              onLeftClick={() => handleAddress.textAlign('left')}
              onCenterClick={() => handleAddress.textAlign('center')}
              onRightClick={() => handleAddress.textAlign('right')}
              onJustifyClick={() => handleAddress.textAlign('justify')}
              lineHeight={address.lineHeight}
              onLineHeightChange={handleAddress.lineHeight}
              color={address.color}
              setColor={handleAddress.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerAddress} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - Phone',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={phone.selectedFont}
              onFontChange={handlePhone.fontChange}
              fontSize={phone.fontSize}
              onFontSizeChange={handlePhone.fontSize}
              bold={phone.bold}
              italic={phone.italic}
              underline={phone.underline}
              onBoldClick={handlePhone.boldClick}
              onItalicClick={handlePhone.italicClick}
              onUnderlineClick={handlePhone.underlineClick}
              textAlign={phone.textAlign}
              onLeftClick={() => handlePhone.textAlign('left')}
              onCenterClick={() => handlePhone.textAlign('center')}
              onRightClick={() => handlePhone.textAlign('right')}
              onJustifyClick={() => handlePhone.textAlign('justify')}
              lineHeight={phone.lineHeight}
              onLineHeightChange={handlePhone.lineHeight}
              color={phone.color}
              setColor={handlePhone.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerPhone} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - City',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={city.selectedFont}
              onFontChange={handleCity.fontChange}
              fontSize={city.fontSize}
              onFontSizeChange={handleCity.fontSize}
              bold={city.bold}
              italic={city.italic}
              underline={city.underline}
              onBoldClick={handleCity.boldClick}
              onItalicClick={handleCity.italicClick}
              onUnderlineClick={handleCity.underlineClick}
              textAlign={city.textAlign}
              onLeftClick={() => handleCity.textAlign('left')}
              onCenterClick={() => handleCity.textAlign('center')}
              onRightClick={() => handleCity.textAlign('right')}
              onJustifyClick={() => handleCity.textAlign('justify')}
              lineHeight={city.lineHeight}
              onLineHeightChange={handleCity.lineHeight}
              color={city.color}
              setColor={handleCity.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerCity} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Input - ZIP / Postal Code',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={zipCode.selectedFont}
              onFontChange={handleZipCode.fontChange}
              fontSize={zipCode.fontSize}
              onFontSizeChange={handleZipCode.fontSize}
              bold={zipCode.bold}
              italic={zipCode.italic}
              underline={zipCode.underline}
              onBoldClick={handleZipCode.boldClick}
              onItalicClick={handleZipCode.italicClick}
              onUnderlineClick={handleZipCode.underlineClick}
              textAlign={zipCode.textAlign}
              onLeftClick={() => handleZipCode.textAlign('left')}
              onCenterClick={() => handleZipCode.textAlign('center')}
              onRightClick={() => handleZipCode.textAlign('right')}
              onJustifyClick={() => handleZipCode.textAlign('justify')}
              lineHeight={zipCode.lineHeight}
              onLineHeightChange={handleZipCode.lineHeight}
              color={zipCode.color}
              setColor={handleZipCode.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerZipCode} />,
        },
        {
          heading: 'Style',
          component: <InputStyle />,
        },
      ],
    },
    {
      title: 'Button - Save',
      expanded: [
        {
          heading: 'Text',
          component: (
            <TextEditor
              fontFamilies={fontFamilies}
              selectedFont={button.selectedFont}
              onFontChange={handleButton.fontChange}
              fontSize={button.fontSize}
              onFontSizeChange={handleButton.fontSize}
              bold={button.bold}
              italic={button.italic}
              underline={button.underline}
              onBoldClick={handleButton.boldClick}
              onItalicClick={handleButton.italicClick}
              onUnderlineClick={handleButton.underlineClick}
              textAlign={button.textAlign}
              onLeftClick={() => handleButton.textAlign('left')}
              onCenterClick={() => handleButton.textAlign('center')}
              onRightClick={() => handleButton.textAlign('right')}
              onJustifyClick={() => handleButton.textAlign('justify')}
              lineHeight={button.lineHeight}
              onLineHeightChange={handleButton.lineHeight}
              color={button.color}
              setColor={handleButton.colorChange}
            />
          ),
        },
        {
          heading: 'Design',
          component: <DesignChanger items={designChangerButton} />,
        },
        {
          heading: 'Style',
          component: <ButtonStyle />,
        },
      ],
    },
  ];
};
