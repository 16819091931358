import React from 'react';

export default ({ status, name, reviewId }) => {
  return (
    <div className='w-4/5 h-72 px-4 py-2 bg-white filter drop-shadow-lg'>
      <p className='text-xl'>Review Status</p>
      <>
        <p className='mt-2'>status</p>
        <div className='mt-1 mb-3 ml-10 flex items-center'>
          <p
            className={`px-5 py-1.5 rounded-full ${
              status === 'submitted' ? 'bg-blue-400' : 'bg-gray-300'
            }`}
          >
            Submitted
          </p>
          <hr className='w-5 mx-2 border border-black' />
          <p className='px-5 py-1.5 bg-gray-300 rounded-full'>
            Is currently being reviewed
          </p>
          <hr className='w-5 mx-2 border border-black' />
          <p className='px-5 py-1.5 bg-gray-300 rounded-full'>Finished</p>
        </div>
      </>

      <>
        <p>App information</p>
        <p className='ml-10 mt-1'>App Name: {name}</p>
        <p className='ml-10 mt-3'>
          Review ID: {reviewId ? reviewId : '------'}
        </p>
      </>
    </div>
  );
};
