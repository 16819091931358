import axios from 'axios';
import { getHeaders, getUserId } from '../helpers/userAuth.helper';
import { BASE_URL, CUSTOMER_ADMIN_DOMAIN } from '../constants/api';

const request = axios.create();

request.interceptors.request.use(
  (config) => {
    const customerId = getUserId();
    const headers = getHeaders();

    config.headers['access-token'] = headers.token;
    config.headers['client'] = headers.client;
    config.headers['uid'] = headers.uid;
    config.headers['token-type'] = 'Bearer';
    config.headers['Content-Type'] = 'application/json';

    const subdomain = customerId ? `customer_${customerId}.` : '';

    config.baseURL = `https://${subdomain}${BASE_URL}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      alert('you are logged out from the app');
      window.open(CUSTOMER_ADMIN_DOMAIN, '_self');
    }
    return error.response;
  }
);

export default request;
