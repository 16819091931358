import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../../assets/icons';

export default () => {
  return (
    <div className='flex justify-center'>
      <div className='bottom-navigation'>
        <FontAwesomeIcon icon={icons.dashboard} />
        <FontAwesomeIcon icon={icons.home} />
        <FontAwesomeIcon icon={icons.cart} />
      </div>
    </div>
  );
};
