import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default () => {
  const location = useLocation();

  return (
    <div className='bg-gray-200 flex justify-center py-4'>
      <Link to='/android'>
        <p
          className={`mx-2 my-1 px-4 text-xl border-blue-400 cursor-pointer ${
            (location.pathname === '/android' || location.pathname === '/') &&
            'border-b-2'
          }`}
        >
          Android
        </p>
      </Link>
      <Link to='/ios' className='pointer-events-none'>
        <p
          className={`mx-2 my-1 px-4 text-xl border-blue-400 ${
            location.pathname === '/ios' && 'border-b-2'
          }`}
        >
          IOS
        </p>
      </Link>
    </div>
  );
};
