import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../assets/icons';

const Template = ({ name, selected, onTemplateChange }) => {
  return (
    <div className='mr-5 mb-5 bg-gray-200 filter drop-shadow-lg rounded-lg'>
      <label className='w-44 h-44 flex flex-col items-center cursor-pointer'>
        <p className='m-auto'>{name}</p>

        <input
          type='radio'
          checked={selected}
          onChange={onTemplateChange}
          className='absolute bottom-5'
          style={{ height: '18px', width: '18px' }}
        />
      </label>
    </div>
  );
};

const New = ({ selected, onTemplateChange }) => {
  return (
    <div className='mr-5 mb-5 bg-gray-200 filter drop-shadow-lg rounded-lg'>
      <label className='w-44 h-44 flex flex-col items-center cursor-pointer'>
        <div className='m-auto flex flex-col items-center'>
          <FontAwesomeIcon icon={icons.plus} size='lg' />
          <p className='mt-2'>New</p>
        </div>

        <input
          type='radio'
          checked={selected}
          onChange={onTemplateChange}
          className='absolute bottom-5'
          style={{ height: '18px', width: '18px' }}
        />
      </label>
    </div>
  );
};

Template.New = New;

export default Template;
